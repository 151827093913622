import axios from 'axios'
import config from '../data/config.json'
// import {Planification} from './constants'

const ApiLogisRep = {
	getPlanification: async (data) => {
		// console.log(data);
		// const response = Planification;
		const response = await axios.get('Planification', 
			{
				baseURL: config[config.env].ApiLogisRep,
				params: data,
				crossdomain: true,
				headers: {
					"Access-Control-Allow-Credentials": true,
					"Access-Control-Allow-Headers": "*",
					"Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
					"Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
					"Access-Control-Expose-Headers": "*"
				}
			}
		)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});

		return response;
	},
}
export default ApiLogisRep;