import React, { useEffect } from 'react'
import {Link, withRouter} from "react-router-dom";
import {Box, Grid, makeStyles} from '@material-ui/core'
// import HomeCarousel from '../common/CustomCarousel'
import HomeCarousel from '../common/HomeCarousel';
import Chat from '../common/Chat';
import ReactGA from 'react-ga';

// import Smooch from 'smooch'

const Smooch = require('smooch');

// const userStyles = makeStyles(theme => ({
// 	imageBannerHm: {
// 		width: '100%',
// 		height: 'auto',
// 	},
// 	btnInfoHm: {
// 		width: '100%',
// 		height: '54px',
// 		backgroundColor: '#f37021',
// 		color: '#fff',
// 		border: '1px solid #f37021',
// 		borderRadius: '6px'
// 	},
// 	btnInfoDisableHm: {
// 		width: '100%',
// 		height: '54px',
// 		backgroundColor: '#bfbfbf',
// 		color: '#fff',
// 		border: '1px solid #827a74',
// 		borderRadius: '6px'
// 	},
// }))

const Home = (props) => {
	let useStyles=makeStyles(props.styles)
	const classes = useStyles()
	const openMsgLink = () => {
		ReactGA.event({
			category: 'Home ',
			action: 'Click Get my bonus',
			label: 'Button Banner'
		});
		// console.log("Open");
	}
	// console.log(props)
	if(props.images !== null && props.bookings !== null && props.config !== null)
	{
		const {destination, images, config, bookings, trans, locale,links,styles} = props;
		const {booking} = bookings;
		document.title = destination.name + ' ' + config.delegation.zone_name;

		let iso = locale;
		if(iso.indexOf('-') > 0)
		{
			iso = iso.substring(0, iso.indexOf('-'));
		}
		// console.log(destination.name);
		return (
			<Grid container>
				<Grid item xs={12}>
					<Box mb={2}>
						<HomeCarousel images={images} config={config} bookings={bookings} openChat={openMsgLink} locale={iso}/>
					</Box>
				</Grid>
				<Grid item xs={6} sm={6}>
					<Box mb={2} p={1}>
						<Link to="/my-trip"><button className={classes.btnInfoHm}>{links.translations.home_btn_my_trip}</button></Link>
					</Box>
				</Grid>
				<Grid item xs={6} sm={6}>
					<Box mb={2} p={1}>
						<Link to="/my-nexus-wallet"><button className={classes.btnInfoHm}>{links.translations.home_btn_my_wallet}</button></Link>
					</Box>
				</Grid>
				<Grid item xs={6} sm={6}>
					<Box mb={2} p={1}>
						<Link to="/transfer-upgrade"><button className={classes.btnInfoHm}>{links.translations.home_btn_upgrade}</button></Link>
					</Box>
				</Grid>
				<Grid item xs={6} sm={6}>
					<Box mb={2} p={1}>
						<Link to="/contact-us"><button className={classes.btnInfoHm}>{links.translations.home_btn_contact_us}</button></Link>
					</Box>
				</Grid>
				<Grid item xs={6} sm={6}>
					<Box mb={2} p={1}>
						<a href={links.link+"services/results.aspx?accion=searchservices&destinationID="+config.delegation.zone_id+"&startDate="+booking.bw_ini.substring(0, booking.bw_ini.indexOf("T"))+"&endDate="+booking.bw_end.substring(0, booking.bw_end.indexOf("T"))+"&paxs=10&culture="+iso+"&idioma="+iso} 
						   target="_blank"
						   rel="noopener noreferrer">
							<button className={classes.btnInfoHm}>{links.translations.home_btn_experiences}</button>
						</a>
					</Box>
				</Grid>
				<Grid item xs={6} sm={6}>
					<Box mb={2} p={1}>
						<Link to="/share-my-experience"><button className={classes.btnInfoHm}>{links.translations.home_btn_feedback}</button></Link>
					</Box>
				</Grid>
				
			</Grid>
		)
	}
	return <div></div>
	
}

export default withRouter(Home);