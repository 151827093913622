import '../../../scss/GroupsFlow.scss';
import React, { Component } from 'react';
import SelectArrival from './SelectArrival';
import SelectDeparture from './SelectDeparture';
import Confirmation from './Confirmation';
import ApiXHub from '../../../services/ApiXHub';
import { Store } from 'react-notifications-component';
import Cookies from 'universal-cookie';
import Encrypt from '../../libraries/SessionEncrypt';
import Validation from '../../../services/Validation';
import Helpers from "../../libraries/helpers";
import trans from "../../../data/translation.json"

const cookies = new Cookies();
const helpers = new Helpers();

class GroupsFlow extends Component {
	constructor (props) {
		super(props)
		this.state= {
			locale: (helpers.existsLangCookie() ? helpers.getLangCookie() : helpers.getLangNav()),
			loader: false,
			step: 1,
			arrival: '',
			departure: '',
			locator: null,
			bookings: null,
			is_dir_in: false,
			is_dir_out: false
		}
	}

	// notification
	notifyDanger = (message) => {
		Store.addNotification({
			title: "Error!",
			message: message,
			type: "danger",
			insert: "top",
			container: "top-center",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 2000,
				onScreen: true
			}
		});
	}

	// Proceed to next step
	nextStep = ()  => {
		const {step, arrival, departure} = this.state;
		let apply = false;

		switch(step) {
			case 1: 
				if(arrival){
					apply = true;
				} else{
					this.notifyDanger("No selecciono nada");
				}
				break;
			case 2: 
				if(departure){
					apply = true;
				} else{
					this.notifyDanger("No selecciono nada");
				}
				break;
			case 3: 
				if(arrival && departure){
					apply = true;
				} else{
					this.notifyDanger("No selecciono nada");
				}
				break;
			default:
				apply = false;
				break;
		}


		if(apply) {
			this.setState({
				...this.state,
				step: step + 1
			});
		}
	}

	// Proceed to prev step
	prevStep = ()  => {
		const { step } = this.state;
		this.setState({
			...this.state,
			step: step - 1
		});
	}

	// Handle fields change
	handleChange = (input, value) => e => {
		// console.log(input, value);
		if(value === 'non')
		{
			this.setState({
				...this.state,
				[input]: value
			});
		} else {
			this.setState({
				...this.state,
				[input]: value.line_id
			});
		}
	}

	// Redirect to Home
	goToHome = async ()  => {
		// console.log(this.state);
		let XhubSession = localStorage.getItem('xhub-session');
		let request = {
			token: XhubSession,
			locator: this.state.bookings.locator,
			arrival: this.state.arrival,
			departure: this.state.departure
		};
		// console.log(request);
		request = Validation.addLines(request);
		// console.log(request);
		if(request)
		{
			// console.log(request);
			let response = await ApiXHub.addLines(request);
			// console.log(response);

			if(response.data.is_valid)
			{
				window.location = '/home';
			}
		}
	}

	setLoader = async (status) => {
		await this.setState({
			...this.state,
			loader: status
		});
	}

	setStep = async (step) => {
		await this.setState({
			...this.state,
			step: step
		});
	}

	setUserInfo = async (locator) => {
		await this.setState({
			...this.state,
			locator: locator
		})
	}
	
	setBooking = async (bookings) => {
		await this.setState({
			...this.state,
			bookings: bookings
		})
	}

	getBooking = async (request) => {
		this.setLoader(true);
		request = Validation.getBooking(request);

		if(request)
		{
			//console.log(request);
			let response = await ApiXHub.getBooking(request);
			//console.log(response);
			if(response.data.is_valid)
			{
				// "is_dir_in": true,
				// "is_dir_out": true,
				// if(response.data.lines_trf_in > 1 && response.data.lines_trf_out > 1) {
				// 	this.setStep(1);
				// } else if(response.data.lines_trf_in > 1 && !(response.data.lines_trf_out > 1)) {
				// 	this.setStep(1);
				// } else if(!(response.data.lines_trf_in > 1) && response.data.lines_trf_out > 1) {
				// 	this.setStep(2);
				// }
				this.setStep(1);
				this.setBooking(response.data);
				//console.log(response.data);
			}
		}
		this.setLoader(false);
	}

	componentDidMount() {
		const session = new Encrypt();
		let UserInfo = session.decryptUser(cookies);
		this.setUserInfo(UserInfo.locator);
		let lang = (helpers.existsLangCookie() ? helpers.getLangCookie() : helpers.getLangNav());
		lang = (Validation.isEmpty(UserInfo.language) ? lang : UserInfo.language);

		let request = {
			code: UserInfo.locator,
			lang: lang
		}
		
		this.getBooking(request);

		helpers.checkSession();
  }

	render () {
		const {step, arrival, departure, bookings, loader, locale} = this.state;
		const values = {arrival, departure};
		switch(step) {
			case 1: 
				return (
					<SelectArrival
						trans={trans[locale]}
						status={loader}
						nextStep={this.nextStep}
						handleChange={this.handleChange}
						values={values}
						booking={bookings}
					/>
				);
			case 2: 
				return (
					<SelectDeparture
						trans={trans[locale]}
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						handleChange={this.handleChange}
						values={values}
						booking={bookings}
					/>
				);
				case 3:
					return (
						<Confirmation
							trans={trans[locale]}
							nextStep={this.goToHome}
							prevStep={this.prevStep}
							handleChange={this.handleChange}
							values={values}
							booking={bookings}
						/>
					);
				default:
					return (
						<SelectArrival
							trans={trans[locale]}
							nextStep={this.nextStep}
							handleChange={this.handleChange}
							values={values}
							booking={bookings}
						/>
					);
		}
	}
}
export default GroupsFlow;