import React, {useState} from 'react'
import {Box, Card, Grid, makeStyles, Button, IconButton} from '@material-ui/core'
import Loader from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIosRounded';
import Smooch from 'smooch';
import Helpers from '../../libraries/helpers';
import ImgTransfer from '../../../images/04-trip-icon-transfer-25x25.png';
// const userStyles = makeStyles(theme => ({
// 	cardItemVi:{
// 		width: '90%',
// 		margin: '0 auto',
// 		padding: '0 10px'
// 	},
// 	marginCardVi:{
// 		marginBottom: '20px'
// 	},
// 	titleBoxVi: {
// 		display: 'flex',
// 		alignItems: 'center',
// 		justifyContent: 'center',
// 		marginTop: '8px',
// 		marginBottom: '8px',
// 	},
// 	titleImgVi:{
// 		display: 'inline-block',
// 		width: '25px',
// 	},
// 	titleItemVi:{
// 		margin: 0,
// 		color: '#ff7415',
// 		textAlign: 'center',
// 		fontSize: '20px',
// 		fontFamily: 'Roboto',
// 	},
// 	labelItemVi: {
// 		fontFamily: 'Roboto',
// 		fontSize: '16px',
// 		fontWeight: 'bold',
// 		color: '#7A7A78',
// 		padding: '5px'
// 	},
// 	inputItemVi:{
// 		backgroundColor: '#F4F4F4',
// 		margin: '5px',
// 		fontFamily: 'Roboto',
// 		fontSize: '12px',
// 		color: '#575756',
// 		padding: '7px',
// 		borderRadius: '4px',
// 		textAlign: 'center',
// 		minHeight: '14px',
// 		// overflowX: 'scroll'
// 	},
// 	btnInfoVi: {
// 		color: '#fff',
// 		border: '1px solid #ff7415',
// 		margin: 'auto',
// 		minWidth: '50%',
// 		borderRadius: '6px',
// 		backgroundColor: '#ff7415',
// 		appearance: 'auto',
// 		textRendering: 'auto',
// 		letterSpacing: 'normal',
// 		wordSpacing: 'normal',
// 		textTransform: 'none',
// 		textIndent: '0px',
// 		textShadow: 'none',
// 		display: 'inline-block',
// 		textAlign: 'center',
// 		alignItems: 'flex-start',
// 		cursor: 'default',
// 		boxSizing: 'border-box',
// 		font: '400 13.333px Roboto',
// 		padding: '4px 6px',
// 	},
// 	boxInfoNotCorrectVi:{
// 		textAlign: 'center'
// 	},
// 	btnConfirmInfoVi:{
// 		color: '#fff',
// 		border: '1px solid #ff7415',
// 		margin: '0 auto',
// 		minWidth: '50%',
// 		borderRadius: '6px',
// 		backgroundColor: '#ff7415',
// 		appearance: 'auto',
// 		textRendering: 'auto',
// 		letterSpacing: 'normal',
// 		wordSpacing: 'normal',
// 		textTransform: 'none',
// 		textIndent: '0px',
// 		textShadow: 'none',
// 		display: 'inline-block',
// 		textAlign: 'center',
// 		alignItems: 'flex-start',
// 		cursor: 'default',
// 		boxSizing: 'border-box',
// 		font: '400 13.333px Roboto',
// 		padding: '4px 6px',
// 		"&:hover, &:focus, &:active": {
// 			color: '#fff',
// 			backgroundColor: '#ff7415',
// 		}
// 	},
// 	infoNotCorrectVi:{
// 		padding: '0px 22px',
//     color: '#7A7A78',
//     fontSize: '16px',
//     fontFamily: 'Roboto',
//     fontWeight: 400
// 	},
// 	marginBottomVi: {
// 		marginBottom: '10px',
// 		marginTop: '-52px',
// 	},
// 	backLinkVi:{
// 		position: 'relative',
//     top: '74px',
//     backgroundColor: 'rgba(255,255,255, 0.5)'
// 	},
// }))

const VerifyInformation = (props) => {
	const useStyles=makeStyles(props.styles)
	const classes = useStyles(props);
	const [loader, setLoader] = useState(false);
	const helpers = new Helpers();
	const {trip, booking, trans,links,openChatLink} = props;

	const getMonthText = (num) => {
		return trans.months[num];
	}

	const handleConfirmInfo = async (booking) => {
		setLoader(true);
		await props.handleConfirmInfo(booking);
		setLoader(false);
	}

	if(trip !== null && booking !== null)
	{
		document.title = trip.fields.titleVerifyInfo;
		var meetingDateTime = helpers.correctDate(booking.meeting_date, booking.meeting_time);
		// console.log(meetingDateTime.getDate(), meetingDateTime.getUTCDate());
		// console.log(trip);
		return (
			<Grid container className={classes.marginBottomVi}>
				<IconButton 
					onClick={() => props.handleOpenCheckin()}
					className={classes.backLinkVi}
					aria-label="Back">
					<ArrowBackIosIcon fontSize="inherit" />
				</IconButton>
				<Grid item xs={12}>
					<Box mb={2}>
						<img src={trip.fields.bannerVerifyInfo.fields.file.url} alt={trip.fields.bannerVerifyInfo.fields.title} style={{width: '100%', height: 'auto'}}/>
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={12} className={classes.marginCardVi}>
					<Card className={classes.cardItemVi}>
						<Grid container>
							<Grid item xs={12}>
								<Box className={classes.titleBoxVi}>
									<img className={classes.titleImgVi} src={ImgTransfer} alt="Transfer"/>
									<h4 className={classes.titleItemVi}>{(booking.direction === 'IN' ? links.translations.trip_lb_trf_service_arr : (booking.direction === 'OUT' ? links.translations.trip_lb_trf_service_dpt : links.translations.trip_lb_trf_service_default))}</h4>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box mb={1} style={{ textAlign: 'center' }}>
									<button className={classes.btnInfoVi} onClick={openChatLink}>{links.translations.trip_btn_transfer}</button>
								</Box>
							</Grid>
							<Grid item xs={2}>
								<Box mb={1} className={classes.labelItemVi}>{links.translations.trip_lb_from}</Box>
							</Grid>
							<Grid item xs={10}>
								<Box mb={1}><div className={classes.inputItemVi}>{ booking.from }</div></Box>
							</Grid>
							<Grid item xs={2}>
								<Box mb={1} className={classes.labelItemVi}>{links.translations.trip_lb_to}</Box>
							</Grid>
							<Grid item xs={10}>
								<Box mb={1}><div className={classes.inputItemVi}>{ booking.to }</div></Box>
							</Grid>
							<Grid item xs={6}>
								<Box mb={1} className={classes.labelItemVi}>{links.translations.trip_lb_trf_type}</Box>
							</Grid>
							<Grid item xs={6}>
								<Box mb={1}><div className={classes.inputItemVi}>{booking.trf_type}</div></Box>
							</Grid>
							<Grid item xs={6}>
								<Box mb={1} className={classes.labelItemVi}>{(booking.direction === 'IN' ? links.translations.trip_lb_flight_date_arr : (booking.direction === 'OUT' ? links.translations.trip_lb_flight_date_dpt : links.translations.trip_lb_flight_date_default))}</Box>
							</Grid>
							<Grid item xs={6}>
								<Box mb={1}><div className={classes.inputItemVi}>{booking.meeting_date && (meetingDateTime.getDate() + "/" + getMonthText(meetingDateTime.getUTCMonth()) + "/" + meetingDateTime.getUTCFullYear())}</div></Box>
							</Grid>
							<Grid item xs={6}>
								<Box mb={1} className={classes.labelItemVi}>{links.translations.trip_lb_flight_no}</Box>
							</Grid>
							<Grid item xs={6}>
								<Box mb={1}><div className={classes.inputItemVi}>{booking.flight_number ? booking.flight_number : '-----'}</div></Box>
							</Grid>
							<Grid item xs={6}>
								<Box mb={1} className={classes.labelItemVi}>{(booking.direction === 'IN' ? links.translations.trip_lb_flight_time_arr : (booking.direction === 'OUT' ? links.translations.trip_lb_flight_time_dpt : links.translations.trip_lb_flight_time_default))}</Box>
							</Grid>
							<Grid item xs={6}>
								<Box mb={1}><div className={classes.inputItemVi}>{booking.flight_time ? helpers.formatAMPM(booking.flight_time) : '--:--'}</div></Box>
							</Grid>
						</Grid>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<Box mb={2} className={classes.boxInfoNotCorrectVi}>
						{loader ? <Loader 	
						style={{
							color: props.links.primary_color
						  }} 
						  /> : <Button size="small" onClick={() => handleConfirmInfo(booking)} className={classes.btnConfirmInfoVi}>{links.translations.bp_btn_confirm_info}</Button>}
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box mb={2}>
						<p className={classes.infoNotCorrectVi}>{ links.translations.trip_info_not_correct }</p>
					</Box>
				</Grid>
			</Grid>
		)
	}
	return <div></div>
	
}

export default VerifyInformation;