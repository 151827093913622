import React from 'react'
import {AppBar, Toolbar, IconButton,makeStyles} from '@material-ui/core'
import {Link} from "react-router-dom"
import MenuIcon from '@material-ui/icons/Menu'
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import trans from "../../data/translation.json";
import Config from "../../data/config.json";
// const useStyles = makeStyles(theme => ({
// 	appBar: {
// 		backgroundColor: '#fff',
// 		color: '#eb6506',
// 	},
// 	containerLogo: {
// 		flexGrow: 1,
// 		height: '72px',
// 	},
// 	containerInfo:{
// 		flexGrow: 11,
// 		height: 'auto'
// 	},
// 	logo: {
// 		height: '60px',
// 		marginLeft: '-8px',
// 		marginTop: '6px',
// 	},
// 	lang: {
// 		height: '20px',
// 	},
// 	menuButton: {
// 		marginRight: theme.spacing(1),
// 	},
// 	tags: {
// 		display: 'block',
// 		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
// 		marginLeft: '10px',
// 		color: '#747474'
// 	},
// 	username: {
// 		marginTop: '6px',
// 		fontSize: '11px',
// 		fontWeight: 'bold',
// 		color: '#eb6506'
// 	},
// 	booking: {
// 		fontSize: '11px',
// 	},
// 	zones: {
// 		fontSize: '11px',
// 	},
// 	products: {
// 		fontSize: '11px',
// 	},
// 	date: {
// 		fontSize: '11px',
// 		fontWeight: 'bold',
// 		color: '#000'
// 	}
// }))

export default function Navbar (props) {

	const useStyles=makeStyles(props.styles)
	const classes = useStyles(props);
	const getMonthText = (num, iso = 'en-CA') => {
        return trans[iso].months[num];
	}

	// const getShortProductName = (collection, iso) => {
	// 	let index = (iso === 'en-CA' ? 'ServiceName_en' : (iso === 'es' ? 'ServiceName_es' : (iso === 'fr' ? 'ServiceName_fr' : (iso === 'pt-BR' ? 'ServiceName_pt' : ''))));
	// 	// console.log(iso, index);
	// 	var shortProductName = collection.map(item => item[index]);
	// 	shortProductName = shortProductName.join(", ");
	// 	return shortProductName;
	// }

	// const changeHeight = () => {
	// 	// if(props.header === true)
	// 	// {
	// 	// 	classes.containerInfo.height = '82px'
	// 	// } else {
	// 	// 	classes.containerInfo.height = '72px'
	// 	// }
	// 	// console.log(containerHeight)
	// 	props.handleDrawerToggleHeader()
	// }
	const getImageThumb = (user, config, bw, ew, iso, large = false,value=null) => {
		let url = Config[Config.env].thumb;
		//console.log(props.links.primary_color)
        url = url + "?locator=" + encodeURI((user.booking.agency_ref ? user.booking.agency_ref : user.booking.booking_code));
		url = url + "&name=" + encodeURI(user.client.name + ' ' + user.client.lastname);
		url = url + "&zone=" + encodeURI(config.delegation.zone_name + ', ' + config.delegation.country_name);
		//url = url + "&lines=" + encodeURI(getShortProductName(user.ServiceCollection, iso));
		url = url + "&lines=" + encodeURI(value);
		url = url + "&dates=" + encodeURI(getMonthText(bw.getUTCMonth(), iso) + " " + bw.getUTCDate() + ", " + bw.getUTCFullYear() + " — " + getMonthText(ew.getUTCMonth(), iso) + " " + ew.getUTCDate() + ", " + ew.getUTCFullYear());
		url = url + "&color=" + encodeURI(props.links.primary_color.substring(1));
		url = url + (large === true ?  "&large=true" : "");
        return url;
	}
	
	// console.log(props.user);
	if(props.bookings !== null && props.config !== null && props.links!==null)
	{
		const {bookings, config, locale, header,links} = props;
		var beginTravelDate = new Date(bookings.booking.tw_ini + '.000Z');
		var endTravelDate = new Date(bookings.booking.tw_end + '.000Z');
		// console.log(getMonthText(beginTravelDate.getMonth()),beginTravelDate.getDate());
		return (
			<AppBar id="main-header" className={classes.appBar}>
				<Toolbar>
					<div className={classes.containerLogo}>
						<Link to="/">
							<img src={links.company_label_navbar.fields.file.url} className={classes.logo} />
						</Link>
					</div>
					<div className={classes.containerInfo}>
						<img 
							src={getImageThumb(bookings, config, beginTravelDate, endTravelDate, locale)} 
							alt={bookings.client.name + ' ' + bookings.client.lastname}
							style={{maxWidth: '150px', width: '100%', display: header ? 'none' : 'block' }}
							/>
						{/* <img 
							src={getImageThumb(bookings, config, beginTravelDate, endTravelDate, locale, true)} 
							alt={bookings.client.name + ' ' + bookings.client.lastname}
							style={{maxWidth: '150px', width: '100%', display: header ? 'block' : 'none' }}
							/> */}
					</div>
					{/* <IconButton color="action" aria-label="Expand" onClick={() => changeHeight()}>
						{props.header === true ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
					</IconButton> */}
					<IconButton color="inherit" aria-label="language" onClick={() => props.handleDrawerToggleLang()}>
						<span className={classes.icon1NavBar}>
							{locale === 'es' ? 'ESP' : (locale === 'fr' ? 'FRA' : (locale === 'pt-BR' ? 'POR' : 'ENG'))}
							<span className={
								classes.icon2NavBar
							}></span>
						</span>
					</IconButton>
					<IconButton edge="end" color="inherit" aria-label="menu" onClick={() => props.handleDrawerToggle()}>
						<MenuIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
		)
	}
	return <div></div>
}