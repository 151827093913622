import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../../css/rules-carousel.css";
import {Carousel} from 'react-responsive-carousel';

class DestinationCarousel extends Component {
	render () {
		const {images} = this.props;
		return (
				<Carousel autoPlay>
					{images.map((image, i) => <div key={i} id={i} ><img src={image.fields.file.url} alt={image.fields.title} /></div>)}
				</Carousel>
			);
	}
}

export default DestinationCarousel;