import React from 'react'
import {Link} from "react-router-dom";
import Language from '../../libraries/Language';
import trans from "../../../data/translation.json";
// import axios from 'axios';
import {Box, Grid, makeStyles, Card} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Helpers from '../../libraries/helpers';
// const baseURL = 'https://nexusgov3.nexustours.net/JuniperInformation/api/PickupTime';

// const userStyles = makeStyles(theme => ({
// 	cardItemTr:{
// 		width: '90%',
// 		margin: '0 auto',
// 		padding: '0 10px'
// 	},
// 	marginCardTr:{
// 		marginBottom: '20px'
// 	},
// 	titleBoxTr: {
// 		display: 'flex',
// 		alignItems: 'center',
// 		justifyContent: 'center',
// 		marginTop: '8px',
// 		marginBottom: '8px',
// 	},
// 	titleImgTr:{
// 		display: 'inline-block',
// 		width: '25px',
// 	},
// 	titleItemTr:{
// 		margin: 0,
// 		color: '#004A63',
// 		textAlign: 'center',
// 		fontSize: '20px',
// 		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
// 	},
// 	labelItemTr: {
// 		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
// 		fontSize: '16px',
// 		fontWeight: 'bold',
// 		color: '#7A7A78',
// 		padding: '5px'
// 	},
// 	inputItemTr:{
// 		backgroundColor: '#F4F4F4',
// 		margin: '5px',
// 		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
// 		fontSize: '12px',
// 		color: '#575756',
// 		padding: '7px',
// 		borderRadius: '4px',
// 		textAlign: 'center',
// 		minHeight: '14px',
// 		// overflowX: 'scroll'
// 	},
// 	btnInfoTr: {
// 		color: '#fff',
//         border: '1px solid #ff7415',
//         margin: 'auto',
//         minWidth: '90%',
//         borderRadius: '6px',
//         backgroundColor: '#ff7415',
//         appearance: 'auto',
//         textRendering: 'auto',
//         letterSpacing: 'normal',
//         wordSpacing: 'normal',
//         textTransform: 'none',
//         textIndent: '0px',
//         textShadow: 'none',
//         display: 'inline-block',
//         textAlign: 'center',
//         alignItems: 'flex-start',
//         cursor: 'default',
//         boxSizing: 'border-box',
//         font: '400 13.333px Roboto',
//         fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//         padding: '4px 6px',
//         textDecoration: 'none',
// 	},
// }))

const MyTransfer = (props) => {
    // console.log(props)
    const useStyles=makeStyles(props.styles)
	const classes = useStyles(props);
	const lang = new Language();
	const helpers = new Helpers();

	const { transfer,trip,links,openChatLink } = props;
	let locale = lang.getLang();

	const getMonthText = (num, iso = 'en-CA') => {
		return trans[iso].months[num];
	}

	const getMessage = (iso = 'en-CA') => {
		if(transfer.flight_number && transfer.flight_time)
		{
			if(transfer.meeting_time === null || transfer.meeting_time === '')
			{
				if (transfer.is_schedule_grouped)
				{
					return (
						<Tooltip disableFocusListener placement="right-start" title={links.translations.trip_tp_48hrs}>
							<InfoIcon style={{ fontSize: 15 }} />
						</Tooltip>
					);
				} else {
					return (
						<Tooltip disableFocusListener placement="right-start" title={links.translations.trip_tp_not_sch}>
							<InfoIcon style={{ fontSize: 15 }} />
						</Tooltip>
					);
				}
			}
		} else {
			return (
				<Tooltip disableFocusListener placement="right-start" title={links.translations.trip_tp_not_sch}>
					<InfoIcon style={{ fontSize: 15 }} />
				</Tooltip>
			);
		}
		return null;
	}

    if(transfer !== null)
	{
         //console.log(trip);
        // var flightDateTime = new Date(transfer.flightDateTime+ '.000Z'); // + '.000Z'
        var meetingDateTime = helpers.correctDate(transfer.meeting_date, transfer.meeting_time);
		// console.log(meetingDateTime.getDate(), meetingDateTime.getUTCDate());
        // var flightDateTime = new Date(transfer.flightDateTime).toISOString();
        // var now_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        // console.log(flightDateTime);
        // console.log(meetingDateTime);
        return (
            <Grid item xs={12} sm={6} md={6} className={classes.marginCardTr}>
                <Card className={classes.cardItemTr}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box className={classes.titleBoxTr}>
                                <img className={classes.titleImgTr} src={trip.fields.iconTrf.fields.file.url} alt="Transfer"/>
                                <h4 className={classes.titleItemTr}>{(transfer.direction === 'IN' ? links.translations.trip_lb_trf_service_arr : (transfer.direction === 'OUT' ? links.translations.trip_lb_trf_service_dpt : links.translations.trip_lb_trf_service_default))}</h4>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box  mb={1} style={{ textAlign: 'center' }}>
                                <button onClick={openChatLink} className={classes.btnInfoTr} >{links.translations.trip_btn_transfer}</button>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} style={{ textAlign: 'center' }}>
                                <Link className={classes.btnInfoTr} to="/my-nexus-wallet">{links.translations.nw_listitem_btn}</Link>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemTr}>{links.translations.trip_lb_code}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemTr}>{(transfer.agency_ref ? transfer.agency_ref : transfer.locator)}</div></Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box mb={1} className={classes.labelItemTr}>{links.translations.trip_lb_from}</Box>
                        </Grid>
                        <Grid item xs={10}>
                            <Box mb={1}><div className={classes.inputItemTr}>{transfer.from}</div></Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box mb={1} className={classes.labelItemTr}>{links.translations.trip_lb_to}</Box>
                        </Grid>
                        <Grid item xs={10}>
                            <Box mb={1}><div className={classes.inputItemTr}>{transfer.to}</div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemTr}>{links.translations.trip_lb_trf_type}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemTr}>{transfer.trf_type}</div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemTr}>{links.translations.trip_trf_passengers}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            {/* <Box mb={1}><div className={classes.inputItem}>{transfer.adults + ' ' + trans[locale].trip_trf_adults + ', ' + transfer.children + ' ' + trans[locale].trip_trf_children}</div></Box> */}
                            <Box mb={1}><div className={classes.inputItemTr}>{(transfer.adults + transfer.children + transfer.babies)}</div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemTr}>{(transfer.direction === 'IN' ? links.translations.trip_lb_flight_date_arr : (transfer.direction === 'OUT' ? links.translations.trip_lb_flight_date_dpt : links.translations.trip_lb_flight_date_default))}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemTr}>{transfer.meeting_date && (meetingDateTime.getDate() + "/" + getMonthText(meetingDateTime.getUTCMonth(), locale) + "/" + meetingDateTime.getUTCFullYear())}</div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemTr}>{links.translations.trip_lb_flight_no}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemTr}>{transfer.flight_number ? transfer.flight_number : '-----'}</div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemTr}>{(transfer.direction === 'IN' ? links.translations.trip_lb_flight_time_arr : (transfer.direction === 'OUT' ? links.translations.trip_lb_flight_time_dpt : links.translations.trip_lb_flight_time_default))}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemTr}>{transfer.flight_time ? helpers.formatAMPM(transfer.flight_time) : '--:--'}</div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemTr}>
                                {links.translations.trip_lb_flight_pickup_time}
                                {getMessage(locale)}
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemTr}>{((transfer.flight_number && transfer.flight_time) ? (transfer.direction === 'IN' ? helpers.formatAMPM(transfer.flight_time) : (transfer.meeting_time !== '' ? helpers.formatAMPM(transfer.meeting_time) : '--:--')) : '--:--')}</div></Box>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }
    return <div></div>
}

export default MyTransfer;