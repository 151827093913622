import React from 'react'
import Language from '../../libraries/Language';
import {Box, Grid, makeStyles, Card} from '@material-ui/core'
import ImgTransfer from '../../../images/04-trip-icon-transfer-25x25.png';
// const userStyles = makeStyles(theme => ({
// 	cardItemNf:{
// 		width: '90%',
// 		margin: '0 auto',
// 		padding: '12px 10px'
// 	},
// 	marginCardNf:{
//         marginTop: '-20px',
// 		marginBottom: '20px',
// 	},
// 	titleBoxNf: {
// 		alignItems: 'center',
// 		justifyContent: 'center',
// 		marginTop: '8px',
// 		marginBottom: '8px',
//         textAlign: 'center',
// 	},
// 	titleImgNf:{
// 		display: 'block',
// 		width: '35px',
//         margin: 'auto',
// 	},
// 	titleItemNf:{
// 		margin: '0 auto',
// 		color: '#ff7415',
// 		textAlign: 'center',
// 		fontSize: '20px',
// 		fontFamily: 'Roboto',
//         width: '200px',
//         fontWeight: '500',
// 	},
//     textItemNf:{
// 		margin: '0 auto',
// 		color: '#505050',
// 		textAlign: 'center',
// 		fontSize: '16px',
// 		fontFamily: 'Roboto',
//         width: 'auto',
//         fontWeight: '500',
// 	},
// 	btnInfoNf: {
// 		color: '#fff',
//         border: '1px solid #ff7415',
//         height: '30px',
//         margin: 'auto',
//         minWidth: '50%',
//         borderRadius: '6px',
//         backgroundColor: '#ff7415',
//         appearance: 'auto',
//         textRendering: 'auto',
//         letterSpacing: 'normal',
//         wordSpacing: 'normal',
//         textTransform: 'none',
//         textIndent: '0px',
//         textShadow: 'none',
//         display: 'inline-block',
//         textAlign: 'center',
//         alignItems: 'flex-start',
//         cursor: 'default',
//         boxSizing: 'border-box',
//         font: '400 13.333px Roboto',
//         padding: '1px 6px',
// 	},
// }))

const TransferNotFound = (props) => {
    const lang = new Language();
	const useStyles=makeStyles(props.styles)
	const classes = useStyles(props);
    const { transfer,trip,links,openChatLink } = props;
    let locale = lang.getLang();

    return (
        <Grid item xs={12} sm={12} className={classes.marginCardNf}>
            <Card className={classes.cardItemNf} elevation={0}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box className={classes.titleBoxNf}>
                            <img className={classes.titleImgNf} src={trip.fields.iconTrf.fields.file.url} alt="Transfer"/>
                            <h4 className={classes.titleItemNf}>{links.translations.trip_title_notfound}</h4>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
            <Card className={classes.cardItemNf} elevation={0}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box>
                            <p className={classes.textItemNf}>{links.translations.trip_text_notfound}</p>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
            <Card className={classes.cardItemNf} elevation={0}>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Box style={{ textAlign: 'center' }}>
                            <button className={classes.btnInfoNf} onClick={openChatLink}>{links.translations.trip_btn_book_transfer}</button>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

export default TransferNotFound;