import React from 'react'
import {Divider, Drawer, IconButton, List, ListItem, ListItemText, makeStyles, ListItemSecondaryAction, Typography} from '@material-ui/core'
import {Link} from "react-router-dom";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const drawerWidth = 240;


const DrawerMenu = (props) => {


    const logout = () => {
        cookies.remove('xhub-info');
        localStorage.removeItem('xhub-entry-id');
        localStorage.removeItem('xhub-session');
        window.location = '/login';
	}
    if (props.bookings !== null && props.config !== null&&props.links!==null)
    {
        const {bookings, config,  links,locale} = props;
        const {booking} = bookings;
        // console.log(links.company_color)
        const userStyles =makeStyles(theme => ({
            drawerDm: {
              width: drawerWidth,
              flexShrink: 0,
            },
            drawerPaperDm: {
                width: drawerWidth,
            },
            toolbar: theme.mixins.toolbar,
            drawerHeader: {
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(0, 1),
                // necessary for content to be below app bar
                ...theme.mixins.toolbar,
                justifyContent: 'flex-start',
                width: '79%',  
                color:links.company_color,
            },
            link: {
                textDecoration: 'none',
                color:theme.palette.text.primary
            },
            logout: {
                textDecoration: 'none',
                color: links.company_color,
                width: '100%',
                backgroundColor: links.secondary_color,
                appearance: 'none',
                border: 'none',
                padding: 0,
            }
        }))
        const classes = userStyles();
        let iso = locale;
		if(iso.indexOf('-') > 0)
		{
			iso = iso.substring(0, iso.indexOf('-'));
		}
        return (
            <Drawer 
                className={classes.drawer}  
                anchor="right"
                variant="temporary"
                open={props.open}
                onClose={props.onClose ? props.onClose : null}
            >
                <div className={classes.toolbar} />
                <div className={classes.drawerHeader}>
                    <Typography variant="h6" gutterBottom >{links.translations.menu_title}</Typography>
                    <IconButton edge="end" onClick={() => props.handleDrawerToggle()}>
                        <ChevronRightIcon />
                    </IconButton>
                </div>
                <Divider />
                <List component="nav">
                    <Link to="/my-trip" className={classes.link}>
                        <ListItem button>
                            <ListItemText primary={links.translations.menu_my_trip} />
                            <ListItemSecondaryAction>
                                <ChevronRightIcon />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Link>
                    <Link to="/my-nexus-wallet" className={classes.link}>
                        <ListItem button>
                            <ListItemText primary={links.translations.menu_wallet} />
                            <ListItemSecondaryAction>
                                <ChevronRightIcon />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Link>
                    <Link to="/transfer-upgrade" className={classes.link}>
                        <ListItem button>
                            <ListItemText primary={links.translations.menu_transfer} />
                            <ListItemSecondaryAction>
                                <ChevronRightIcon />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Link>
                    <a href={links.link+"services/results.aspx?accion=searchservices&destinationID="+config.delegation.zone_id+"&startDate="+booking.bw_ini.substring(0, booking.bw_ini.indexOf("T"))+"&endDate="+booking.bw_end.substring(0, booking.bw_end.indexOf("T"))+"&paxs=10&culture="+iso+"&idioma="+iso} 
                    className={classes.link} 
                    target="_blank"
                    rel="noopener noreferrer">
                        <ListItem button>
                            <ListItemText primary={links.translations.menu_experiences} />
                            <ListItemSecondaryAction>
                                <ChevronRightIcon />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </a>
                    <Link to="/contact-us" className={classes.link}>
                        <ListItem button>
                            <ListItemText primary={links.translations.menu_contact} />
                            <ListItemSecondaryAction>
                                <ChevronRightIcon />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Link>
                    <a href={"https://www.nexustours.com/besafe?culture="+iso+"&idioma="+iso} className={classes.link} target="_blank" rel="noopener noreferrer">
                        <ListItem button>
                            <ListItemText primary={links.translations.menu_be_safe} />
                            <ListItemSecondaryAction>
                                <ChevronRightIcon />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </a>
                    <Link to="/share-my-experience" className={classes.link}>
                        <ListItem button>
                            <ListItemText primary={links.translations.menu_share}/>
                            <ListItemSecondaryAction>
                                <ChevronRightIcon />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Link>
                </List>
                <Divider />
                <List component="nav">
                    <button onClick={() => logout()} className={classes.logout}>
                        <ListItem button>
                            <ListItemText primary="Logout"/>
                            <ListItemSecondaryAction>
                                <ExitToAppIcon />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </button>
                </List>
            </Drawer>
        )
    }
    return (<div></div>);
}

export default DrawerMenu