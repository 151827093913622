import { Store } from "react-notifications-component";
import trans from "../data/translation.json";
import config from "../data/config.json";
import { PhoneNumberUtil } from "google-libphonenumber";
import Requests from "./Requests";

const Validation = {
  isEmpty: (input) => {
    if (input === null) return true;

    return input.length === 0 || !input.trim();
  },
  isInteger: (input) => {
    return Number.isInteger(parseInt(input));
  },
  validateEmail: (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  validatePhone: (phone) => {
    phone = Validation.formatPhone(phone);
    // console.log(phone, phone.length);
    // Phone number validation using google-libphonenumber
    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isPossibleNumber(phoneUtil.parse(phone));
    } catch (e) {
      valid = false;
    }
    if (valid) {
      // console.log("Dato Correcto")
      return true;
    } else {
      // console.log("Dato Incorrecto")
      return false;
    }
  },
  formatPhone: (phone) => {
    var filter = "+0123456789";
    var outPhone = "";
    for (var i = 0; i < phone.length; i++)
      if (filter.indexOf(phone.charAt(i)) !== -1) outPhone += phone.charAt(i);

    return outPhone;
  },
  validateNumber: (number) => {
    if (number.length >= 10 && number.length <= 15) {
      number = parseInt(number);
      return Number.isInteger(number);
    }
    return false;
  },
  validateBooking: (booking) => {
    if (booking.length >= 6) {
      return true;
    }
    return false;
  },
  validateLanguage: (lang) => {
    var iso;
    if (lang.length >= 2) {
      var exists = config.validLanguages.filter((item) => {
        return item.toLowerCase().includes(lang.toLowerCase());
      });
      if (exists !== undefined && exists !== null) {
        iso = "en"; //user.language;
      }
    }
    return false;
  },
  showNotification: (
    message,
    title = "Error!",
    type = "danger",
    container = "top-right"
  ) => {
    Store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: container,
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  },
  login: (request) => {
    let notifications = [];
    if (Validation.isEmpty(request.code)) {
      notifications.push({
        title: "Empty field!",
        message: "The booking number is empty.",
      });
    } else {
      if (!Validation.validateBooking(request.code)) {
        notifications.push({
          title: "Format error!",
          message: "The booking is invalid",
        }); // Error de formato! : El booking es inválido
      }
    }
    if (Validation.isEmpty(request.contact)) {
      notifications.push({
        title: "Empty field!",
        message: "The email o phone is empty.",
      });
    } else {
      if (Number.isInteger(parseInt(request.contact))) {
        if (!Validation.validateNumber(request.contact)) {
          notifications.push({
            title: "Format error!",
            message: "The telephone number is invalid",
          }); // Error de formato! : El número de teléfono es inválido
        }
      } else {
        if (!Validation.validateEmail(request.contact)) {
          notifications.push({
            title: "Format error!",
            message: "Email is invalid",
          }); // Error de formato! : El email es inválido
        }
      }
    }

    if (notifications.length <= 0) {
      request = Requests.login(request);
      return request;
    } else {
      notifications.forEach((notification) => {
        Validation.showNotification(notification.message, notification.title);
      });
      return false;
    }
  },
  loginRegister: (request) => {
    let notifications = [];
    if (Validation.isEmpty(request.code)) {
      notifications.push({
        title: "Empty field!",
        message: "The booking number is empty.",
      });
    } else {
      if (!Validation.validateBooking(request.code)) {
        notifications.push({
          title: "Format error!",
          message: "The booking is invalid",
        }); // Error de formato! : El booking es inválido
      }
    }
    if (notifications.length <= 0) {
      request = Requests.login(request);
      return request;
    } else {
      notifications.forEach((notification) => {
        Validation.showNotification(notification.message, notification.title);
      });
      return false;
    }
  },
  contactForm: (request, lines) => {
    let notifications = [];
    let counter_checks = 0;
    // console.log("Validando...");
    if (request.form.check_email) {
      counter_checks += 1;
    }
    if (request.form.check_sms) {
      counter_checks += 1;
    }
    if (request.form.check_whatsapp) {
      counter_checks += 1;
    }
    if (counter_checks <= 0) {
      notifications.push({
        title: "Error!",
        message: trans[request.locale].validations.RULE1000,
      });
    }
    if (Validation.isEmpty(request.form.firstname)) {
      notifications.push({
        title: "Empty field!",
        message: trans[request.locale].validations.RULE1007,
      });
    }
    if (Validation.isEmpty(request.form.lastname)) {
      notifications.push({
        title: "Empty field!",
        message: trans[request.locale].validations.RULE1008,
      });
    }
    if (
      (!Validation.validatePhone(request.form.whatsapp) &&
        request.form.check_whatsapp) ||
      (!Validation.validatePhone(request.form.sms) && request.form.check_sms)
    ) {
      notifications.push({
        title: "Error!",
        message: trans[request.locale].validations.RULE1001,
      });
    }
    if (
      !Validation.validateEmail(request.form.email) &&
      request.form.check_email
    ) {
      notifications.push({
        title: "Empty field!",
        message: trans[request.locale].validations.RULE1002,
      });
    }
    if (!request.form.terms) {
      notifications.push({
        title: "Empty field!",
        message: trans[request.locale].validations.RULE1003,
      });
    }

    if (notifications.length <= 0) {
      request = Requests.register(request, lines);
      return request;
    } else {
      notifications.forEach((notification) => {
        Validation.showNotification(notification.message, notification.title);
      });
      return false;
    }
  },
  changeLang: (request) => {
    let notifications = [];
    if (Validation.isEmpty(request.token)) {
      notifications.push({
        title: "Empty field!",
        message: "The token is empty.",
      });
    }
    if (Validation.isEmpty(request.language)) {
      notifications.push({
        title: "Empty field!",
        message: "The language is empty.",
      });
    }

    if (notifications.length <= 0) {
      request = Requests.changeLang(request);
      return request;
    } else {
      notifications.forEach((notification) => {
        Validation.showNotification(notification.message, notification.title);
      });
      return false;
    }
  },
  getBooking: (request) => {
    let notifications = [];
    // console.log(request);
    if (Validation.isEmpty(request.code)) {
      notifications.push({
        title: "Empty field!",
        message: "The booking number is empty.",
      });
    } else {
      if (!Validation.validateBooking(request.code)) {
        notifications.push({
          title: "Format error!",
          message: "The booking is invalid",
        }); // Error de formato! : El booking es inválido
      }
    }
    // console.log(request);
    if (notifications.length <= 0) {
      request = Requests.getBooking(request);
      return request;
    } else {
      notifications.forEach((notification) => {
        Validation.showNotification(notification.message, notification.title);
      });
      return false;
    }
  },
  getBookings: (request) => {
    let notifications = [];
    if (Validation.isEmpty(request.token)) {
      notifications.push({
        title: "Empty field!",
        message: "The token is empty.",
      });
    }
    if (Validation.isEmpty(request.code)) {
      notifications.push({
        title: "Empty field!",
        message: "The booking number is empty.",
      });
    } else {
      if (!Validation.validateBooking(request.code)) {
        notifications.push({
          title: "Format error!",
          message: "The booking is invalid",
        }); // Error de formato! : El booking es inválido
      }
    }
    if (Validation.isEmpty(request.lang)) {
      notifications.push({
        title: "Empty field!",
        message: "The language is empty.",
      });
    }
    if (notifications.length <= 0) {
      request = Requests.getBookings(request);
      return request;
    } else {
      notifications.forEach((notification) => {
        Validation.showNotification(notification.message, notification.title);
      });
      return false;
    }
  },
  getConfig: (request) => {
    let notifications = [];
    if (Validation.isEmpty(request.code)) {
      notifications.push({
        title: "Empty field!",
        message: "The booking number is empty.",
      });
    } else {
      if (!Validation.validateBooking(request.code)) {
        notifications.push({
          title: "Format error!",
          message: "The booking is invalid",
        }); // Error de formato! : El booking es inválido
      }
    }
    if (Validation.isEmpty(request.lang)) {
      notifications.push({
        title: "Empty field!",
        message: "The language is empty.",
      });
    }
    if (notifications.length <= 0) {
      request = Requests.getConfig(request);
      return request;
    } else {
      notifications.forEach((notification) => {
        Validation.showNotification(notification.message, notification.title);
      });
      return false;
    }
  },
  getAirportTransferPass: (request) => {
    let notifications = [];
    if (Validation.isEmpty(request.code)) {
      notifications.push({
        title: "Empty field!",
        message: "The booking number is empty.",
      });
    } else {
      if (!Validation.validateBooking(request.code)) {
        notifications.push({
          title: "Format error!",
          message: "The booking is invalid",
        }); // Error de formato! : El booking es inválido
      }
    }
    if (Validation.isEmpty(request.name)) {
      notifications.push({
        title: "Empty field!",
        message: "The name is empty.",
      });
    }
    if (request.line.length <= 0) {
      notifications.push({
        title: "Empty field!",
        message: "The line is empty.",
      });
    }
    if (Validation.isEmpty(request.lang)) {
      notifications.push({
        title: "Empty field!",
        message: "The language is empty.",
      });
    }
    if (notifications.length <= 0) {
      request = Requests.getAirportTransferPass(request);
      return request;
    } else {
      notifications.forEach((notification) => {
        Validation.showNotification(notification.message, notification.title);
      });
      return false;
    }
  },
  addAirportTransferPass: (request) => {
    let notifications = [];
    if (Validation.isEmpty(request.locator)) {
      notifications.push({
        title: "Empty field!",
        message: "The booking number is empty.",
      });
    } else {
      if (!Validation.validateBooking(request.locator)) {
        notifications.push({
          title: "Format error!",
          message: "The booking is invalid",
        }); // Error de formato! : El booking es inválido
      }
    }
    if (Validation.isEmpty(request.name)) {
      notifications.push({
        title: "Empty field!",
        message: "The name is empty.",
      });
    }
    if (request.line.length <= 0) {
      notifications.push({
        title: "Empty field!",
        message: "The line is empty.",
      });
    }
    if (Validation.isEmpty(request.language)) {
      notifications.push({
        title: "Empty field!",
        message: "The language is empty.",
      });
    }
    if (notifications.length <= 0) {
      request = Requests.addAirportTransferPass(request);
      return request;
    } else {
      notifications.forEach((notification) => {
        Validation.showNotification(notification.message, notification.title);
      });
      return false;
    }
  },
  addLinesLogin: (request) => {
    let notifications = [];
    if (request.lines.length <= 0) {
      notifications.push({
        title: "Empty field!",
        message: "The lines are empty.",
      });
    }
    if (Validation.isEmpty(request.locator)) {
      notifications.push({
        title: "Empty field!",
        message: "The booking number is empty.",
      });
    } else {
      if (!Validation.validateBooking(request.locator)) {
        notifications.push({
          title: "Format error!",
          message: "The booking is invalid",
        }); // Error de formato! : El booking es inválido
      }
    }

    if (Validation.isEmpty(request.token)) {
      notifications.push({
        title: "Empty field!",
        message: "The session is empty.",
      });
    }
    //console.log(request);
    if (notifications.length <= 0) {
      request = Requests.addLinesLogin(request);
      return request;
    } else {
      notifications.forEach((notification) => {
        Validation.showNotification(notification.message, notification.title);
      });
      return false;
    }
  },
  addLines: (request) => {
    let notifications = [];
    if (request.departure === "non" && request.arrival === "non") {
      notifications.push({
        title: "Important!",
        message: "At least one service line must be selected.",
      }); // Se debe seleccionar al menos una linea de servicio.
    } else {
      // if(!Validation.isInteger(request.arrival))
      // {
      // 	notifications.push({title: "Empty field!", message: "The arrival line is empty."});
      // }
      // if(!Validation.isInteger(request.departure))
      // {
      // 	notifications.push({title: "Empty field!", message: "The departure line is empty."});
      // }
      if (Validation.isEmpty(request.locator)) {
        notifications.push({
          title: "Empty field!",
          message: "The booking number is empty.",
        });
      } else {
        if (!Validation.validateBooking(request.locator)) {
          notifications.push({
            title: "Format error!",
            message: "The booking is invalid",
          }); // Error de formato! : El booking es inválido
        }
      }

      if (Validation.isEmpty(request.token)) {
        notifications.push({
          title: "Empty field!",
          message: "The session is empty.",
        });
      }
    }
    //console.log(request);
    if (notifications.length <= 0) {
      request = Requests.addLines(request);
      return request;
    } else {
      notifications.forEach((notification) => {
        Validation.showNotification(notification.message, notification.title);
      });
      return false;
    }
  },
  validateReservation: (request) => {
    let notifications = [];
    if (Validation.isEmpty(request.code)) {
      notifications.push({
        title: "Empty field!",
        message: "The booking number is empty.",
      });
    } else {
      if (!Validation.validateBooking(request.code)) {
        notifications.push({
          title: "Format error!",
          message: "The booking is invalid",
        });
      }
    }
    if (
      request.hasOwnProperty("lastname") &&
      Validation.isEmpty(request.lastname)
    ) {
      notifications.push({
        title: "Empty field!",
        message: "The last name is empty.",
      });
    }

    if (notifications.length <= 0) {
      request = Requests.validateReservation(request);
      return request;
    } else {
      notifications.forEach((notification) => {
        Validation.showNotification(notification.message, notification.title);
      });
      return false;
    }
  },
};
export default Validation;
