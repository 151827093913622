import React, { Component, useState, useEffect } from 'react';
import Helpers from '../../libraries/helpers';
import { makeStyles } from '@material-ui/core'
import "../../../css/chat.css";
import Smooch from 'smooch'
import ReactGA from 'react-ga';
const ChatCont = (props) => {
  const { agency, helpers, locale, openMsgLink, links } = props
  //console.log(links.valued)
  const useStyles = makeStyles(agency)
  const classes = useStyles(props)
  //console.log(openMsgLink);
  return (<div className={links.valued}>
    <div className="container-btn-call-chat">

      <ReactGA.OutboundLink
        className="call-rep"
        eventLabel="Call Rep"
        to="tel:+529982516559"
        target="_blank"
      >
        {/* <img src={loginSettings.image_chat.fields.file.url} alt={trans[locale].chat_call_rep}/> */}
        <div className={classes.containercall}></div>
        <span>{links.translations.chat_call_rep}</span>
      </ReactGA.OutboundLink>
    </div>
    <div className="container-btn-call-chat">
      <a id="open-chat" className="chat-rep" onClick={openMsgLink}>
        <div className={classes.containerchat}></div>
        <span>{links.translations.chat_chat_rep}</span>
      </a>
    </div>
  </div>
  )


}

class Chat extends Component {


  render() {
    // console.log(this.props)
    const agency = this.props.agency

    if (agency === null) {
      return (<div></div>)
    } else {
      const helpers = new Helpers();
      const locale = (helpers.existsLangCookie() ? helpers.getLangCookie() : helpers.getLangNav());
      return (
        <ChatCont
          helpers={helpers}
          locale={locale}
          agency={this.props.agency}
          openMsgLink={this.openMsgLink}
          activateMsgLink={this.activateMsgLink}
          links={this.props.links}
        />
      );

    }
  }
}

export default Chat;