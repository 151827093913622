import React, {useState} from 'react';
//import ReactHtmlParser from 'react-html-parser'; 
import {Card, CardActionArea, CardContent} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import DateIcon from '@material-ui/icons/CalendarToday';
import FlightIcon from '@material-ui/icons/Flight';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ShuttleIcon from '@material-ui/icons/AirportShuttle';
import AdultIcon from '@material-ui/icons/Face';
import ChildIcon from '@material-ui/icons/ChildCare';
import UncheckIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {  makeStyles } from '@material-ui/core';
export default function RadioButtonTransfer(props) {
	const [show, setShow] = useState(false);
	const {item, search, date,links,agency} = props;
	const userStyles=makeStyles(agency)
	const classes=userStyles(props)
	//console.log(links)
	const getIconPerson = (key, name, search) => {
		// if(search == "" && key == null){
		// 	return(
		// 		<div className='pax' key={key}>
		// 			<PersonIcon />
		// 			<div>{name}</div>
		// 		</div>
		// 	)
		// } else if(name.toLowerCase().includes(search.toLowerCase())){
			let textToSearch = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
			let pattern = new RegExp(`${textToSearch}`, "gi")
			let newName = name.replace(pattern, match => `<mark>${match}</mark>`)
			 //console.log(newName);
			return(
				<div className='pax' key={key}>
					<PersonIcon />
					<div dangerouslySetInnerHTML={{ __html: newName }} />
				</div>
			)
		// }
	}
	const getFirstIconPerson = (paxes, search) => {
		let paxx = paxes.find((pax) => {
			let names = pax.name + " " + pax.lastname;
			if (search === "") {
				return pax
			} else if (names.toLowerCase().includes(search.toLowerCase())) {
				return pax
			}
		});
		return getIconPerson(null, paxx.name + " " + paxx.lastname, search)
	}

	return (
		<Card  className={'card-main card-main-trf ' + (props.value === item.line_id ? 'selected' : '')} variant="outlined">
			<CardActionArea onClick={props.handleChange} 		style={{
														color: links.primary_color,
													  }}>
				<CardContent style={{
														color: links.primary_color
													  }}>
					<div className="card-header card-trf-header">
						{ !props.withoutName && item.paxes[0].name !== "" && item.paxes[0].lastname !== "" ?
							<div className="pax-name">
								{ show ?
									item.paxes.map((pax, key) => (getIconPerson(key, pax.name + " " + pax.lastname, search)))
								:
									getFirstIconPerson(item.paxes, search)
								}
								<IconButton aria-label="Show Names" size="small" className='btn-show-name' onClick={() => setShow(!show)}>
									{ show ?
										<ArrowUpwardIcon fontSize="inherit" />
										:
										<ArrowDownwardIcon fontSize="inherit" />
									}
								</IconButton>
							</div>
							:
							<div className="pax-name"></div>
						}
						<div className="date">
							<DateIcon /> {date}
						</div>
					</div>
					<div className="card-content card-trf-content">
						<div className="icon-radio">
							{(props.value === item.line_id ? <CheckIcon
													style={{
														color: links.company_color
													  }}
							/> : <UncheckIcon/>)}
						</div>
						<div className="content-info">
							<div className="from">{item.from}</div>
							{(item.is_in_out === 'IN' ? 
								<div className="flight-info">
									<FlightIcon/>{item.flight_number} <ScheduleIcon/> {item.flight_time}<br/>
								</div>
								:
								<div className="tranfer-info">
									<ShuttleIcon/>{item.trf_type_en} <AdultIcon/>{item.adults}  <ChildIcon/> {item.children}
								</div>
							)}
							<div className="to">{item.to}</div>
							{(item.is_in_out === 'IN' ? 
								<div className="tranfer-info">
									<ShuttleIcon/>{item.trf_type_en} <AdultIcon/>{item.adults}  <ChildIcon/> {item.children}
								</div>
								:
								<div className="flight-info">
									<FlightIcon/>{item.flight_number} <ScheduleIcon/> {item.flight_time}<br/>
								</div>
							)}
						</div>
					</div>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}