import Cookies from 'universal-cookie';
import Encrypt from '../libraries/SessionEncrypt';
import ApiXHub from '../../services/ApiXHub';
import Validation from '../../services/Validation';

const cookies = new Cookies();
const isoEn = ["en","en-US", "en-EG", "en-AU", "en-GB", "en-CA", "en-NZ", "en-IE", "en-ZA", "en-JM", "en-BZ", "en-TT"];
const isoFr = ["fr", "fr-BE", "fr-CA", "fr-CH", "fr-LU"];
const isoPt = ["pt", "pt-BR"];
const isoEs = ["es", "es-AR", "es-GT", "es-CR", "es-PA", "es-DO", "es-MX", "es-VE", "es-CO", "es-PE", "es-EC", "es-CL", "es-UY", "es-PY", "es-BO", "es-SV", "es-HN", "es-NI", "es-PR"];

class AppHelpers {
	existsLangCookie ()
	{
		let Locale = cookies.get('locale');
		return (Locale !== undefined && Locale !== null ? true : false)
	}
	getLangCookie ()
	{
		let Locale = cookies.get('locale');
		return (Locale !== undefined && Locale !== null ? Locale : 'en-CA')
	}
	getLangNav()
	{
		var lang = window.navigator.language || navigator.language || navigator.userLanguage;
		var isEn = isoEn.find((item) => { return item === lang});
		var isEs = isoEs.find((item) => { return item === lang});
		var isFr = isoFr.find((item) => { return item === lang});
		var isPt = isoPt.find((item) => { return item === lang});
		if(isEn !== undefined){return 'en-CA';}
		if(isEs !== undefined){return 'es';}
		if(isFr !== undefined){return 'fr';}
		if(isPt !== undefined){return 'pt-BR';}
		return 'en-CA';
	}
	formatAMPM(date)
	{
		// console.log(date);
		var ampmo = date.split(" ");
		var time = date.split(":");
		var hours = time[0];
		var minutes = time[1];
		var ampm = ampmo[1];
		// var hours = date.getUTCHours();
		// var minutes = date.getUTCHours();
		// var ampm = hours >= 12 ? 'PM' : 'AM';
		// hours = hours % 12;
		// hours = hours ? hours : 12; // the hour '0' should be '12'
		// minutes = minutes < 10 ? '0'+minutes : minutes;
		var strTime = hours + ':' + minutes + ' ' + ampm;
		var clean = strTime.split(" ");
		strTime = clean[0] +  ' ' + clean[1];
		// console.log(ampmo);
		return strTime;
	}
	splitTime(time)
	{
		// console.log(date);
		var ampmo = time.split(" ");
		var ampm = ampmo[1];

		var mtTime = ampmo[0].split(":");
		var hours = (ampm === 'PM' ? (parseInt(mtTime[0]) + 12) : parseInt(mtTime[0]));
		var minutes = parseInt(mtTime[1]);
		var seconds = parseInt(mtTime[2]);
		
		var arrTime = [hours, minutes, seconds];
		// console.log(arrTime);
		return arrTime;
	}
	splitDate(date)
	{
		// console.log(date);
		var mtDate = date.split("/");
		var month = (mtDate[0] - 1);
		var day = mtDate[1];
		var year = mtDate[2];
		
		var arrDate = [month, day, year];
		// console.log(arrTime);
		return arrDate;
	}
	correctDate(date, time)
	{
		var arrDate = this.splitDate(date);
		// var arrTime = this.splitTime(time);

		var month = arrDate[0];
		var day = arrDate[1];
		var year = arrDate[2];

		// var hours = arrTime[0];
		// var minutes = arrTime[1];
		// var seconds = arrTime[2];

		// var correctDate = new Date(year, month, day, hours, minutes, seconds);
		var correctDate = new Date(year, month, day);

		return correctDate;
	}
	checkError()
	{
		const params = new URLSearchParams(window.location.search);
		
		if(params.has('error'))
		{
			let tag = params.get('error');
			
			switch(tag) {
				case 'not-cookies': 
					//Validation.showNotification("Token Invalido");
					console.log("No tiene cookies");
					break;
				case 'token-invalid': 
					//Validation.showNotification("Token Invalido");
					console.log("Token Invalido");
					break;
				case 'not-registered': 
					//Validation.showNotification("No registrado");
					console.log("No registrado");
					break;
				case 'request-invalid': 
					//Validation.showNotification("Request Invalido");
					console.log("Request Invalido");
					break;
				case 'canceled-reservation': 
					//Validation.showNotification("Reserva Cancelada");
					console.log("Reserva Cancelada");
					break;
				default:
					break;
			}
		}
	}
	logout () {
		// cookies.remove('xhub-info');
		// localStorage.removeItem('xhub-entry-id');
		// localStorage.removeItem('xhub-session');
		console.log("Logout");
	}
	redirectSession() {
		//console.log("Redirect");
		// this.logout();
	}
	async checkSession(isRegistered = true){
		const session = new Encrypt();
		let User = cookies.get('xhub-info');
		let EntryId = localStorage.getItem('xhub-entry-id');
		let XhubSession = localStorage.getItem('xhub-session');

		if((User === undefined || User === null) || (EntryId === undefined || EntryId === null) || (XhubSession === undefined || XhubSession === null))
		{
			window.location = '/login?error=not-cookies'
		}
		
		let UserInfo = session.decryptUser(cookies);

		if(isRegistered)
		{	
			
			let lang = (Validation.isEmpty(UserInfo.language) ? lang : UserInfo.language);
            //console.log(lang)
			let request = {
				token: XhubSession,
				code: UserInfo.locator,
				lang: lang
			}
			
			request = Validation.getBookings(request);

			if(request)
			{
				let response = await ApiXHub.getBookings(request);
				if(!response.data.is_valid)
				{
					window.location = '/login?error=token-invalid'
				}
			}
		}

		// if(!UserInfo.is_registered)
		// {
		// 	window.location = '/login?error=not-registered'
		// }
		if(!UserInfo.is_valid)
		{
			window.location = '/login?error=request-invalid'
		}
		if(UserInfo.status !== 'OK')
		{
			window.location = '/login?error=canceled-reservation'
		}
	}
}

export default AppHelpers;