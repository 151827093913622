import React, { Component } from 'react';
import {Link} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../../css/rules-carousel.css";
import {Carousel} from 'react-responsive-carousel';

class HomeCarousel extends Component {

	getRoute = (tag, config = null, bookings = null, index = 0,  image = null, openChat, iso) => {
			var url = '';
			if(tag.includes("|"))
			{
				var res = tag.split("|");
				tag = res[0];
				url = res[1];
				//console.log(url, tag);
			}
			switch (tag)
			{
				case 'EXPERIENCES_FINDER':
					//console.log(url, tag);
					return (<a key={index} id={index} href={url+config.delegation.zone_id+"&startDate="+bookings.booking.bw_ini.substring(0, bookings.booking.bw_ini.indexOf("T"))+"&endDate="+bookings.booking.bw_end.substring(0, bookings.booking.bw_end.indexOf("T"))+"&paxs=10&culture="+iso+"&idioma="+iso}
												className="link-banner"
												target="_blank"
												rel="noopener noreferrer">
												<img src={image.fields.file.url} alt={image.fields.title} />
										</a>);
				case 'REQUEST_UPGRADE':
					return (<Link key={index} id={index} to="/transfer-upgrade" className="link-banner">
												<img src={image.fields.file.url} alt={image.fields.title} />
										</Link>);
				case 'BE_SAFE_NEXUS':
					return (<a key={index} id={index} href={"https://www.nexustours.com/besafe&culture="+iso+"&idioma="+iso} className="link-banner" target="_blank" rel="noopener noreferrer">
												<img src={image.fields.file.url} alt={image.fields.title} />
										</a>);
				case 'CONTACT_US':
					return (<Link key={index} id={index} to="/contact-us" className="link-banner">
												<img src={image.fields.file.url} alt={image.fields.title} />
										</Link>);
				case 'SEARCH_EXC':
					return (<a key={index} id={index} href={url+"&startDate="+bookings.booking.bw_ini.substring(0, bookings.booking.bw_ini.indexOf("T"))+"&endDate="+bookings.booking.bw_end.substring(0, bookings.booking.bw_end.indexOf("T"))+"&paxs=10&productType=TKT&prov=SGN"}
												className="link-banner"
												target="_blank"
												rel="noopener noreferrer">
												<img src={image.fields.file.url} alt={image.fields.title} />
										</a>);
				case 'NEW_URL':
					return (<a key={index} id={index} href={url}
												className="link-banner"
												target="_blank"
												rel="noopener noreferrer">
												<img src={image.fields.file.url} alt={image.fields.title} />
										</a>);
				case 'OPEN_CHAT':
					return (<a key={index} id={index} href="#" onClick={() => openChat()}
										className="link-banner"
										rel="noopener noreferrer">
										<img src={image.fields.file.url} alt={image.fields.title} />
									</a>);
				default:
					if(image !== null){
						return (<Link key={index} id={index} to="/" className="link-banner">
											<img src={image.fields.file.url} alt={image.fields.title} />
										</Link>);
					} else {
						return '';
					}
			}
	}

	render () {
		const {images, config, bookings, openChat, locale} = this.props;
		if(images !== null && bookings !== null && config !== null)
		{
			return (
				<Carousel autoPlay infiniteLoop centerMode>
					{images.map((image, i) => this.getRoute(image.fields.description, config, bookings, i, image, openChat, locale))}
				</Carousel>
			);
		}
		return (<div></div>);
	}
}

export default HomeCarousel;