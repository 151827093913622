import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Card, makeStyles } from "@material-ui/core";
import SocialMedias from "./SocialMedias";
import MyRep from "./MyRep";
import Loader from "../../partials/Loader";
// import ListItems from '../common/ListItems';
import "../../../css/contact-us.css";
import Markdown from "react-markdown";
const Smooch = require("smooch");
const ContForm = (props) => {
  const {
    contact,
    planification,
    trans,
    styles,
    openMsgLink,
    link,
    linkText,
    links,
    token,
  } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles(props);
  // console.log(contact)
  if (styles === null && contact === null && planification === null) {
    return <div></div>;
  } else {
    return (
      <div>
        <img
          src={contact.fields.howToContactUs.fields.file.url}
          alt={contact.fields.howToContactUs.fields.title}
          width={"100%"}
        />
        <img
          src={contact.fields.virtualConcierge.fields.file.url}
          alt={contact.fields.virtualConcierge.fields.title}
          width={"100%"}
        />
        {token !== false ? (
          <Loader styles={styles} links={links} />
        ) : (
          <MyRep
            planification={planification}
            token={token}
            trans={trans}
            styles={styles}
            links={links}
            contact={contact}
          />
        )}
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          alignItems="stretch"
          className={"contact-container-cards"}
        >
          <Grid item xs={6}>
            <Card className="contact-card-item" elevation={3}>
              <div>
                <img
                  src={contact.fields.imgCall.fields.file.url}
                  className="contact-card-img"
                  alt={contact.fields.titleCall}
                />
                <span className={classes.contactCardTitle}>
                  {contact.fields.titleCall}
                </span>
              </div>
              <div className="contact-card-links">
                {contact.fields.descriptionCall
                  .split("\n")
                  .map((content, i) => {
                    return <Markdown key={i} children={content} />;
                  })}
              </div>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className="contact-card-item" elevation={3}>
              <div>
                <img
                  src={contact.fields.imgChat.fields.file.url}
                  className="contact-card-img"
                  alt={contact.fields.titleChat}
                />
                <span className={classes.contactCardTitle}>
                  {contact.fields.titleChat}
                </span>
              </div>
              <div className="contact-card-links">
                {contact.fields.descriptionChat
                  .split("\n")
                  .map((content, i) => {
                    return <Markdown key={i} children={content} />;
                  })}
              </div>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} style={{ marginLeft: "0px" }}>
            <Card className="container-social" elevation={0}>
              <h5 className={classes.titleSocial}>
                {contact.fields.titleSocial}
              </h5>
              <SocialMedias images={contact.fields.socialMedia} />
            </Card>
          </Grid>
          <Grid item xs={6} style={{ marginRight: "0px" }}>
            <Card elevation={0}>
              <div className="container-btn-call">
                {/* <button className="chat-rep"  id="open-chat">
									<span>{links.translations.chat_chat_rep}</span>
									<img src={contact.fields.imgSunCo.fields.file.url} alt={links.translations.chat_chat_rep}/>
								</button> */}
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} zeroMinWidth>
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.titleWeb}
            >
              {linkText}
            </a>
          </Grid>
        </Grid>
      </div>
    );
  }
};
class ContactUs extends Component {
  // constructor (props) {
  // 	super(props)
  // }

  // openMsgLink(){
  // 	(function(w,d,t,u,n,a,m){
  // 		a=d.createElement(t);
  // 		m=d.getElementsByTagName(t)[0];
  // 		a.async=1;
  // 		a.src=u;m.parentNode.insertBefore(a,m)
  // 	  })
  // 	  (window,document,"script","https://mas-wepartner.inconcertcc.com/public/integration/scripts.js?token=9295d6e620e3e515555646d0a68a611b","ic");

  // }
  // componentDidMount() {
  // 	this.openMsgLink();
  //   }
  render() {
    const { contact, planification, trans, styles, links, loader, token } =
      this.props;

    const link = links.hotel_link;
    const linkText = links.text;
    console.log(planification);
    if (contact !== null && styles !== null) {
      document.title = contact.fields.title;
      return (
        <ContForm
          contact={contact}
          planification={planification}
          trans={trans}
          styles={styles}
          links={links}
          link={link}
          linkText={linkText}
          openMsgLink={this.openMsgLink}
          loader={loader}
          token={token}
        />
      );
    } else {
      return <div></div>;
    }
  }
}
export default ContactUs;
export { withRouter };
