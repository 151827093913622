import React from 'react';
import { ReactNotifications } from 'react-notifications-component';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import RadioButtonTransfer from '../common/RadioButtonTransfer';

export default function Confirmation(props) {
	const {agency, settings } = props;
	const userStyles = makeStyles(agency)
	const classes = userStyles(props)
	//console.log(settings);
	if (settings === null) {
		return (<div></div>)
	}
	else{
		return (
			<div className="container-checkin container-confirm-form">
					<ReactNotifications/>
					<p className="booking">{settings.translations.form_group_booking_number } { (props.booking.agency_ref ? props.booking.agency_ref : props.booking.locator)}</p>
					<h1 className={classes.titleDepature}>{ settings.translations.form_group_title_confim }</h1>
					<p className="description">{ settings.translations.form_group_description_confim }</p>
					<div className={classes.containerBookingLines}>
						{ props.booking.lines.map((item, i)=> (item.line_id === props.values.arrival ? 
							<RadioButtonTransfer 
								key={i} 
								id={i} 
								locator={props.booking.locator}
								date={item.meeting_shortdate}
								item={item} 
								handleChange={props.handleChange('arrival', item.line_id)}
								value={props.values.arrival}
								search={""}
								links={settings}
								agency={agency}
								withoutName={true}
								/> : ''))}
						{ props.booking.lines.map((item, i)=> (item.line_id === props.values.departure ? 
							<RadioButtonTransfer 
								key={i} 
								id={i} 
								locator={props.booking.locator}
								date={item.meeting_shortdate}
								item={item} 
								handleChange={props.handleChange('departure', item.line_id)}
								value={props.values.departure}
								search={""}
								links={settings}
								agency={agency}
								withoutName={true}
								/> : ''))}
					</div>
			  <div className="wizard-control">
				<Button size="large" onClick={props.prevStep} classes={{root: "prev"}}>{ settings.translations.form_group_btn_prev }</Button>
				<Button size="large" style={{
								color: settings.primary_color,
							}} onClick={props.nextStep} classes={{root: "next"}}>{ settings.translations.form_group_btn_next }</Button>
			  </div>
				</div>
		  );


	}

}