import Cookies from 'universal-cookie';

const cookies = new Cookies();

class AppLanguage {
	getLang ()
	{
		let Locale = cookies.get('locale');
        return (Locale !== undefined && Locale !== null ? Locale : 'en-CA')
	}
}

export default AppLanguage;