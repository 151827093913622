import React from 'react';
import {Card, CardActionArea, CardContent} from '@material-ui/core';
import UncheckIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/CheckCircle';

export default function RadioButtonNonTransfer(props) {
	return (
		<Card className={'card-main card-main-trf non ' + (props.value === 'non' ? 'selected' : '')} variant="outlined">
			<CardActionArea onClick={props.handleChange}>
				<CardContent>
					<div className="card-content card-trf-content">
						<div className="icon-radio">
							{(props.value === 'non' ? <CheckIcon/> : <UncheckIcon/>)}
						</div>
						<div className="content-info">
							<div className="not-trf">{ props.label }</div>
						</div>
					</div>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}