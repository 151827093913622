import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import {usingStyles} from '../../services/themeOptions'

const Loader = (props) => {
	const {styles,links} = props;
	if(styles===null){
		const classing=usingStyles(styles)
		//console.log(links)

		return (
			<div className={classing.containerBackLoader}>
				<div className={classing.containerLoader}>
					<CircularProgress
							style={{
							color: props.settings.primary_color
						  }}
					/>
				</div>
			</div>
		)
	}
	else
	{
		//console.log('2')
		//console.log(styles,links)
		const classing=usingStyles(styles)
		return(
           <div className={classing.containerBackLoader}>
			<div className={classing.containerLoader}>
				<CircularProgress
				style={{
					color: links.primary_color
				  }}
				/>
			</div>
		</div>
	)

	}
	
}

export default Loader