import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { IconButton, Grid, Checkbox, Box,  makeStyles } from '@material-ui/core';
import DrawerLanguage from '../../partials/DrawerLanguage';
import ContactItem from '../contact-form/ContactItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chat from '../common/Chat';
import Cookies from 'universal-cookie';
import { ReactNotifications } from 'react-notifications-component';
import Encrypt from '../../libraries/SessionEncrypt';
import Helpers from "../../libraries/helpers";
import Validation from '../../../services/Validation';
import ApiXHub from '../../../services/ApiXHub';
import translations from "../../../data/translation.json";
import ContactItems from "../../../data/contact-form.json";
import VirtualBriefImg from '../../../images/02-register-banner-vc-170x170.jpg';
import "../../../css/contact-form.css";
import '../../../scss/ContactFlow.scss';
import {  propsStyle, getContentfulAsset } from '../../../services/themeOptions'
import { ArrowDropDown } from '@material-ui/icons';
const cookies = new Cookies();
const helpers = new Helpers();
 function ContForm (props)  {
	const { locale, loader, openLang, form,agency,loading,handleChangeLocale, handleChange,sendForm,settings,handleDrawerToggleLang} = props
		 //console.log(agency)
		const userStyles=makeStyles(agency)
		const classes=userStyles(props)
		// console.log(props)


	if(loading===false)
	{

		return (
			<div className="container-contact-form">
				<IconButton
					color="inherit"
					aria-label="language"
					onClick={() => handleDrawerToggleLang()}
					className={classes.btnLanguage}
				>
					{locale === 'es' ? 'ESP' : (locale === 'fr' ? 'FRA' : (locale === 'pt-BR' ? 'POR' : 'ENG'))}
					<ArrowDropDown></ArrowDropDown>
				</IconButton>
				<ReactNotifications />
				<Grid container>
					<Grid item xs={12} sm={12}>
						<Box className={classes.textlocalexpert}>{settings.translations.form_contact_local_expert_ini}<strong className={classes.strongs}>{settings.translations.form_contact_local_expert}</strong>{settings.translations.form_contact_local_expert_end}</Box>
						<img src={VirtualBriefImg} alt={settings.translations.form_contact_local_expert} className="img-local-expert" />
						<p className={classes.descriptionlocalexpert}>{settings.translations.form_contact_description_1}</p>
					</Grid>
					<Grid item xs={12} sm={12} style={{ marginBottom: '30px' }}>
						<p className={classes.descriptionlocalexpert}>{settings.translations.form_contact_how_contact_you}</p>
						<div className="cards-selection">
							<div className="contact-info">
								<div className="input-contact-info large">
									<label htmlFor="firstname" className={classes.inputcontactinfolabel}>{settings.translations.form_login_firstname}</label>
									<input type="text" name="firstname" onChange={handleChange} value={form.firstname} />
								</div>
							</div>
							<div className="contact-info">
								<div className="input-contact-info large">
									<label htmlFor="lastname" className={classes.inputcontactinfolabel}>{settings.translations.form_login_lastname}</label>
									<input type="text" name="lastname" onChange={handleChange} value={form.lastname} />
								</div>
							</div>
							{ContactItems.map((item, i) => <ContactItem className={classes.inputcontactinfolabel} key={i} id={i} item={item} locale={locale} handleChange={handleChange} Styles={agency} loading={true} userStyles={classes} links={settings} />)}
						</div>
						<div className={classes.oneOptionContactForm}>
							<p>{settings.translations.form_contact_one_required}</p>
							<p className="sms-notify">{settings.translations.form_contact_terms_sms}</p>
						</div>
						<div className={classes.termsContactForm}>
							<div className="check-terms">
								<Checkbox checked={form.terms} name="terms" onChange={handleChange} style={{color: props.settings.company_color}}/>
							</div>
							<label className="terms" htmlFor="terms">{settings.translations.form_contact_terms}<a href="https://www.nexustours.com/privacy-policy">{settings.translations.form_contact_privacy_policy}</a>.</label>
						</div>
						{loader === true ? <CircularProgress
							style={{
							color: settings.primary_color
						  }}
					/> : <button className={classes.buttonL} onClick={() => sendForm()}>{settings.translations.form_contact_btn_send}</button>}
					</Grid>
				</Grid>
				<DrawerLanguage open={openLang} onClose={handleDrawerToggleLang}  handleChangeLocale={handleChangeLocale} />
				<div className='chatbox'>
				<Chat
				agency={agency}	
				links={settings}	
				/>		
			   </div>
			</div>
		);
	}
	else
	{
		return(<div></div>);
	}

  };
class ContactForm extends Component {
	
	constructor(props) {
		super(props)
		this.state = {
			openLang: false,
			locale: (helpers.existsLangCookie() ? helpers.getLangCookie() : helpers.getLangNav()),
			loader: false,
			ip_client: "",
			form: {
				check_sms: false,
				check_email: true,
				check_whatsapp: false,
				firstname: "",
				lastname: "",
				sms: "",
				email: "",
				whatsapp: "",
				channels: [],
				terms: false
			},
			agency: null,
			loading: true,
			settings:null

		}
	}
	
	handleDrawerToggleLang = () => {
		this.setState({
			...this.state,
			openLang: !this.state.openLang
		})
	}

	handleChangeLocale = async (iso) => {
		cookies.set('locale', iso, { path: "/" });
		await this.setState({
			...this.state,
			locale: iso
		})
		this.handleDrawerToggleLang();
		this.getMainAgency();
	}

	handleLoader = (status) => {
		// console.log(status);
		this.setState({
			...this.state,
			loader: status
		})
	}

	handleChange = async (e) => {
		// console.log(e);
		 //console.log(e);
		// console.log(e.target.name + " => " + e.target.checked);
		await this.setState({
			...this.state,
			form: {
				...this.state.form,
				[e.target.name]: (e.target.type === "checkbox" ? e.target.checked : e.target.value)
			}
		})
	}

	setClientInfo = async (name, lastname) => {
		await this.setState({
			...this.state,
			form: {
				...this.state.form,
				firstname: name,
				lastname: lastname
			}
		})
	}

	sendForm = async () => {
		this.handleLoader(true);
		let Request = await Validation.contactForm(this.state, this.props.values);
		// console.log(Request);
		if (Request) {
			// console.log(Request);
			let response = await ApiXHub.register(Request);

			if (response.data.is_valid) {
				// Almacenar el token en cookie
				localStorage.setItem('xhub-session', response.data.token);

				// Solicitar login para actualizar
				let requestLogin = {
					code: Request.locator,
					contact: Request.channels[0].value,
					company: this.state.settings.simple_name
				}
				//console.log(requestLogin);
				requestLogin = Validation.loginRegister(requestLogin);
				if (requestLogin) {
					let responseLogin = await ApiXHub.login(requestLogin);
					if (responseLogin.data.is_valid) {
						// Encriptar del login
						const session = new Encrypt();
						let pathname = cookies.get('eh_pathname');
						let user = responseLogin.data;
						let sessionEncrypt = session.encrypt(user);
						cookies.set('xhub-info', sessionEncrypt, { path: "/" });

						ReactGA.event({
							category: 'Contact Form',
							action: 'Register Contacts',
							label: 'Button Register'
						});

						if (pathname !== undefined) {
							window.location = pathname
						} else {
							window.location = '/home'
						}
					}
				}
				//console.log(response);
			}

		}
		this.handleLoader(false);
	}

	getMainAgency =  async () =>{
    let aw=await propsStyle(this.state.locale);
	  let ag=await getContentfulAsset(this.state.locale);
	  let value="2"
	  let set = {
			simple_name: ag.name,
			hostname: ag.hostname,
			text: ag.companyName,
			link: ag.deeplink,
			company_color: ag.companyColor,
			primary_color: ag.primaryColor,
			secondary_color: ag.secondaryColor,
			company_label: ag.logoLeft,
			xhub_label: ag.urlLogoRight,
			background_image:ag.imgBackgroundLogin,
			image_call:ag.imgCall,
			image_chat:ag.imgChat,
			translations:ag.translations,
			validations:ag.validations,
			valued:value==="1"?'container-chat2':'container-chat' 
			// company_label_navbar: urlXhubLogoNavbar
		};
		set.primary_color="#"+set.primary_color
		set.secondary_color="#"+set.secondary_color
		set.company_color="#"+set.company_color
	  // console.log(aw)
	  this.setState({...this.state, agency: aw, loading: false,settings:set})
	}
activateMsgLink= async ()=> {
		(function(w,d,t,u,n,a,m){
				  a=d.createElement(t);
				  m=d.getElementsByTagName(t)[0];
				  a.async=1;
				  a.src=u;m.parentNode.insertBefore(a,m)
				})
				(window,document,"script","https://mas-wepartner.inconcertcc.com/public/integration/scripts.js?token=9295d6e620e3e515555646d0a68a611b","ic");
		
	  }

	componentWillMount() {   
		 this.getMainAgency();
		 this.activateMsgLink();
	}

	componentDidMount() {
		helpers.checkSession(false);
		const {name, lastname} = this.props;
		if(name === undefined && lastname === undefined){
			const session = new Encrypt();
			let UserAuth = session.decryptUser(cookies);
			//console.log(UserAuth);
			this.setClientInfo(UserAuth.name, UserAuth.lastname);
		} else {
			this.setClientInfo(name, lastname);
		}
  }

	render() {
		//  console.log(this.state.locale)
		if(this.state.loading===false)
		{
			// console.log(this.state.settings.simple_name)
			return(
				<ContForm
						locale={this.state.locale}
						loader={this.state.loader}
						openLang={this.state.openLang}
						form={this.state.form}
						agency={this.state.agency}
						loading={this.state.loading}
						setClientInfo={this.setClientInfo}
						handleChangeLocale={this.handleChangeLocale}
						handleDrawerToggleLang={this.handleDrawerToggleLang}
						sendForm={this.sendForm}
                        handleChange={this.handleChange}
						settings={this.state.settings}
						trans={translations[this.state.locale]}
					/>
				
				)
		} else {
			return(<div></div>)
		}
	}
}
export default   (ContactForm);