import React, { useEffect, useState } from 'react'
import {Switch, Route, Link} from "react-router-dom";
import {IconButton, makeStyles} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIosRounded'
import Navbar from './Navbar'
import DrawerMenu from './DrawerMenu';
import DrawerLanguage from './DrawerLanguage';
import Config from "../../data/config.json";
import translations from "../../data/translation.json";
import Home from '../sections/home/Home';
import MyTrip from '../sections/my-trip/MyTrip';
import UpgradeTransfer from '../sections/upgrade-transfer/UpgradeTransfer';
import ContactUs from '../sections/contact-us/ContactUs';
import ShareMyExperience from '../sections/share-my-experience/ShareMyExperience';
import MyNexusWallet from '../sections/my-nexuswallet/MyNexusWallet';
import ModalBox from '../sections/common/ModalBox';
import Loader from '../partials/Loader';
import '../../css/grid.css';
import Cookies from 'universal-cookie';
import Encrypt from '../libraries/SessionEncrypt';
import ApiLogisRep from '../../services/ApiLogisRep';
import ApiXHub from '../../services/ApiXHub';
import Validation from '../../services/Validation';
import Helpers from "../libraries/helpers";
import Chat from '../sections/common/Chat';
import {  propsStyle, getContentfulAsset } from '../../services/themeOptions'
const cookies = new Cookies();
const helpers = new Helpers();

const userStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		maxWidth: '768px',
		alignItems: 'center',
		margin: '0 auto',
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		backgroundColor: '#fff',
		padding: theme.spacing(0),
	},
	imageBanner: {
		width: '100%',
		height: 'auto',
	},
	button: {
		position: 'fixed',
  		top: '80px',
		left: '4px',
  		zIndex: 100,
		backgroundColor: 'rgba(243,243,243,0.8)',
		boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
	},
	btnIcon:{
		color: '#575756'
	},
}))

const Container = () => {
	const contentful = require("contentful");
	const client = contentful.createClient({
		// This is the space ID. A space is like a project folder in Contentful terms
		space: Config[Config.env].cf_space,
		// This is the access token for this space. Normally you get both ID and the token in the Contentful web app
		accessToken: Config[Config.env].cf_token,
		// This is the environment for this space.
		environment: Config[Config.env].cf_environment
	});

	const classes = userStyles();
	const [loader, setLoader] = useState(false);
	const [open, setOpen] = useState(false);
	const [openLang, setOpenLang] = useState(false);
	const [openHeader, setOpenHeader] = useState(false);
	const [locale, setLocale] = useState('en-CA');
	const [config, setConfig] = useState(null);
	const [bookings, setBookings] = useState(null);
	const [mainDestination, setMainDestination] = useState(null);
	const [images, setImages] = useState(null);
	const [myTrip, setMyTrip] = useState(null);
	const [upgradeTransfer, setUpgradeTransfer] = useState(null);	
	const [contactUs, setContactUs] = useState(null);
	const [shareMyExperience, setShareMyExperience] = useState(null);
	const [nexusWallet, setNexusWallet] = useState(null);
	const [planification, setPlanification] = useState(null);
	const [styles, setStyles] = useState(null);
	const [token, settoken] = useState(true);
	const [links, setLinks] = useState(null);

	const getMainDestination = async (Locale) => {
		setLoader(true);
		helpers.checkSession();
		const session = new Encrypt();
		//let User = cookies.get('xhub-info');
		let EntryId = localStorage.getItem('xhub-entry-id');
		let XhubSession = localStorage.getItem('xhub-session');
		let UserInfo = session.decryptUser(cookies);

		cookies.set('eh_pathname', window.location.pathname, {path: "/"});
		client
		.getEntry(EntryId, {locale:Locale})
		.then((response) => {
			setMainDestination(response.fields);
			document.title = response.fields.name;
			if( response.fields.banner !== null)
			{
				//console.log(Locale)
				setImages(response.fields.banner)
				
			}
			if(response.fields.upgradeTransfer)
			{
				client
				.getEntry(response.fields.upgradeTransfer.sys.id, {locale: Locale})
				.then((response) => {
					setUpgradeTransfer(response);
					// console.log(response);
				})
				.catch(err => console.log(err));
			}
			if(response.fields.myTrip)
			{
				client
				.getEntry(response.fields.myTrip.sys.id, {locale: Locale})
				.then((response) => {
					setMyTrip(response);
					// console.log(response);
				})
				.catch(err => console.log(err));
			}
			if(response.fields.contactUs)
			{
				client
				.getEntry(response.fields.contactUs.sys.id, {locale: Locale})
				.then((response) => {
				setContactUs(response);
				// console.log(contactUs);
				})
				.catch(err => console.log(err));
			}
			if(response.fields.shareMyExperience)
			{
				client
				.getEntry(response.fields.shareMyExperience.sys.id, {locale: Locale})
				.then((response) => {
					setShareMyExperience(response);
					// console.log(contactUs);
				})
				.catch(err => console.log(err));
			}
			if(response.fields.myNexusWallet)
			{
				client
				.getEntry(response.fields.myNexusWallet.sys.id, {locale: Locale})
				.then((response) => {
					setNexusWallet(response);
					// console.log(contactUs);
				})
				.catch(err => console.log(err));
			}
			
		})
		.catch(err => console.log(err));

		let request = {
			token: XhubSession,
			code: UserInfo.locator,
			lang: locale
		}
		
		request = Validation.getBookings(request);

		if(request)
		{
			// console.log(request);
			let response = await ApiXHub.getBookings(request);
			 //console.log(response);
			if(response.data.is_valid)
			{
				let requestConfig = {
					code: UserInfo.locator,
					lang: locale
				}
				requestConfig = Validation.getConfig(requestConfig);
				if(requestConfig)
				{
					let responseConfig = await ApiXHub.getConfig(requestConfig);
					if(responseConfig.data.is_valid)
					{
						// console.log(responseConfig.data);
						// console.log(response.data);
						setConfig(responseConfig.data);
						setBookings(response.data);
						handlePlanification(responseConfig.data);
					} else {
						window.location = '/login'
					}
				}
			} else {
				//response.data.errors[0].type
				window.location = '/login?error=token-invalid'
			}
		}
		setLoader(false);
		
	}
	const getMainAgency =  async (Locale) =>{
		let aw= await propsStyle(Locale);
		setStyles(aw)
		let ag= await getContentfulAsset(Locale);
		//console.log(ag);
		let value="2"
		let set = {
		  simple_name: ag.name,
		  hostname: ag.hostname,
		  text: ag.companyName,
		  link: ag.deeplink,
		  company_color: ag.companyColor,
		  primary_color: ag.primaryColor,
		  secondary_color: ag.secondaryColor,
		   company_label: ag.logoLeft,
		   xhub_label: ag.LogoRight,
		  company_label_navbar: ag.XhubLogoNavbar,
		  background_image:ag.imgBackgroundLogin,
		  image_call:ag.imgCall,
		  image_chat:ag.imgChat,
		  hotel_link:ag.companyLink,
		  brochure:ag.showBrochure,
		  translations:ag.translations,
		  validations:ag.validations,
		  valued:value==="1"?'container-chat2':'container-chat' 

	  };
	  set.primary_color="#"+set.primary_color
	  set.secondary_color="#"+set.secondary_color
	  set.company_color="#"+set.company_color
	  // console.log(aw)
	  
	  setLinks(set)
	}
	const handleDrawerToggle = () => {
      setOpen(!open);
	}
	const handleDrawerToggleLang = () => {
		setOpenLang(!openLang);
	}
	const handleDrawerToggleHeader = () => {
		setOpenHeader(!openHeader);
	}
	const handleChangeLocale = (iso) => {
		settoken(true)
		//console.log(iso)
		let XhubSession = localStorage.getItem('xhub-session');
		setOpenLang(false);
		setLocale(iso)
		cookies.set('locale', iso, {path: "/"});
		if(bookings !== null && (XhubSession !== undefined || XhubSession !== null))
		{
			if(iso.indexOf('-') > 0)
			{
				iso = iso.substring(0, iso.indexOf('-'));
			}

			let request = {
				token: XhubSession,
				language: iso
			}

			request = Validation.changeLang(request);

			if(request.language!=null)
			{
				//console.log(request);
				//let response = await ApiXHub.changeLang(request);
				ApiXHub.changeLang(request);
				//console.log(response);
				// if(response.data.is_valid)
				// {}
			}
		}
	}
	const handleChangePathname = () => {
		cookies.set('eh_pathname', window.location.pathname, {path: "/"});
	}
	const handleBookings = (newBookings) => {
		setBookings(newBookings);
	}
	const handlePlanification = async (config) => {
		
		if(config !== null)
		{
			//console.log(config.logisrep)
			setLoader(true)
			//console.log(token)
			let iso = locale;
			if(iso.indexOf('-') > 0)
			{
				iso = iso.substring(0, iso.indexOf('-'));
			}
			
			let response = await ApiLogisRep.getPlanification({
				delegationId: config.delegation.id, language: iso, agency: 'VE', hotelName: config.logisrep.name, hotelId:  config.logisrep.id
			}).then((response)=>{
				//console.log(response.data)
				setLoader(false)
				settoken(false)
				return(setPlanification(response.data))	 
			}
			
			);
		}
		
	}
	const activateMsgLink= async ()=>{
		(function(w,d,t,u,n,a,m){
			a=d.createElement(t);
			m=d.getElementsByTagName(t)[0];
			a.async=1;
			a.src=u;m.parentNode.insertBefore(a,m)
		  })(window,document,"script","https://mas-wepartner.inconcertcc.com/public/integration/scripts.js?token=9295d6e620e3e515555646d0a68a611b","ic");
	}
	const openChatLink= ()=>{
		
		document.getElementById("open-chat").click()

	}
    const changeLocales=()=>
	{
		let Locale = cookies.get('locale');
		
		if(Locale !== undefined && Locale !== null||locale!==null && locale !==undefined)
		{
			
			 //console.log(Locale);
			handleChangeLocale(Locale);
			setLocale(Locale);
			getMainAgency(Locale);
			getMainDestination(Locale);
		}
	}
	
	
	useEffect(() => {
		changeLocales();
		handleChangePathname();
		activateMsgLink();
	}, [locale])
	if(links===null)
	{
		return( <div></div> )
	}
	else
	{ 
		//console.log(styles)
		return (
				<div className={classes.root}>
					{ loader && <Loader
					styles={styles} 
					links={links}
					/> }
					{ <ModalBox bookings={bookings} trans={translations[locale]} styles={styles} links={links}/> }
					<Navbar handleDrawerToggle={handleDrawerToggle} handleDrawerToggleLang={handleDrawerToggleLang} handleDrawerToggleHeader={handleDrawerToggleHeader} locale={locale} bookings={bookings} config={config} header={openHeader} styles={styles} links={links}/>
					<main className={classes.content}>
						<div className={classes.toolbar} />
						<Switch>
							<Route path="/my-nexus-wallet">
								<MyNexusWallet wallet={nexusWallet} openChatLink={openChatLink} trip={myTrip} bookings={bookings} config={config} trans={translations[locale]} handleBookings={handleBookings} locale={locale} styles={styles} links={links}/>
							</Route>
							<Route path="/my-trip">
								<Link to="/">
									<IconButton aria-label="Back" className={classes.button + " back-to-home"}>
										<ArrowBackIosIcon fontSize="inherit" className={classes.btnIcon} />
									</IconButton>
								</Link>
								<MyTrip trip={myTrip} userInfo={bookings} openChatLink={openChatLink}  trans={translations[locale]} styles={styles} links={links}/>
							</Route>
							<Route path="/transfer-upgrade">
								<Link to="/">
									<IconButton aria-label="Back" className={classes.button+" back-to-home"}>
										<ArrowBackIosIcon fontSize="inherit" className={classes.btnIcon} />
									</IconButton>
								</Link>
								<UpgradeTransfer  trip={upgradeTransfer} openChatLink={openChatLink} trips={myTrip}userInfo={bookings}  trans={translations[locale]} styles={styles} links={links}/>
							</Route>
							<Route path="/contact-us">
								<Link to="/">
									<IconButton aria-label="Back" className={classes.button+" back-to-home"}>
										<ArrowBackIosIcon fontSize="inherit" className={classes.btnIcon}  />
									</IconButton>
								</Link>
		
								<ContactUs contact={contactUs} trans={translations[locale]} loader={loader} token={token} planification={planification} styles={styles} links={links} />
							
						
								</Route>
							
							<Route path="/share-my-experience">
								<Link to="/">
									<IconButton aria-label="Back" className={classes.button+" back-to-home"}>
										<ArrowBackIosIcon fontSize="inherit" className={classes.btnIcon} />
									</IconButton>
								</Link>
								<ShareMyExperience shareMyExperience={shareMyExperience} config={config} trans={translations[locale]} locale={locale} styles={styles} links={links} />
							</Route>
							<Route path="/">
								<Home destination={mainDestination}   images={images} bookings={bookings} config={config} locale={locale} trans={translations[locale]} styles={styles} links={links}/>
							</Route>
						</Switch>
					</main>
					<DrawerMenu open={open} onClose={handleDrawerToggle} handleDrawerToggle={handleDrawerToggle} trans={translations[locale]} bookings={bookings} config={config} styles={styles} links={links} locale={locale}  />
					<DrawerLanguage open={openLang} onClose={handleDrawerToggleLang} handleChangeLocale={handleChangeLocale} styles={styles} links={links} />
					<Chat
				agency={styles}
				links={links}/>
				</div>
		)
	}

}

export default Container;