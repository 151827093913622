import axios from "axios";
import config from "../data/config.json";
import {
  Booking,
  Login,
  Register,
  Lines,
  ValidateReservation,
  ValidateReservationLastname,
} from "./constants";

const ApiXHub = {
  getIP: async () => {
    // const response = SearchBooking;
    const response = await axios
      .get("/", {
        baseURL: config[config.env].getIP,
        params: { format: "json" },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  },
  getUser: async (data) => {
    // const response = BookingDestination;
    const response = await axios
      .get("/ExperiencesHub/BookingDestination", {
        baseURL: config[config.env].APIXHub,
        params: data,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  },
  channel: async (data) => {
    // const response = ChannelPreferred;
    const response = await axios
      .post("/ExperiencesHub/ChannelPreferred", data, {
        baseURL: config[config.env].APIXHub,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  },
  languagePreferred: async (data) => {
    // const response = BookingDestination;
    const response = await axios
      .post("/ExperiencesHub/BookingDestination", data, {
        baseURL: config[config.env].APIXHub,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  },
  generateBP: async (data) => {
    // const response = BoardingPass;
    const response = await axios
      .post("/ExperiencesHub/BoardingPass", data, {
        baseURL: config[config.env].APIXHub,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  },
  downloadBP: async (data) => {
    // const response = PDFBoardingPass;
    const response = await axios
      .get("/ExperiencesHub/PDFBoardingPass", {
        baseURL: config[config.env].APIXHub,
        params: data,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "http://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  },
  login: async (data) => {
    // const response = Login;
    const response = await axios
      .get("/ExperiencesHubGroups/login", {
        baseURL: config[config.env].APIXHub,
        params: data,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  },
  register: async (data) => {
    //const response = Register;
    const response = await axios
      .post("/ExperiencesHubGroups/register", data, {
        baseURL: config[config.env].APIXHub,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  },
  changeLang: async (data) => {
    //const response = Register;
    const response = await axios
      .post("/ExperiencesHubGroups/change-lang", data, {
        baseURL: config[config.env].APIXHub,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  },
  getBooking: async (data) => {
    // const response = Booking;
    const response = await axios
      .get("/ExperiencesHubGroups/get-reservation", {
        baseURL: config[config.env].APIXHub,
        params: data,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });

    return response;
  },
  getBookings: async (data) => {
    // const response = Booking;
    const response = await axios
      .get("/ExperiencesHubGroups/get-reservations", {
        baseURL: config[config.env].APIXHub,
        params: data,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });

    return response;
  },
  getConfig: async (data) => {
    // const response = Booking;
    const response = await axios
      .get("/ExperiencesHubGroups/get-config", {
        baseURL: config[config.env].APIXHub,
        params: data,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });

    return response;
  },
  getAirportTransferPass: async (data) => {
    // const response = Booking;
    const response = await axios
      .get("/ExperiencesHubGroups/airport-transfer-pass", {
        baseURL: config[config.env].APIXHub,
        params: data,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });

    return response;
  },
  addAirportTransferPass: async (data) => {
    //const response = Register;
    const response = await axios
      .post("/ExperiencesHubGroups/airport-transfer-pass", data, {
        baseURL: config[config.env].APIXHub,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  },
  addLines: async (data) => {
    //const response = Lines;
    const response = await axios
      .put("/ExperiencesHubGroups/add-lines", data, {
        baseURL: config[config.env].APIXHub,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  },
  validateReservation: async (data) => {
    // let response = "";
    // if (data.hasOwnProperty("lastname")) {
    //   response = ValidateReservationLastname;
    // } else {
    //   response = ValidateReservation;
    // }
    // await new Promise((resolve) => setTimeout(resolve, 500));
    const response = await axios
      .get("/ExperiencesHubGroups/validate-reservation", {
        baseURL: config[config.env].APIXHub,
        params: data,
        crossdomain: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK",
          "Access-Control-Allow-Origin": "https://nexusgov3.nexustours.net",
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  },
};
export default ApiXHub;
