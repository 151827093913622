import React from 'react'
// import {Link} from "react-router-dom";
import {Box, Grid, makeStyles, Card} from '@material-ui/core'
import Language from '../../libraries/Language';
import trans from "../../../data/translation.json";
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Helpers from '../../libraries/helpers';
// const userStyles = makeStyles(theme => ({
// 	cardItem:{
// 		width: '90%',
// 		margin: '0 auto',
// 		padding: '0 10px'
// 	},
// 	marginCard:{
// 		marginBottom: '20px'
// 	},
// 	titleBox: {
// 		display: 'flex',
// 		alignItems: 'center',
// 		justifyContent: 'center',
// 		marginTop: '8px',
// 		marginBottom: '8px',
// 	},
// 	titleImg:{
// 		display: 'inline-block',
// 		width: '25px',
// 	},
// 	titleItem:{
// 		margin: 0,
// 		color: '#004A63',
// 		textAlign: 'center',
// 		fontSize: '20px',
// 		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
// 	},
// 	labelItem: {
// 		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
// 		fontSize: '16px',
// 		fontWeight: 'bold',
// 		color: '#7A7A78',
// 		padding: '5px'
// 	},
// 	inputItem:{
// 		backgroundColor: '#F4F4F4',
// 		margin: '5px',
// 		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
// 		fontSize: '12px',
// 		color: '#575756',
// 		padding: '7px',
// 		borderRadius: '4px',
// 		textAlign: 'center',
// 		minHeight: '14px',
// 		// overflowX: 'scroll'
// 	},
// 	btnInfo: {
// 		color: '#fff',
//         border: '1px solid #ff7415',
//         margin: 'auto',
//         minWidth: '90%',
//         borderRadius: '6px',
//         backgroundColor: '#ff7415',
//         appearance: 'auto',
//         textRendering: 'auto',
//         letterSpacing: 'normal',
//         wordSpacing: 'normal',
//         textTransform: 'none',
//         textIndent: '0px',
//         textShadow: 'none',
//         display: 'inline-block',
//         textAlign: 'center',
//         alignItems: 'flex-start',
//         cursor: 'default',
//         boxSizing: 'border-box',
//         font: '400 13.333px Roboto',
//         fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//         padding: '4px 6px',
//         textDecoration: 'none',
// 	},
// }))

const MyExcursion = (props) => {
	const useStyles=makeStyles(props.styles)
	const classes = useStyles(props);
	const helpers = new Helpers();
	const lang = new Language();

  const {excursion, user,links,styles,trip,openChatLink} = props;
	let locale = lang.getLang();
//console.log(trip.fields.iconExc.fields.file.url)
	const getMonthText = (num, iso = 'en-CA') => {
    return trans[iso].months[num];
	}

  if(excursion !== null && user !== null)
	{
		let iso = locale;
		if(iso.indexOf('-') > 0)
		{
			iso = iso.substring(0, iso.indexOf('-'));
		}
		// var meetingDate = new Date(excursion.meeting_date + '.000Z');
		// console.log(excursion.meeting_date);
		var meetingDate = new Date(excursion.meeting_date); // + '.000Z'
		// console.log(meetingDate);
        return (
            <Grid item xs={12} sm={6} md={6} className={classes.marginCardEx}>
					<Card className={classes.cardItemEx}>
						<Grid container>
							<Grid item xs={12}>
								<Box className={classes.titleBoxEx}>
									<img className={classes.titleImgEx} src={trip.fields.iconExc.fields.file.url}  alt={links.translations.trip_lb_exc_title}/>
									<h4 className={classes.titleItemEx}>{links.translations.trip_lb_exc_title}</h4>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box mb={1} style={{ textAlign: 'center' }}>
									<a href={"https://www.nexustours.com/services/results.aspx?accion=searchservices&destinationID="+user.booking.zone_id+"&startDate="+user.booking.tw_ini.substring(0, user.booking.tw_ini.indexOf("T"))+"&endDate="+user.booking.tw_end.substring(0, user.booking.tw_end.indexOf("T"))+"&paxs=10&culture="+iso+"&idioma="+iso} 
									target="_blank"
									rel="noopener noreferrer">
										<button onClick={openChatLink} className={classes.btnInfoEx}>{links.translations.trip_btn_excursion}</button>
									</a>
								</Box>
							</Grid>
							<Grid item xs={6}>
									<Box mb={1} className={classes.labelItemEx}>{links.translations.trip_lb_code}</Box>
							</Grid>
							<Grid item xs={6}>
									<Box mb={1}><div className={classes.inputItemEx}>{(excursion.agency_ref ? excursion.agency_ref : excursion.locator)}</div></Box>
							</Grid>
							<Grid item xs={5}>
								<Box mb={1} className={classes.labelItemEx}>{links.translations.trip_lb_exc_name}</Box>
							</Grid>
							<Grid item xs={7}>
								<Box mb={1}><div className={classes.inputItemEx}>{excursion.product_name}</div></Box>
							</Grid>
							<Grid item xs={5}>
								<Box mb={1} className={classes.labelItemEx}>{links.translations.trip_lb_exc_date}</Box>
							</Grid>
							<Grid item xs={7}>
								<Box mb={1}><div className={classes.inputItemEx}>{meetingDate.getUTCDate() + "/" + getMonthText(meetingDate.getUTCMonth(), locale) + "/" + meetingDate.getUTCFullYear()}</div></Box>
							</Grid>
							<Grid item xs={5}>
								<Box mb={1} className={classes.labelItemEx}>{links.translations.trip_trf_passengers}</Box>
							</Grid>
							<Grid item xs={7}>
								{/* <Box mb={1}><div className={classes.inputItem}>{excursion.adults + ' ' + trans[locale].trip_trf_adults + ', ' + excursion.children + ' ' + trans[locale].trip_trf_children}</div></Box> */}
								<Box mb={1}><div className={classes.inputItemEx}>{(excursion.adults + excursion.children + excursion.babies)}</div></Box>
							</Grid>
							<Grid item xs={5}>
								<Box mb={1} className={classes.labelItemEx}>
									{trans[locale].trip_lb_exc_pickup}
									{(excursion.meeting_time ? '' : 
										<Tooltip disableFocusListener placement="right-start" title={trans[locale].trip_tp_not_trf}>
                      <InfoIcon style={{ fontSize: 15 }} />
                    </Tooltip>)}
								</Box>
							</Grid>
							<Grid item xs={7}>
								<Box mb={1}><div className={classes.inputItemEx}>{(excursion.meeting_time ? helpers.formatAMPM(excursion.meeting_time) : '--:--')}</div></Box>
							</Grid>
						</Grid>
					</Card>
				</Grid>
        )
    }
    return <div></div>
}

export default MyExcursion;