import React, { useState } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import RadioButtonTransfer from '../common/RadioButtonTransfer';
import RadioButtonNonTransfer from '../common/RadioButtonNonTransfer';
import Loader from '../../partials/Loader';

export default function SelectArrival(props) {
	const { agency, settings } = props;
	const [searchName, setSearchName] = useState("");
	const userStyles = makeStyles(agency)
	const classes = userStyles(props)
	//console.log(settings)
	if (settings === null) {
		return (<div></div>)
	}
	else {

		return (
			<div className="container-checkin container-arrival-form">
				{props.status && <Loader styles={agency} links={settings}
				/>}
				<ReactNotifications />
				<p className="booking">{settings.translations.form_group_booking_number } { (props.booking !== null ? (props.booking.agency_ref ? props.booking.agency_ref : props.booking.locator) : "") }</p>
				<h1 className={classes.titleDepature}>{settings.translations.form_group_title_arrival}</h1>
				<p className="description">{settings.translations.form_group_description_arrival}</p>
				<div className="container-search">
					<TextField
						id="search-by-name"
						label={settings.translations.form_group_inp_search}
						onChange={(event) => {
							setSearchName(event.target.value)
						}}
					/>
				</div>
				<div className={classes.containerBookingLines}>
					{props.booking && props.booking.lines.filter((item) => {
						let names = item.paxes.map((pax) => { return pax.name + " " + pax.lastname }).join("|");
						if (searchName === "") {
							return item
						} else if (names.toLowerCase().includes(searchName.toLowerCase())) {
							return item
						}
					}).map((item, i)=> (item.direction === 'IN' ? 
					<RadioButtonTransfer 
						key={i} 
						id={i} 
						locator={props.booking.locator}
						date={item.meeting_shortdate}
						item={item} 
						handleChange={props.handleChange('arrival', item)}
						value={props.values.arrival}
						search={searchName}
						links={settings}
						agency={agency}
						
						/> : ''))}
				<RadioButtonNonTransfer
						label={settings.translations.form_group_message_not_trf}
						handleChange={props.handleChange('arrival', 'non')}
						value={props.values.arrival}
						links={settings}
						agency={agency}
					/> 

				</div>
				<div className="wizard-control">
					<Button style={{
						color: settings.primary_color,
					}} size="large" onClick={props.nextStep} classes={{ root: "next" }}>{settings.translations.form_group_btn_next}</Button>
				</div>
			</div>
		);
	}
}