import React, { useState, useEffect} from 'react';
import {Box, Grid, makeStyles, Accordion, AccordionSummary, AccordionDetails, Typography, Button, IconButton} from '@material-ui/core';
import {Link} from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIosRounded';
import '../../../css/boarding-pass.css';

// const userStyles = makeStyles(theme => ({
// 	headingBi: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightBold,
// 		color: '#565654',
//   },
// 	imgIconBi: {
// 		width: '20px',
// 		height: '20px',
// 		marginRight: '8px',
// 	},
// 	btnConfirmInfoBi:{
// 		color: '#fff',
// 		border: '1px solid #ff7415',
// 		margin: '0 0 0 auto',
// 		minWidth: '50%',
// 		borderRadius: '6px',
// 		backgroundColor: '#ff7415',
// 		appearance: 'auto',
// 		textTransform: 'none',
// 		cursor: 'default',
// 		boxSizing: 'border-box',
// 		font: '400 13.333px Roboto',
// 		padding: '4px 6px',
// 		"&:hover, &:focus, &:active": {
// 			color: '#fff',
// 			backgroundColor: '#ff7415',
// 		}
// 	},
// 	accordionDetailsBi:{
// 		padding: 0,
//     flexDirection: 'column',
//     backgroundColor: '#EAEAEA',
// 	},
// 	itemListBi:{
// 		padding: '10px 16px',
//     borderTop: '1px solid #D3D3D3',
//     borderBottom: '1px solid #D3D3D3',
// 		justifyContent: 'start',
//     fontWeight: '400',
//     textTransform: 'capitalize',
//     fontSize: '16px',
//     borderRadius: '0px'
// 	},
// 	buttonBi: {
// 		position: 'fixed',
// 		top: '80px',
// 		left: '4px',
// 		zIndex: 100,
// 		backgroundColor: 'rgba(243,243,243,0.8)',
// 		boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
// 	},
// 	btnIconBi:{
// 		color: '#575756'
// 	},
// }))

const BookingInfo = (props) => {
	const useStyles=makeStyles(props.styles)
	const classes = useStyles(props);
	const [activateBrochure, setActivateBrochure] = useState(null);
	const [activatePassport, setActivatePassport] = useState(null);
	const haveTRF = (reservations) => {
		// console.log("Have TRF");
		//  console.log(props.config.digital_brochure);
		let bookings = reservations.filter(reservation => reservation.have_trf);
		// console.log(props.links.brochure);
		return (bookings.length > 0 ? true : false);
		
	}
	const haveEXC = (reservations) => {
		let bookings = reservations.filter(reservation => reservation.have_exc);
		return (bookings.length > 0 ? true : false);
	}
	const visibilityState = ()=>
	{
       if(props.links.brochure===false&&props.links.text==='Nexus Tours')
	   {
		// console.log('1')
          setActivateBrochure('none')
		  setActivatePassport('block')
	   }
	   else if(props.links.brochure===true&&props.config.digital_brochure===null&&props.config.digital_passport!==null&&props.links.text==='Nexus Tours')
	   {
		// console.log('2')
          setActivateBrochure('none')
		  setActivatePassport('block')
	   }
	   else if(props.links.brochure===true&&props.config.digital_brochure===null&&props.config.digital_passport!==null&&props.links.text!=='Nexus Tours')
	   {
		// console.log('3')
          setActivateBrochure('none')
		  setActivatePassport('none')
	   }
	   else if(props.links.brochure===true&&props.config.digital_brochure===null&&props.config.digital_passport!==null&&props.links.text==='Nexus Tours')
	   {
		// console.log('4')
          setActivateBrochure('none')
		  setActivatePassport('block')
	   }
	   else if(props.links.brochure===true&&props.config.digital_brochure===null&&props.config.digital_passport===null&&props.links.text==='Nexus Tours')
	   {
		// console.log('5')
		setActivateBrochure('none')
		setActivatePassport('none')
	   }
	   else if(props.links.brochure===true&&props.config.digital_brochure!==null&&props.config.digital_passport===null)
	   {
		// console.log('6')
		setActivateBrochure('block')
		setActivatePassport('none')
	   }
	   else if(props.links.brochure===true&&props.config.digital_brochure!==null&&props.config.digital_passport!==null&&props.links.text!=='Nexus Tours')
	   {
		// console.log('7')
		setActivateBrochure('block')
		setActivatePassport('none')
	   }
	   else
	   {
		// console.log('8')
		setActivateBrochure('none')
		setActivatePassport('none')
	   }
	}
	useEffect(() => {
		visibilityState();
	
	}, [])
	if(props.wallet !== null && props.config !== null && props.bookingInfo !== null)
	{
		const {config, bookingInfo, wallet, trans, handleClickBoarding, showDigitalPassport,showDigitalBrochure,links} = props;
		document.title = wallet.fields.title;
		//console.log(wallet.fields)
		return (
			<div>
				<Link to="/">
					<IconButton aria-label="Back" className={classes.buttonBi+" back-to-home"}>
						<ArrowBackIosIcon fontSize="inherit" className={classes.btnIconBi} />
					</IconButton>
				</Link>
				<Grid item xs={12}>
					<Box mb={2}>
						<img src={wallet.fields.banner.fields.file.url} alt={wallet.fields.banner.fields.title} style={{width: '100%', height: 'auto'}}/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box m={2}>
						{
							haveTRF(bookingInfo.bookings) &&
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<img src={wallet.fields.iconTrf.fields.file.url} className={classes.imgIconBi} alt={links.translations.nw_header_boarding_pass}/>
									<Typography className={classes.headingBi}>{links.translations.nw_header_boarding_pass}</Typography>
								</AccordionSummary>
								<AccordionDetails className={classes.accordionDetailsBi}>
									{bookingInfo.bookings.map((booking) => (
										booking.lines !== null && booking.lines.length > 0 ? 
											booking.lines.map((line, i) => (line !== null && line.service_type === "TRF" && (line.direction !== "" && (line.direction === 'IN' || line.direction === 'OUT') && line.direction !== null && line.direction.length > 0) ?
											<Button key={i} onClick={handleClickBoarding(line)} className={classes.itemListBi}>
												{(line.direction === 'IN' ? links.translations.nw_listitem_arr : (line.direction === 'OUT' ? links.translations.nw_listitem_dep : ''))}
												{(!line.have_atp ? <span className={classes.btnConfirmInfoBi}>{links.translations.nw_listitem_btn}</span> : '')}
											</Button>
											: ''))
										: '')
									)}
								</AccordionDetails>
							</Accordion>
						}
						{
							haveEXC(bookingInfo.bookings) &&
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel2a-content"
									id="panel2a-header"
								>
									<img src={wallet.fields.iconExc.fields.file.url} className={classes.imgIconBi} alt={links.translations.nw_header_vouchers}/>
									<Typography className={classes.headingBi}>{links.translations.nw_header_vouchers}</Typography>
								</AccordionSummary>
								<AccordionDetails className={classes.accordionDetailsBi}>
									{bookingInfo.bookings.map((booking) => (
										booking.lines !== null && booking.lines.length > 0 ? 
											booking.lines.map((line, i) => (line !== null && line.service_type === "EXC" ?
											<Button key={i} onClick={handleClickBoarding(line)} className={classes.itemListBi}>
												{line.product_name}
											</Button> 
											: ''))
										: '')
									)}
								</AccordionDetails>
							</Accordion>
						}
						<Accordion style={{display: activatePassport}} onChange={showDigitalPassport(config.digital_passport)} expanded={false}>
							<AccordionSummary
								aria-controls="panel3a-content"
								id="panel3a-header"
							>
								<img src={wallet.fields.iconWallet.fields.file.url} className={classes.imgIconBi} alt={links.translations.nw_header_digital_pass}/>
								<Typography className={classes.headingBi}>{links.translations.nw_header_digital_pass}</Typography>
							</AccordionSummary>
						</Accordion>
						<Accordion style={{display: activateBrochure}} onChange={showDigitalBrochure(config.digital_brochure)} expanded={false}>
							<AccordionSummary
								aria-controls="panel3a-content"
								id="panel3a-header"
							>
								<img src={wallet.fields.iconWallet.fields.file.url} className={classes.imgIconBi} alt={links.translations.nw_header_digital_brochure}/>
								<Typography className={classes.headingBi}>{links.translations.nw_header_digital_brochure}</Typography>
							</AccordionSummary>
						</Accordion>
					</Box>
				</Grid>
			</div>
		);
	}
	return <div></div>
}

export default BookingInfo;