import React from 'react'
import {withRouter} from "react-router-dom"
import {Box, Grid} from '@material-ui/core'
import Chat from '../common/Chat';
import Transfer from '../my-trip/MyTransfer';
import TransferNotFound from '../my-trip/TransferNotFound';
import CarouselD from '../destination/DestinationCarousel';
import Smooch from 'smooch';
import ReactGA from 'react-ga';

// const Smooch = require('smooch');

const UpgradeTransfer = (props) => {
	const {trip, trips,userInfo,links,styles,openChatLink} = props;

	const openMsgLink = () => {
		ReactGA.event({
			category: 'Upgrade Transfer',
			action: 'Click Upgrade Transfer',
			label: 'Button Upgrade Transfer'
		});

		Smooch.open();
		
	}

	if(trip !== null && userInfo !== null)
	{
		document.title = trip.fields.title;
		return (
			<Grid container>
				<Grid item xs={12}>
					<Box mb={2}>
						<CarouselD images={trip.fields.carousel} />
					</Box>
				</Grid>
				{userInfo.bookings.map((booking) => (
					booking.lines !== null && booking.lines.length >  0 && booking.lines_trf_out > 0 ? 
						booking.lines.map((line, i) => (line !== null && line.service_type === "TRF" && line.direction === 'OUT' ?
							<Transfer key={i} transfer={line} trip={trips} openChatLink={openChatLink} links={links} styles={styles}/>
						: ''))
					: <TransferNotFound links={links} trip={trips} styles={styles} openChatLink={openChatLink}/>)
				)}
			</Grid>
		)
	}
	else
	{

     return(<TransferNotFound links={links} trip={trips} styles={styles} openChat={openMsgLink}/>)
	}
	
	
}

export default withRouter(UpgradeTransfer);