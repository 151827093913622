import React from 'react';
import {Box, Grid, makeStyles, Button, IconButton} from '@material-ui/core';
import {Link} from "react-router-dom";
import Helpers from '../../libraries/helpers';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIosRounded';
// const userStyles = makeStyles(theme => ({
// 	imgQRBp:{
// 		width: '150px',
// 		height: '150px',
// 		margin: 'auto',
// 		border: '1px solid #ff7415'
// 	},
// 	bookingCodeBp:{
// 		color: '#ff7415',
// 		fontSize: '14px',
// 		fontWeight: 'bold',
// 	},
// 	tagLabelBp:{
// 		color: '#575756',
// 		fontSize: '14px',
// 	},
// 	tagInputBp:{
// 		color: '#505050',
// 		fontSize: '14px',
// 		fontWeight: 'bold',
// 	},
// 	boxInfoNotCorrectBp:{
// 		textAlign: 'center'
// 	},
// 	btnConfirmInfoBp:{
// 		color: '#fff',
// 		border: '1px solid #ff7415',
// 		margin: '0 auto',
// 		minWidth: '50%',
// 		borderRadius: '6px',
// 		backgroundColor: '#ff7415',
// 		appearance: 'auto',
// 		textRendering: 'auto',
// 		letterSpacing: 'normal',
// 		wordSpacing: 'normal',
// 		textTransform: 'none',
// 		textIndent: '0px',
// 		textShadow: 'none',
// 		display: 'inline-block',
// 		textAlign: 'center',
// 		alignItems: 'flex-start',
// 		cursor: 'default',
// 		boxSizing: 'border-box',
// 		font: '400 13.333px Roboto',
// 		padding: '4px 6px',
// 		textDecoration: 'none',
// 		"&:hover, &:focus, &:active": {
// 			color: '#fff',
// 			backgroundColor: '#ff7415',
// 		}
// 	},
// 	infoNotCorrectBp:{
// 		padding: '0px 22px',
//     color: '#7A7A78',
//     fontSize: '16px',
//     fontFamily: 'Roboto',
//     fontWeight: 400
// 	},
// 	marginBottomBp: {
// 		marginBottom: '20px',
// 		marginTop: '-52px',
// 	},
// 	backLinkBp:{
// 		position: 'relative',
//     top: '74px',
//     backgroundColor: 'rgba(255,255,255, 0.5)'
// 	},
// }))

const BoardingPass = (props) => {
	const useStyles=makeStyles(props.styles)
	const classes = useStyles(props);
	const {trip, bookingInfo, booking, trans,links} = props;
	const helpers = new Helpers();

	const getMonthText = (num) => {
		return trans.months[num];
	}

	if(trip !== null && bookingInfo !== null && booking !== null)
	{
		document.title = trip.fields.titleBoardingPass;
		var meetingDateTime = helpers.correctDate(booking.meeting_date, booking.meeting_time);
		// console.log(meetingDateTime.getDate(), meetingDateTime.getUTCDate());
		// console.log(booking);
		return (
			<Grid container className={classes.marginBottomBp}>
				<IconButton 
					onClick={() => props.handleOpenCheckin()}
					className={classes.backLinkBp}
					aria-label="Back">
					<ArrowBackIosIcon fontSize="inherit" />
				</IconButton>
				<Grid item xs={12}>
					<Box mb={2}>
						<img src={trip.fields.bannerBoardingPass.fields.file.url} alt={trip.fields.bannerBoardingPass.fields.title} style={{width: '100%', height: 'auto'}}/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box mb={2} className={classes.boxInfoNotCorrectBp}>
						<img src={"https://api.qrserver.com/v1/create-qr-code/?size=130x130&data="+booking.locator+";"+booking.line_id} alt="QR" className={classes.imgQRBp}/>
						<p className={classes.bookingCodeBp}>{(booking.agency_ref ? booking.agency_ref : booking.locator)}</p>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box mb={0} className={classes.boxInfoNotCorrectBp}>
						<p className={classes.tagLabelBp}>{links.translations.mv_lb_customer_name}</p>
						<p className={classes.tagInputBp}>{bookingInfo.client.name + ' ' + bookingInfo.client.lastname}</p>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<hr/>
				</Grid>
				<Grid item xs={12}>
					<Box mb={0} className={classes.boxInfoNotCorrectBp}>
						<p className={classes.tagLabelBp}>{links.translations.bp_lb_from}</p>
						<p className={classes.tagInputBp}>{booking.from}</p>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box mb={0} className={classes.boxInfoNotCorrectBp}>
						<p className={classes.tagLabelBp}>{links.translations.bp_lb_to}</p>
						<p className={classes.tagInputBp}>{ booking.to }</p>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<hr/>
				</Grid>
				<Grid item xs={4}>
					<Box mb={0} className={classes.boxInfoNotCorrectBp}>
						<p className={classes.tagLabelBp}>{links.translations.bp_lb_flight_no}</p>
						<p className={classes.tagInputBp}>{booking.flight_number}</p>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box mb={0} className={classes.boxInfoNotCorrectBp}>
						<p className={classes.tagLabelBp}>{(booking.direction === 'IN' ? links.translations.bp_lb_flight_time_arr : links.translations.bp_lb_flight_time_dep)}</p>
						<p className={classes.tagInputBp}>{booking.flight_time ? helpers.formatAMPM(booking.flight_time) : '--:--'}</p>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box mb={0} className={classes.boxInfoNotCorrectBp}>
						<p className={classes.tagLabelBp}>{links.translations.bp_lb_total_pax}</p>
						<p className={classes.tagInputBp}>{(booking.adults + booking.children + booking.babies)}</p>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<hr/>
				</Grid>
				
				<Grid item xs={(booking.direction === "OUT" ? 4 : 6)}>
					<Box mb={2} className={classes.boxInfoNotCorrectBp}>
						<p className={classes.tagLabelBp}>{links.translations.bp_lb_trf_type}</p>
						<p className={classes.tagInputBp}>{booking.trf_type}</p>
					</Box>
				</Grid>
				{ booking.direction === "OUT" &&
					<Grid item xs={4}>
						<Box mb={2} className={classes.boxInfoNotCorrectBp}>
							<p className={classes.tagLabelBp}>{links.translations.mv_lb_pickup_time}</p>
							<p className={classes.tagInputBp}>{booking.meeting_time}</p>
						</Box>
					</Grid>
				}
				<Grid item xs={(booking.direction === "OUT" ? 4 : 6)}>
					<Box mb={2} className={classes.boxInfoNotCorrectBp}>
						<p className={classes.tagLabelBp}>{links.translations.bp_lb_trf_date}</p>
						<p className={classes.tagInputBp}>{booking.meeting_date && (meetingDateTime.getDate() + "/" + getMonthText(meetingDateTime.getUTCMonth()) + "/" + meetingDateTime.getUTCFullYear())}</p>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box mb={2} mt={2} className={classes.boxInfoNotCorrectBp}>
						<Button size="small" 
							onClick={props.handleDownload(booking)}
							className={classes.btnConfirmInfoBp}
						>{links.translations.mv_btn_download}</Button>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box mb={2} mt={2} className={classes.boxInfoNotCorrectBp}>
						<Link to="/" className={classes.btnConfirmInfoBp}>{links.translations.mv_btn_continue}</Link>
					</Box>
				</Grid>
			</Grid>
		)
	}
	return <div></div>
	
}

export default BoardingPass;