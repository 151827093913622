import React, {useState} from 'react';
import { ReactNotifications } from 'react-notifications-component';
import {withStyles, Tooltip,makeStyles, ClickAwayListener, CircularProgress, Button, Menu, MenuItem} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import userImg from '../../../images/01-login-icon-user-70x70.png';
import Chat from '../common/Chat';
import { ArrowDropDown } from '@material-ui/icons';
const LightTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: 'rgba(255, 255, 255, 0.8)',
	  color: 'rgba(0, 0, 0, 0.87)',
	  fontSize: 11,
	},
}))(Tooltip);

export default function Register(props) {
	const {trans, form, loader,agency,loginSettings, locale, openLang,openReg, showLastname, registerDisabled} = props;
	const [open, setOpen] = useState(false);
	const useStyles=makeStyles(agency)
	const classes=useStyles(props)
	if(agency !== null&&openReg===true)
	{
	return (
		<div className={classes.containerLogin}>
			<ReactNotifications />
			<div className={classes.imgleft}>
			<img src={loginSettings.company_label.fields.file.url}  ></img>
			</div>
			<div className={classes.imgright}>
			<img src={loginSettings.xhub_label.fields.file.url} ></img>
			</div>
			<div >
			      <div>
				<img  src={userImg} alt="User" className={classes.imgLogin} />
				</div>
				<label htmlFor="agencyref" className={classes.labellogin}>
					{loginSettings.translations.form_login_booking} 
					<ClickAwayListener onClickAway={() => setOpen(false)}>
						<LightTooltip 
							PopperProps={{
								disablePortal: true,
							}}
							onClose={() => setOpen(false)}
							open={open}
							disableFocusListener
							disableHoverListener
							disableTouchListener
							placement="bottom"
							agency=	{agency}
							title={loginSettings.translations.form_login_booking_tooltip}>
						
							<InfoIcon style={{  fontSize: '22px', color: '#fff', marginLeft: '5px', marginBottom: '-5px'}} onClick={() => setOpen(true)}/>
						</LightTooltip>
					</ClickAwayListener>
				</label>
				<input className={classes.inputlogin} type="text" name="agencyref" onChange={props.handleChangeAgencyRef} value={form.agencyref}/>
				{ showLastname === true &&
					<label htmlFor="lastname" className={classes.labellogin}>
						{loginSettings.translations.form_login_lastname}
					</label>
				}
				{ showLastname === true &&
					<input 
						className={classes.inputlogin}
						type="text"
						name="lastname"
						onChange={props.handleChangeAgencyRef}
						value={form.lastname}
					/>
				}
				{loader === true ? <CircularProgress
						style={{
							color: loginSettings.primary_color,
							textAlign:'center',
							marginLeft: '140px'
						  }}
				/> : 
					<Button className={classes.btn}
						disabled={registerDisabled}
						onClick={() => props.register()}>{ loginSettings.translations.form_login_btn_register }</Button>
				}
				{loader === false && 
					<Button aria-controls="simple-menu" aria-haspopup="true" onClick={props.handleOpenLang} className={classes.btnTrnsp}>
						{locale === 'es' ? 'Español' : (locale === 'fr' ? 'Français' : (locale === 'pt-BR' ? 'Português' : 'English'))}
						<ArrowDropDown fontSize='small'></ArrowDropDown>
					</Button>
				}
				{loader === false &&
					<Menu
						id="simple-menu"
						anchorEl={openLang}
						keepMounted
						open={Boolean(openLang)}
						onClose={props.handleCloseLang}
					>
						<MenuItem onClick={() => props.handleChangeLocale('en-CA')}>English</MenuItem>
						<MenuItem onClick={() => props.handleChangeLocale('es')}>Español</MenuItem>
						<MenuItem onClick={() => props.handleChangeLocale('fr')}>Français</MenuItem>
						<MenuItem onClick={() => props.handleChangeLocale('pt-BR')}>Português</MenuItem>
					</Menu>
				}
				{loader === false && <button 
					className={classes.btnClkHre}
					type="button"
					onClick={() => props.handleLogin("Login")}>{[loginSettings.translations.form_login_already_registered_1, <u>{loginSettings.translations.form_login_already_registered_2}</u>, loginSettings.translations.form_login_already_registered_3]}</button>
				}
			</div>
		</div>
	);
    }
	else
	{
		return <div></div>;
	}
}