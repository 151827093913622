import React, { Component } from 'react'

export default class SocialMedia extends Component {
    render() {
        const {image} = this.props;
		if(null !== image)
		{
            return (
                <a href={image.fields.link} className={'icon-social'} target="_blank" rel="noopener noreferrer">
                    <img alt={image.fields.name} src={image.fields.image.fields.file.url} width={'30px'}/>
                </a>
            )
        }
        return <span></span>
    }
}
