import React from 'react'
import {Box, Grid, makeStyles, Button, IconButton} from '@material-ui/core'
import {Link} from "react-router-dom";
import Helpers from '../../libraries/helpers';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIosRounded';
// const Smooch = require('smooch');



const MyVoucher = (props) => {
	const useStyles=makeStyles(props.styles)
	const classes = useStyles(props);
	const {trip, bookingInfo, booking, trans,links} = props;
	const helpers = new Helpers();

	const getMonthText = (num) => {
		return trans.months[num];
	}

	if(trip !== null && bookingInfo !== null && booking !== null)
	{
		//console.log(bookingInfo)
		document.title = trip.fields.titleMyVoucher;
		var meetingDateTime = helpers.correctDate(booking.meeting_date, booking.meeting_time);
		// console.log(meetingDateTime.getDate(), meetingDateTime.getUTCDate());
		// console.log(trip);
		return (
			<Grid container className={classes.marginBottomMb}>
				<IconButton 
					onClick={() => props.handleOpenCheckin()}
					className={classes.backLinkMb}
					aria-label="Back">
					<ArrowBackIosIcon fontSize="inherit" />
				</IconButton>
				<Grid item xs={12}>
					<Box mb={2}>
						<img src={trip.fields.bannerMyVoucher.fields.file.url} alt={trip.fields.bannerMyVoucher.fields.title} style={{width: '100%', height: 'auto'}}/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box mb={2} className={classes.boxInfoNotCorrectMb}>
						<img src={"https://api.qrserver.com/v1/create-qr-code/?size=130x130&data="+booking.locator+";"+booking.line_id} alt="QR" className={classes.imgQR}/>
						<p className={classes.bookingCodeMb}>{(booking.agency_ref ? booking.agency_ref : booking.locator)}</p>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box mb={0} className={classes.boxInfoNotCorrectMb}>
						<p className={classes.tagInputOrangeMb}>{booking.product_name}</p>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<hr/>
				</Grid>
				<Grid item xs={12}>
					<Box mb={0} className={classes.boxInfoNotCorrectMb}>
						<p className={classes.tagLabelMb}>{links.translations.mv_lb_customer_name}</p>
						<p className={classes.tagInputMb}>{bookingInfo.client.name + ' ' + bookingInfo.client.lastname}</p>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<hr/>
				</Grid>
				<Grid item xs={4}>
					<Box mb={0} className={classes.boxInfoNotCorrectMb}>
						<p className={classes.tagLabelMb}>{links.translations.mv_lb_exc_date}</p>
						<p className={classes.tagInputMb}>{booking.meeting_date && (meetingDateTime.getDate() + "/" + getMonthText(meetingDateTime.getUTCMonth()) + "/" + meetingDateTime.getUTCFullYear())}</p>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box mb={0} className={classes.boxInfoNotCorrectMb}>
						<p className={classes.tagLabelMb}>{links.translations.mv_lb_start_time}</p>
						<p className={classes.tagInputMb}>{booking.start_time}</p>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box mb={0} className={classes.boxInfoNotCorrectMb}>
						<p className={classes.tagLabelMb}>{links.translations.mv_lb_total_pax}</p>
						<p className={classes.tagInputMb}>{(booking.adults + booking.children + booking.babies)}</p>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<hr/>
				</Grid>
				<Grid item xs={12}>
					<Box mb={0} className={classes.boxInfoNotCorrectMb}>
						<p className={classes.tagLabelMb}>{links.translations.mv_lb_pickup_time}</p>
						<p className={classes.tagInputMb}>{(booking.meeting_time ? booking.meeting_time : "")} - {(booking.meeting_point ? booking.meeting_point : "")}</p>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box mt={2} className={classes.boxInfoNotCorrectMb}>
						<p className={classes.tagLabelMb}>{links.translations.mv_lb_notified}</p>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box mb={2} mt={2} className={classes.boxInfoNotCorrectMb}>
						<Button size="small" 
							onClick={props.handleDownload(booking)}
							className={classes.btnConfirmInfoMb}
						>{links.translations.mv_btn_download}</Button>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box mb={2} mt={2} className={classes.boxInfoNotCorrectMb}>
						<Link to="/" className={classes.btnConfirmInfoMb}>{links.translations.mv_btn_continue}</Link>
					</Box>
				</Grid>
			</Grid>
		)
	}
	return <div></div>
}

export default MyVoucher;