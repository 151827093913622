import React, { Component } from 'react'
import {withRouter} from "react-router-dom"
import {Grid, Card, Box,withStyles} from '@material-ui/core'
import WidgetTripAdvisor from './WidgetTripAdvisor'
import "../../../css/share-my-experience.css"
import Markdown from 'react-markdown'
import Chat from '../common/Chat'
import {usingStyles} from '../../../services/themeOptions'
const ShareMyExp=(props)=>
{
    const {shareMyExperience, config, trans,styles,links}=props
    const classing=usingStyles(styles)
    return(
        <Grid container spacing={1} className="myexp-container-cards">
        <Grid item xs={12}>
            <Box mb={2} style={{marginTop: '16px'}}>
                <img src={shareMyExperience.fields.banner.fields.file.url} alt={shareMyExperience.fields.banner.fields.title} width={'100%'}/>
            </Box>
        </Grid>
        <Grid item xs={12} className="myexp-grid-item">
            <div className="myexp-card-item">
                <p className="myexp-title">{shareMyExperience.fields.paragraph}<br/><strong>{shareMyExperience.fields.paragraphBold}</strong></p>
            </div>
        </Grid>
        <Grid item xs={12}>
            <Card className="myexp-card-item" elevation={3}>
                <p>{shareMyExperience.fields.paragraphButton}</p>
            
                <a href={shareMyExperience.fields.linkButton} className={classing.myexpBtnSurvey} target="_blank" without rel="noopener noreferrer">{shareMyExperience.fields.textButton}</a>
               
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Card className="myexp-card-item" elevation={3}>
                <p>{shareMyExperience.fields.paragraphTripAdvisor}</p>
                <WidgetTripAdvisor config={config} trans={trans}  links={links}/>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <div className={classing.Thanku}>{shareMyExperience.fields.paragraphThank.split('\n').map((content, i) => { return <Markdown key={i} children={content} />})}</div>
        </Grid>
        <Grid item xs={12} style={{marginTop: '15px'}}>
        </Grid>
    </Grid>
    )

}

class ShareMyExperience extends Component {
	render() {
        const {shareMyExperience, config, trans,styles, links} = this.props;
		if(config !== null && shareMyExperience !== null)
		{
            document.title = shareMyExperience.fields.title;
            return (
                   <ShareMyExp
                   shareMyExperience={shareMyExperience}
                   config={config}
                   trans={trans}
                   styles={styles}
                   links={links}
                   
                   />
            )
        }
        return <div></div>
    }
}
export default (ShareMyExperience);
export {withRouter};