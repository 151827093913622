import React, { Component } from 'react';
import { withStyles, Box, Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import CircleIcon from '@material-ui/icons/Brightness1';
import LogoTA from '../../../images/07-share-logo-trip-advisor-121x30.png';
import {useStyles} from '../../../services/themeOptions.js'
const StyledRating = withStyles({
    iconFilled: {
      color: '#00aa6c',
    },
    iconHover: {
      color: '#00aa6c',
    },
})(Rating);

class WidgetTripAdvisor extends Component {
    constructor (props) {
		super(props)
		this.state = {
			value: 0,
            hover: -1,
            review: "",
		}
	}

    handleValue = async (val) => {
		await this.setState({
			...this.state,
			value: val
		})
	}

    handleHover = async (hov) => {
		await this.setState({
			...this.state,
			hover: hov
		})
	}

    handleTitle = async (e) => {
        // console.log(e.target.name + " => " + e.target.value)
		await this.setState({
			...this.state,
			[e.target.name]: e.target.value
		})
	}

    componentDidMount() {
    // componentWillMount () {
        // const script_1 = document.createElement("script");

        // script_1.id = 'test';
        // script_1.async = true;
        // script_1.src = "https://www.jscache.com/wejs?wtype=cdswritereviewlg&amp;uniq=472&amp;locationId=2020821&amp;lang=es_MX&amp;display_version=2";
        // script_1.onload = "this.loadtrk=true";
        // script_1.dataset.loadtrk = "";
        
        // document.body.appendChild(script_1);
    }

    onSubmit = () => {
        const { config, trans,links } = this.props;
        let url = trans.trip_advisor_url;
        url = url + "UserReview-g" + config.tripadvisor.widget_id;
        url = url + '-d' + config.tripadvisor.location_id;
        url = url + '-' + config.tripadvisor.link;
        url = url + '?m=12097';
        // url = url + 'UserReview-g150807-d2020821-NexusTours-Cancun_Yucatan_Peninsula.html';
        // url = url + '?m=12097';
        url = url + '&qid10=' + this.state.value;
        url = url + '&ReviewTitle=' + encodeURI(this.state.review);
        window.location = url;
        // console.log(url);
    }
  
    render() {
        const { config, trans,links } = this.props;
        const { value, hover } = this.state;
        return (
        <div>
            {/* <div id="TA_cdswritereviewlg472" class="TA_cdswritereviewlg"></div> */}
            <div id="container-ta">
                <div className="ta-logo">
                    <a target="_blank" href={trans.trip_advisor_url} rel="noopener noreferrer">
                        <img src={LogoTA} alt="Tripadvisor" />
                    </a>
                </div>
                <a 
                    target="_blank" 
                    href={links.translations.trip_advisor_url + 'Attraction_Review-g' + config.tripadvisor.widget_id + '-d' + config.tripadvisor.location_id + '-Reviews-' + config.tripadvisor.link }
                    rel="noopener noreferrer nofollow"
                    className="ta-link-op"
                >{links.translations.trip_advisor_title_review}</a>
                <div className="ta-rating">
                    <Box component="fieldset" borderColor="transparent">
                        <StyledRating
                            name="ta-rating"
                            value={value}
                            onChange={(e, newValue) => {
                                this.handleValue(newValue);
                            }}
                            onChangeActive={(e, newHover) => {
                                this.handleHover(newHover);
                            }}
                            icon={<CircleIcon style={{fontSize: '16px', border: '1px solid #00aa6c', borderRadius: '50%', margin: '0 2px'}} />}
                        />
                        <Typography component="span" className="ta-rating-label">{(value !== null ? trans.trip_advisor_widget_rating[hover !== -1 ? hover : value] : trans.trip_advisor_widget_rating[0])}</Typography>
                    </Box>
                </div>
                <div className="ta-form">
                    <textarea name="review" id="review" rows="3" cols="30" placeholder={links.translations.trip_advisor_texta_placeholder} onChange={this.handleTitle}></textarea>
                    <button onClick={() => this.onSubmit()}>{links.translations.trip_advisor_btn_continue}</button>
                </div>
            </div>
        </div>
      );
    }
  }
export default WidgetTripAdvisor;