// import React, { useEffect, useState } from 'react'
import React from 'react'
import {Box, Grid, makeStyles, Card} from '@material-ui/core'
import Loader from '../../partials/Loader';
// import Tooltip from '@material-ui/core/Tooltip';
// import InfoIcon from '@material-ui/icons/InfoOutlined';
import Helpers from '../../libraries/helpers';


// const baseURL = 'https://nexusgov3.nexustours.net/JuniperInformation/api/PickupTime';

// const userStyles = makeStyles(theme => ({
// 	cardItemMr:{
// 		width: '90%',
// 		margin: '0 auto',
// 		padding: '0 10px'
// 	},
// 	marginCardMr:{
// 		marginBottom: '20px'
// 	},
// 	titleBoxMr: {
// 		display: 'flex',
// 		alignItems: 'center',
// 		justifyContent: 'center',
// 		marginTop: '6px',
// 		marginBottom: '6px',
// 	},
// 	titleImgMr:{
// 		display: 'inline-block',
// 		width: '50px',
// 	},
// 	titleItemMr:{
// 		margin: 0,
// 		color: '#ff7415',
// 		textAlign: 'center',
// 		fontSize: '20px',
// 		fontFamily: 'Roboto',
// 	},
// 	labelItemMr: {
// 		fontFamily: 'Roboto',
// 		fontSize: '16px',
// 		fontWeight: 'bold',
// 		color: '#7A7A78',
// 		padding: '5px'
// 	},
//     labelHeaderMr: {
//         fontFamily: 'Roboto',
// 		fontSize: '16px',
// 		fontWeight: 'bold',
// 		color: '#ff7415',
// 		padding: '5px'
//     },
// 	inputItemMr:{
// 		backgroundColor: '#F4F4F4',
// 		margin: '5px',
// 		fontFamily: 'Roboto',
// 		fontSize: '12px',
// 		color: '#575756',
// 		padding: '7px',
// 		borderRadius: '4px',
// 		textAlign: 'center',
// 		minHeight: '14px',
// 		// overflowX: 'scroll'
// 	},
// 	btnInfoMr: {
// 		color: '#fff',
//         border: '1px solid #ff7415',
//         margin: 'auto',
//         minWidth: '50%',
//         borderRadius: '6px',
//         backgroundColor: '#ff7415',
//         appearance: 'auto',
//         textRendering: 'auto',
//         letterSpacing: 'normal',
//         wordSpacing: 'normal',
//         textTransform: 'none',
//         textIndent: '0px',
//         textShadow: 'none',
//         display: 'inline-block',
//         textAlign: 'center',
//         alignItems: 'flex-start',
//         cursor: 'default',
//         boxSizing: 'border-box',
//         font: '400 13.333px Roboto',
//         padding: '4px 6px',
// 	},
// }))

const MyRep = (props) => {
    const useStyles=makeStyles(props.styles)
	const classes = useStyles(props);
    const helpers = new Helpers();
    const {planification, trans,links,contact,styles,token} = props;
    const getMonthText = (num) => {
        return trans.months[num];
	}
    let faceBriefDateTime=null
    let virtualBriefDateTime=null
       //console.log(token)
        if(planification!==null)
        {
            // console.log(planification.FaceBriefing)
            // console.log(planification.VirtualBriefing)
            // console.log(planification.VirtualConciergePorAgenciaIdioma)
            // console.log(planification.RepresentantePorHotelIdioma)
            faceBriefDateTime = new Date(planification.FaceBriefing.Fecha + ' ' + planification.FaceBriefing.HoraBriefing) === null ? faceBriefDateTime.getUTCDate() + "/" + getMonthText(faceBriefDateTime.getUTCMonth()) + "/" + faceBriefDateTime.getUTCFullYear() : links.translations.cmr_lb_info_n_a        
            virtualBriefDateTime = new Date(planification.VirtualBriefing.Fecha + ' ' + planification.VirtualBriefing.HoraBriefing) ===null ? virtualBriefDateTime.getUTCDate() + "/" + getMonthText(virtualBriefDateTime.getUTCMonth() + "/" + virtualBriefDateTime.getUTCFullYear()) : links.translations.cmr_lb_info_n_a
            return (
                <Grid item xs={12} sm={12} md={12} className={classes.marginCardMr}>    
                <Card className={classes.cardItemMr}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box className={classes.titleBoxMr}>
                                <img className={classes.titleImgMr} src={contact.fields.imgMeet.fields.file.url} alt={links.translations.cmr_title_my_rep}/>
                                <h4 className={classes.titleItemMr}>{links.translations.cmr_title_my_rep}</h4>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mb={1} className={classes.labelHeaderMr}>{links.translations.cmr_lb_at_hotel}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_my_hotel}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{planification.RepresentantePorHotelIdioma.Nombre!==null? planification.RepresentantePorHotelIdioma.Nombre:links.translations.cmr_lb_info_n_a} </div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_my_phone}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{(planification.RepresentantePorHotelIdioma.Celular!==null ? <a target="_blank" rel="noopener noreferrer" href={ "tel:" + planification.RepresentantePorHotelIdioma.Celular }>{planification.RepresentantePorHotelIdioma.Celular}</a> :links.translations.cmr_lb_info_n_a)}</div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_welcome_date}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{(planification.FaceBriefing.Fecha!==null?planification.FaceBriefing.Fecha:links.translations.cmr_lb_info_n_a)} </div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_welcome_time}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{(planification.FaceBriefing.HoraBriefing!==null ? helpers.formatAMPM(planification.FaceBriefing.HoraBriefing) : links.translations.cmr_lb_info_n_a)}</div></Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_location}</Box>
                        </Grid>
                        <Grid item xs={9}>
                            <Box mb={1}><div className={classes.inputItemMr}>{ (planification.FaceBriefing.ubicacion!==null ? planification.FaceBriefing.ubicacion : links.translations.cmr_lb_info_n_a) }</div></Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mb={1} className={classes.labelHeaderMr}>{links.translations.cmr_lb_virtually}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_my_virtual}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{ (planification.VirtualConciergePorAgenciaIdioma.Nombre!==null ? planification.VirtualConciergePorAgenciaIdioma.Nombre : links.translations.cmr_lb_info_n_a) }</div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_virtual_date}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{ (planification.VirtualBriefing.Fecha!==null?planification.VirtualBriefing.Fecha:links.translations.cmr_lb_info_n_a) }</div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_virtual_time}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{ (planification.VirtualBriefing.HoraBriefing!==null ? helpers.formatAMPM(planification.VirtualBriefing.HoraBriefing) : links.translations.cmr_lb_info_n_a) }</div></Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_join_here}</Box>
                        </Grid>
                        <Grid item xs={9}>
                            <Box mb={1}><div className={classes.inputItemMr}>{(planification.VirtualBriefing.Link_Briefing!==null ? <a target="_blank" rel="noopener noreferrer" href={ planification.VirtualBriefing.Link_Briefing }>{ planification.VirtualBriefing.Link_Briefing }</a> : links.translations.cmr_lb_info_n_a)}</div></Box>
                        </Grid>
                    </Grid>
                </Card>
                   
            </Grid>
            )  
        }
        else
        {
            faceBriefDateTime=links.translations.cmr_lb_info_n_a  
            virtualBriefDateTime=links.translations.cmr_lb_info_n_a
            return (
                <Grid item xs={12} sm={12} md={12} className={classes.marginCardMr}>
                <Card className={classes.cardItemMr}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box className={classes.titleBoxMr}>
                                <img className={classes.titleImgMr} src={contact.fields.imgMeet.fields.file.url} alt={links.translations.cmr_title_my_rep}/>
                                <h4 className={classes.titleItemMr}>{links.translations.cmr_title_my_rep}</h4>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mb={1} className={classes.labelHeaderMr}>{links.translations.cmr_lb_at_hotel}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_my_hotel}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{links.translations.cmr_lb_info_n_a} </div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_my_phone}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{links.translations.cmr_lb_info_n_a}</div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_welcome_date}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{faceBriefDateTime} </div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_welcome_time}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{links.translations.cmr_lb_info_n_a}</div></Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_location}</Box>
                        </Grid>
                        <Grid item xs={9}>
                            <Box mb={1}><div className={classes.inputItemMr}>{ links.translations.cmr_lb_info_n_a }</div></Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mb={1} className={classes.labelHeaderMr}>{links.translations.cmr_lb_virtually}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_my_virtual}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{ links.translations.cmr_lb_info_n_a}</div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_virtual_date}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{virtualBriefDateTime }</div></Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_virtual_time}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={1}><div className={classes.inputItemMr}>{links.translations.cmr_lb_info_n_a }</div></Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box mb={1} className={classes.labelItemMr}>{links.translations.cmr_lb_join_here}</Box>
                        </Grid>
                        <Grid item xs={9}>
                            <Box mb={1}><div className={classes.inputItemMr}>{links.translations.cmr_lb_info_n_a}</div></Box>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            )  

        }
       
       
       
   


}

export default MyRep;