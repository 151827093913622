import React, { useState } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import RadioButtonTransfer from '../common/RadioButtonTransfer';
import RadioButtonNonTransfer from '../common/RadioButtonNonTransfer';

export default function SelectDeparture(props) {
	const { trans, agency, settings } = props;
	const [searchName, setSearchName] = useState("");
	const userStyles = makeStyles(agency)
	const classes = userStyles(props)
	//.log(settings);
	if (settings === null) {
		return (<div></div>)
	}
	else {
		return (
			<div className="container-checkin container-departure-form">
				<ReactNotifications />
				<p className="booking">{ settings.translations.form_group_booking_number } { (props.booking.agency_ref ? props.booking.agency_ref : props.booking.locator)}</p>
				<h1 className={classes.titleDepature}>{settings.translations.form_group_title_departure}</h1>
				<p className="description">{settings.translations.form_group_description_departure}</p>
				<div className="container-search">
					<TextField
						id="search-by-name"
						label={settings.translations.form_group_inp_search}
						onChange={(event) => {
							setSearchName(event.target.value)
						}}
					/>
				</div>
				<div className={classes.containerBookingLines}>
					{props.booking && props.booking.lines.filter((item) => {
						let names = item.paxes.map((pax) => { return pax.name + " " + pax.lastname }).join("|");
						if (searchName === "") {
							return item
						} else if (names.toLowerCase().includes(searchName.toLowerCase())) {
							return item
						}
					}).map((item, i)=> (item.direction === 'OUT' ? 
					<RadioButtonTransfer 
						key={i} 
						id={i} 
						locator={props.booking.locator}
						date={item.meeting_shortdate}
						item={item} 
						handleChange={props.handleChange('departure', item)}
						value={props.values.departure}
						search={searchName}
						links={settings}
						agency={agency}
						/> : ''))}
        
					<RadioButtonNonTransfer
						label={settings.translations.form_group_message_not_trf}
						handleChange={props.handleChange('departure', 'non')}
						value={props.values.departure}
						links={settings}
						agency={agency}
					/>
				</div>
				<div className="wizard-control">
					<Button size="large" onClick={props.prevStep} classes={{ root: "prev" }}>{settings.translations.form_group_btn_prev}</Button>
					<Button size="large"  style={{
						color: settings.primary_color,
					}} onClick={props.nextStep} classes={{ root: "next" }}>{settings.translations.form_group_btn_next}</Button>
				</div>
			</div>
		);
	}

}