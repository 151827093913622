import { makeStyles } from "@material-ui/core/styles";
// import  themeconf from "../data/config-theme.json";
import { useState } from "react";
import Config from "../data/config.json";
const hostname = Config[Config.env].whitelabel.isStage
  ? Config[Config.env].whitelabel.url
  : window.location.hostname;
const contentful = require("contentful");
const client = contentful.createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: Config[Config.env].cf_space,
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: Config[Config.env].cf_token,
  // This is the environment for this space.
  environment: Config[Config.env].cf_environment,
});
const getContentfulData = async (cookieLang) => {
  let cookie =
    cookieLang !== null && cookieLang !== undefined ? cookieLang : "en-CA";
  // console.log(cookie)
  let resp = await client
    .getEntries({
      content_type: "MainAgency",
      "fields.hostname": hostname,
      locale: cookie,
    })
    .then((response) => {
      return response.items[0].fields;
    });
  return resp;
};
const getContentfulAsset = async (cookieLang) => {
  let cookie =
    cookieLang !== null && cookieLang !== undefined ? cookieLang : "en-CA";
  // console.log(cookie)
  let resp = await client
    .getEntries({
      content_type: "MainAgency",
      "fields.hostname": hostname,
      locale: cookie,
    })
    .then((response) => {
      //  console.log(response.items[0].fields);
      return response.items[0].fields;
    });

  return resp;
};
const propsStyle = async (cookieLang) => {
  let response = await getContentfulData(cookieLang);
  // console.log(response)
  let Agency = {
    simple_name: response.name,
    hostname: response.hostname,
    text: response.companyName,
    link: response.deeplink,
    company_color: response.companyColor,
    primary_color: response.primaryColor,
    secondary_color: response.secondaryColor,
    company_label: response.logoLeft,
    xhub_label: response.urlLogoRight,
    background_image: response.imgBackgroundLogin,
    image_call: response.imgCall,
    image_chat: response.imgChat,
    // company_label_navbar: urlXhubLogoNavbar
  };
  let primary_color = "#" + Agency.primary_color;
  let secondary_color = "#" + Agency.secondary_color;
  let company_color = "#" + Agency.company_color;
  return {
    /*Login Form*/
    btn: {
      display: "block",
      width: "312px",
      height: "40px",
      borderRadius: "20px",
      backgroundColor: primary_color,
      border: "none",
      fontFamily: "Roboto",
      margin: "10px 0",
      color: secondary_color,
      fontSize: "16px",
      fontWeight: "600",
      cursor: "pointer",
      boxShadow: "none",
      appearance: "none",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: secondary_color,
        color: primary_color,
      },
      "&.Mui-disabled": {
        backgroundColor: "#c1c1c1",
        color: "#777",
      },
    },
    btnTrnsp: {
      display: "block",
      width: "312px",
      height: "40px",
      borderRadius: "20px",
      backgroundColor: "transparent",
      border: "none",
      fontFamily: "Roboto",
      margin: "10px 0",
      color: primary_color,
      fontSize: "16px",
      fontWeight: "600",
      cursor: "pointer",
      boxShadow: "none",
      appearance: "none",
      textTransform: "capitalize",
    },
    btnClkHre: {
      marginTop: "20px",
      backgroundColor: "transparent",
      color: company_color,
      fontSize: "14px",
      fontWeight: "400",
      border: "transparent",
      textTransform: "capitalize",
      "& u": {
        fontWeight: 600,
      },
    },
    imgleft: {
      position: "absolute",
      top: "0",
      left: "calc(50% - 130px)",
      width: "auto",
      height: "auto",
      marginRight: "20px",
    },
    imgright: {
      position: "absolute",
      top: "0",
      left: "calc(50% - 130px)",
      width: "auto",
      height: "auto",
      marginLeft: "165px",
      width: "auto",
      height: "auto",
    },
    imgLogin: {
      textAlign: "center",
      margin: "auto",
      display: "block",
    },
    containerLogin: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundImage: "url(" + Agency.background_image.fields.file.url + ")",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      marginBottom: "-48px",
      width: "100%",
      "& @media (min-width: 1800px)": {
        width: "30%",
        borderRadius: "20px",
        position: "relative",
        flex: "none",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    labellogin: {
      //display: 'block',
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: "bold",
      fontSize: "16px",
      textAlign: "center",
      color: primary_color,
    },
    inputlogin: {
      display: "block",
      width: "300px",
      border: "none",
      borderRadius: "5px",
      marginTop: "6px",
      marginBottom: "10px",
      height: "30px",
      padding: "2px 6px",
      textAlign: "center",
      backgroundColor: secondary_color,
      color: primary_color,
      opacity: ".6",
      fontSize: "16px",
      minWidth: "300px",
      "& :focus-visible": {
        outline: "none",
      },
    },
    /*Login Form*/
    /*Navbar Form*/
    appBar: {
      backgroundColor: secondary_color,
      color: primary_color,
    },
    containerLogo: {
      height: "72px",
    },
    containerInfo: {
      flexGrow: 11,
      height: "auto",
    },
    logo: {
      height: "auto",
      marginLeft: "-8px",
      maxWidth: "120px",
      maxHeight: "60px",
      marginTop: "6px",
    },
    lang: {
      height: "20px",
    },
    menuButton: {
      marginRight: "spacing(1)",
    },
    tags: {
      display: "block",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      marginLeft: "10px",
      color: "#747474",
    },
    username: {
      marginTop: "6px",
      fontSize: "11px",
      fontWeight: "bold",
      color: primary_color,
    },
    booking: {
      fontSize: "11px",
    },
    zones: {
      fontSize: "11px",
    },
    products: {
      fontSize: "11px",
    },
    date: {
      fontSize: "11px",
      fontWeight: "bold",
      color: "#000",
    },
    icon1NavBar: {
      position: "relative",
      fontWeight: "bold",
      fontSize: "12px",
    },
    icon2NavBar: {
      position: "relative",
      top: "8px",
      marginTop: "0px",
      left: "4px",
      width: "0",
      height: "0",
      borderLeft: "3px solid transparent",
      borderRight: "3px solid transparent",
      borderTop: "4px solid" + primary_color,
    },
    /*Navbar Form*/
    /*MyExcursion*/
    cardItemEx: {
      width: "90%",
      margin: "0 auto",
      padding: "0 10px",
    },
    marginCardEx: {
      marginBottom: "20px",
    },
    titleBoxEx: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "8px",
      marginBottom: "8px",
    },
    titleImgEx: {
      display: "inline-block",
      width: "25px",
    },
    titleItemEx: {
      margin: 0,
      color: company_color,
      textAlign: "center",
      fontSize: "20px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    labelItemEx: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "16px",
      fontWeight: "bold",
      color: "#7A7A78",
      padding: "5px",
    },
    inputItemEx: {
      backgroundColor: "#F4F4F4",
      margin: "5px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "12px",
      color: "#575756",
      padding: "7px",
      borderRadius: "4px",
      textAlign: "center",
      minHeight: "14px",
      // overflowX: 'scroll'
    },
    btnInfoEx: {
      color: "#fff",
      border: "1px solid" + primary_color,
      margin: "auto",
      minWidth: "90%",
      borderRadius: "6px",
      backgroundColor: primary_color,
      appearance: "auto",
      textRendering: "auto",
      letterSpacing: "normal",
      wordSpacing: "normal",
      textTransform: "none",
      textIndent: "0px",
      textShadow: "none",
      display: "inline-block",
      textAlign: "center",
      alignItems: "flex-start",
      cursor: "default",
      boxSizing: "border-box",
      font: "400 13.333px Roboto",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      padding: "4px 6px",
      textDecoration: "none",
    },
    /*MyExcursion*/
    /*MyTransfer*/
    cardItemTr: {
      width: "90%",
      margin: "0 auto",
      padding: "0 10px",
    },
    marginCardTr: {
      marginBottom: "20px",
    },
    titleBoxTr: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "8px",
      marginBottom: "8px",
    },
    titleImgTr: {
      display: "inline-block",
      width: "25px",
    },
    titleItemTr: {
      margin: 0,
      color: company_color,
      textAlign: "center",
      fontSize: "20px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    labelItemTr: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "16px",
      fontWeight: "bold",
      color: "#7A7A78",
      padding: "5px",
    },
    inputItemTr: {
      backgroundColor: "#F4F4F4",
      margin: "5px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "12px",
      color: "#575756",
      padding: "7px",
      borderRadius: "4px",
      textAlign: "center",
      minHeight: "14px",
      // overflowX: 'scroll'
    },
    btnInfoTr: {
      color: secondary_color,
      border: "1px solid" + primary_color,
      margin: "auto",
      minWidth: "90%",
      borderRadius: "6px",
      backgroundColor: primary_color,
      appearance: "auto",
      textRendering: "auto",
      letterSpacing: "normal",
      wordSpacing: "normal",
      textTransform: "none",
      textIndent: "0px",
      textShadow: "none",
      display: "inline-block",
      textAlign: "center",
      alignItems: "flex-start",
      cursor: "default",
      boxSizing: "border-box",
      font: "400 13.333px Roboto",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      padding: "4px 6px",
      textDecoration: "none",
    },
    /*MyTransfer*/
    /*NotFound*/
    cardItemNf: {
      width: "90%",
      margin: "0 auto",
      padding: "12px 10px",
    },
    marginCardNf: {
      marginTop: "-20px",
      marginBottom: "20px",
    },
    titleBoxNf: {
      alignItems: "center",
      justifyContent: "center",
      marginTop: "8px",
      marginBottom: "8px",
      textAlign: "center",
    },
    titleImgNf: {
      display: "block",
      width: "35px",
      margin: "auto",
    },
    titleItemNf: {
      margin: "0 auto",
      color: company_color,
      textAlign: "center",
      fontSize: "20px",
      fontFamily: "Roboto",
      width: "200px",
      fontWeight: "500",
    },
    textItemNf: {
      margin: "0 auto",
      color: "#505050",
      textAlign: "center",
      fontSize: "16px",
      fontFamily: "Roboto",
      width: "auto",
      fontWeight: "500",
    },
    btnInfoNf: {
      color: secondary_color,
      border: "1px solid" + primary_color,
      height: "30px",
      margin: "auto",
      minWidth: "50%",
      borderRadius: "6px",
      backgroundColor: primary_color,
      appearance: "auto",
      textRendering: "auto",
      letterSpacing: "normal",
      wordSpacing: "normal",
      textTransform: "none",
      textIndent: "0px",
      textShadow: "none",
      display: "inline-block",
      textAlign: "center",
      alignItems: "flex-start",
      cursor: "default",
      boxSizing: "border-box",
      font: "400 13.333px Roboto",
      padding: "1px 6px",
    },
    /*NotFound*/
    /*Boarding pass */
    imgQRBp: {
      width: "150px",
      height: "150px",
      margin: "auto",
      border: "1px solid " + primary_color,
    },
    bookingCodeBp: {
      color: company_color,
      fontSize: "14px",
      fontWeight: "bold",
    },
    tagLabelBp: {
      color: "#575756",
      fontSize: "14px",
    },
    tagInputBp: {
      color: "#505050",
      fontSize: "14px",
      fontWeight: "bold",
    },
    boxInfoNotCorrectBp: {
      textAlign: "center",
    },
    btnConfirmInfoBp: {
      color: secondary_color,
      border: "1px solid" + primary_color,
      margin: "0 auto",
      minWidth: "50%",
      borderRadius: "6px",
      backgroundColor: primary_color,
      appearance: "auto",
      textRendering: "auto",
      letterSpacing: "normal",
      wordSpacing: "normal",
      textTransform: "none",
      textIndent: "0px",
      textShadow: "none",
      display: "inline-block",
      textAlign: "center",
      alignItems: "flex-start",
      cursor: "default",
      boxSizing: "border-box",
      font: "400 13.333px Roboto",
      padding: "4px 6px",
      textDecoration: "none",
      "&:hover, &:focus, &:active": {
        color: secondary_color,
        backgroundColor: primary_color,
      },
    },
    infoNotCorrectBp: {
      padding: "0px 22px",
      color: "#7A7A78",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    marginBottomBp: {
      marginBottom: "20px",
      marginTop: "-52px",
    },
    backLinkBp: {
      position: "relative",
      top: "74px",
      backgroundColor: "rgba(255,255,255, 0.5)",
    },
    /*Boarding pass*/
    /*booking Info*/
    headingBi: {
      fontSize: "typography.pxToRem(15)",
      fontWeight: "typography.fontWeightBold",
      color: "#565654",
    },
    headingHs: {
      fontSize: "typography.pxToRem(15)",
      fontWeight: "typography.fontWeightBold",
      color: "#565654",
      justifyContent: "start",
      textTransform: "capitalize",
      fontFamily: ' "Roboto", "Times New Roman", Times, serif',
      padding: "0px 0px",
    },
    imgIconBi: {
      width: "20px",
      height: "20px",
      marginRight: "8px",
    },
    btnConfirmInfoBi: {
      color: secondary_color,
      border: "1px solid " + primary_color,
      margin: "0 0 0 auto",
      minWidth: "50%",
      borderRadius: "6px",
      backgroundColor: primary_color,
      appearance: "auto",
      textTransform: "none",
      cursor: "default",
      boxSizing: "border-box",
      font: "400 13.333px Roboto",
      padding: "4px 6px",
      "&:hover, &:focus, &:active": {
        color: secondary_color,
        backgroundColor: primary_color,
      },
    },
    accordionDetailsBi: {
      padding: 0,
      flexDirection: "column",
      backgroundColor: "#EAEAEA",
    },
    accordionMenuHs: {
      color: "#F37021",
      fontWeight: "bold",
      fontFamily: ' "Roboto", "Times New Roman", Times, serif',
    },
    accordionMenuHs2: {
      color: "#7A7A78",
      fontWeight: "bold",
      fontFamily: ' "Roboto", "Times New Roman", Times, serif',
    },
    accordionDetailsHs: {
      padding: 0,
      flexDirection: "column",
      backgroundColor: "#EAEAEA",
    },

    accordionDetailsFaq: {
      padding: 0,
      flexDirection: "column",
      margin: "10px",
      textAlign: "justify",
    },
    accordionDetailsFaq2: {
      padding: 0,
      flexDirection: "column",
      margin: "10px",
      textAlign: "justify",
      backgroundColor: "#EAEAEA",
    },
    itemListHs: {
      padding: "10px 16px",
      borderTop: "1px solid #D3D3D3",
      borderBottom: "1px solid #D3D3D3",
      justifyContent: "start",
      fontWeight: "400",
      textTransform: "capitalize",
      fontSize: "16px",
      borderRadius: "0px",
    },
    itemListBi: {
      padding: "10px 16px",
      borderTop: "1px solid #D3D3D3",
      borderBottom: "1px solid #D3D3D3",
      justifyContent: "start",
      fontWeight: "400",
      textTransform: "capitalize",
      fontSize: "16px",
      borderRadius: "0px",
    },
    buttonBi: {
      position: "fixed",
      top: "80px",
      left: "4px",
      zIndex: 100,
      backgroundColor: "rgba(243,243,243,0.8)",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
    buttonBiNotif: {
      position: "fixed",
      zIndex: 100,
    },
    titleItemNot: {
      color: primary_color,
      textAlign: "left",
      fontSize: "25px",
      marginLeft: "35px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      marginBottom: "auto",
    },
    notificationBox: {
      maxWidth: "calc(100% - 0px)",
      top: "50%" /* IMPORTANT */,
      left: "50%" /* IMPORTANT */,
    },
    btnIconBi: {
      color: "#575756",
    },
    /*booking Info*/
    /*my voucher*/
    imgQRMb: {
      width: "150px",
      height: "150px",
      margin: "auto",
      border: "1px solid" + primary_color,
    },
    bookingCodeMb: {
      color: company_color,
      fontSize: "14px",
      fontWeight: "bold",
    },
    tagLabelMb: {
      color: "#575756",
      fontSize: "14px",
    },
    tagInputMb: {
      color: "#505050",
      fontSize: "14px",
      fontWeight: "bold",
    },
    tagInputOrangeMb: {
      color: company_color,
      fontSize: "14px",
      fontWeight: "bold",
    },
    boxInfoNotCorrectMb: {
      textAlign: "center",
    },
    btnConfirmInfoMb: {
      color: "#fff",
      border: "1px solid " + primary_color,
      margin: "0 auto",
      minWidth: "50%",
      borderRadius: "6px",
      backgroundColor: primary_color,
      appearance: "auto",
      textRendering: "auto",
      letterSpacing: "normal",
      wordSpacing: "normal",
      textTransform: "none",
      textIndent: "0px",
      textShadow: "none",
      display: "inline-block",
      textAlign: "center",
      alignItems: "flex-start",
      cursor: "default",
      boxSizing: "border-box",
      font: "400 13.333px Roboto",
      padding: "4px 6px",
      textDecoration: "none",
      "&:hover, &:focus, &:active": {
        color: secondary_color,
        backgroundColor: primary_color,
      },
    },
    infoNotCorrectMb: {
      padding: "0px 22px",
      color: "#7A7A78",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    marginBottomMb: {
      marginBottom: "10px",
      marginTop: "-52px",
    },
    backLinkMb: {
      position: "relative",
      top: "74px",
      backgroundColor: "rgba(255,255,255, 0.5)",
    },
    /*my voucher */
    /*Verify Info */
    cardItemVi: {
      width: "90%",
      margin: "0 auto",
      padding: "0 10px",
    },
    marginCardVi: {
      marginBottom: "20px",
    },
    titleBoxVi: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "8px",
      marginBottom: "8px",
    },
    titleImgVi: {
      display: "inline-block",
      width: "25px",
    },
    titleItemVi: {
      margin: 0,
      color: company_color,
      textAlign: "center",
      fontSize: "20px",
      fontFamily: "Roboto",
    },
    labelItemVi: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#7A7A78",
      padding: "5px",
    },
    inputItemVi: {
      backgroundColor: "#F4F4F4",
      margin: "5px",
      fontFamily: "Roboto",
      fontSize: "12px",
      color: "#575756",
      padding: "7px",
      borderRadius: "4px",
      textAlign: "center",
      minHeight: "14px",
      // overflowX: 'scroll'
    },
    btnInfoVi: {
      color: "#fff",
      border: "1px solid" + primary_color,
      margin: "auto",
      minWidth: "50%",
      borderRadius: "6px",
      backgroundColor: primary_color,
      appearance: "auto",
      textRendering: "auto",
      letterSpacing: "normal",
      wordSpacing: "normal",
      textTransform: "none",
      textIndent: "0px",
      textShadow: "none",
      display: "inline-block",
      textAlign: "center",
      alignItems: "flex-start",
      cursor: "default",
      boxSizing: "border-box",
      font: "400 13.333px Roboto",
      padding: "4px 6px",
    },
    boxInfoNotCorrectVi: {
      textAlign: "center",
    },
    btnConfirmInfoVi: {
      color: secondary_color,
      border: "1px solid " + primary_color,
      margin: "0 auto",
      minWidth: "50%",
      borderRadius: "6px",
      backgroundColor: primary_color,
      appearance: "auto",
      textRendering: "auto",
      letterSpacing: "normal",
      wordSpacing: "normal",
      textTransform: "none",
      textIndent: "0px",
      textShadow: "none",
      display: "inline-block",
      textAlign: "center",
      alignItems: "flex-start",
      cursor: "default",
      boxSizing: "border-box",
      font: "400 13.333px Roboto",
      padding: "4px 6px",
      "&:hover, &:focus, &:active": {
        color: secondary_color,
        backgroundColor: primary_color,
      },
    },
    infoNotCorrectVi: {
      padding: "0px 22px",
      color: "#7A7A78",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    marginBottomVi: {
      marginBottom: "10px",
      marginTop: "-52px",
    },
    backLinkVi: {
      position: "relative",
      top: "74px",
      backgroundColor: "rgba(255,255,255, 0.5)",
    },
    /*Verify Info*/
    /*Home*/
    imageBannerHm: {
      width: "100%",
      height: "auto",
    },
    btnInfoHm: {
      width: "100%",
      height: "54px",
      backgroundColor: primary_color,
      color: "#fff",
      border: "1px solid" + primary_color,
      borderRadius: "6px",
    },
    btnInfoDisableHm: {
      width: "100%",
      height: "54px",
      backgroundColor: "#bfbfbf",
      color: "#fff",
      border: "1px solid #827a74",
      borderRadius: "6px",
    },
    /*Home */
    /*Myvoucher*/
    imgQRMv: {
      width: "150px",
      height: "150px",
      margin: "auto",
      border: "1px solid" + primary_color,
    },
    bookingCodeMv: {
      color: primary_color,
      fontSize: "14px",
      fontWeight: "bold",
    },
    tagLabelMv: {
      color: "#575756",
      fontSize: "14px",
    },
    tagInputMv: {
      color: "#505050",
      fontSize: "14px",
      fontWeight: "bold",
    },
    tagInputOrangeMv: {
      color: company_color,
      fontSize: "14px",
      fontWeight: "bold",
    },
    boxInfoNotCorrectMv: {
      textAlign: "center",
    },
    btnConfirmInfoMv: {
      color: "#fff",
      border: "1px solid" + primary_color,
      margin: "0 auto",
      minWidth: "50%",
      borderRadius: "6px",
      backgroundColor: primary_color,
      appearance: "auto",
      textRendering: "auto",
      letterSpacing: "normal",
      wordSpacing: "normal",
      textTransform: "none",
      textIndent: "0px",
      textShadow: "none",
      display: "inline-block",
      textAlign: "center",
      alignItems: "flex-start",
      cursor: "default",
      boxSizing: "border-box",
      font: "400 13.333px Roboto",
      padding: "4px 6px",
      "&:hover, &:focus, &:active": {
        color: secondary_color,
        backgroundColor: primary_color,
      },
    },
    infoNotCorrectMv: {
      padding: "0px 22px",
      color: "#7A7A78",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    /*Myvoucher*/
    /*TrfArrival*/
    cardItemTa: {
      width: "90%",
      margin: "0 auto",
      padding: "0 10px",
    },
    marginCardTa: {
      marginBottom: "20px",
    },
    titleBoxTa: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "8px",
      marginBottom: "8px",
    },
    titleImgTa: {
      display: "inline-block",
      width: "25px",
    },
    titleItemTa: {
      margin: 0,
      color: primary_color,
      textAlign: "center",
      fontSize: "20px",
      fontFamily: "Roboto",
    },
    labelItemTa: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#7A7A78",
      padding: "5px",
    },
    inputItemTa: {
      backgroundColor: "#F4F4F4",
      margin: "5px",
      fontFamily: "Roboto",
      fontSize: "12px",
      color: "#575756",
      padding: "7px",
      borderRadius: "4px",
      textAlign: "center",
      minHeight: "14px",
      // overflowX: 'scroll'
    },
    btnInfoTa: {
      color: secondary_color,
      border: "1px solid" + primary_color,
      margin: "auto",
      minWidth: "50%",
      borderRadius: "6px",
      backgroundColor: primary_color,
      appearance: "auto",
      textRendering: "auto",
      letterSpacing: "normal",
      wordSpacing: "normal",
      textTransform: "none",
      textIndent: "0px",
      textShadow: "none",
      display: "inline-block",
      textAlign: "center",
      alignItems: "flex-start",
      cursor: "default",
      boxSizing: "border-box",
      font: "400 13.333px Roboto",
      padding: "4px 6px",
    },
    boxInfoNotCorrectViT: {
      textAlign: "center",
    },
    btnConfirmInfoViT: {
      color: "#fff",
      border: "1px solid " + primary_color,
      margin: "0 auto",
      minWidth: "50%",
      borderRadius: "6px",
      backgroundColor: primary_color,
      appearance: "auto",
      textRendering: "auto",
      letterSpacing: "normal",
      wordSpacing: "normal",
      textTransform: "none",
      textIndent: "0px",
      textShadow: "none",
      display: "inline-block",
      textAlign: "center",
      alignItems: "flex-start",
      cursor: "default",
      boxSizing: "border-box",
      font: "400 13.333px Roboto",
      padding: "4px 6px",
      "&:hover, &:focus, &:active": {
        color: secondary_color,
        backgroundColor: primary_color,
      },
    },
    infoNotCorrectViT: {
      padding: "0px 22px",
      color: "#7A7A78",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    /*TrfArrival */
    /*MyRep*/
    cardItemMr: {
      width: "90%",
      margin: "0 auto",
      padding: "0 10px",
    },
    marginCardMr: {
      marginBottom: "20px",
    },
    titleBoxMr: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "6px",
      marginBottom: "6px",
    },
    titleImgMr: {
      display: "inline-block",
      width: "50px",
    },
    titleItemMr: {
      margin: 0,
      color: company_color,
      textAlign: "center",
      fontSize: "20px",
      fontFamily: "Roboto",
    },
    labelItemMr: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#7A7A78",
      padding: "5px",
    },
    labelHeaderMr: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
      color: company_color,
      padding: "5px",
    },
    inputItemMr: {
      backgroundColor: "#F4F4F4",
      margin: "5px",
      fontFamily: "Roboto",
      fontSize: "12px",
      color: "#575756",
      padding: "7px",
      borderRadius: "4px",
      textAlign: "center",
      minHeight: "14px",
      // overflowX: 'scroll'
    },
    btnInfoMr: {
      color: "#fff",
      border: "1px solid" + primary_color,
      margin: "auto",
      minWidth: "50%",
      borderRadius: "6px",
      backgroundColor: primary_color,
      appearance: "auto",
      textRendering: "auto",
      letterSpacing: "normal",
      wordSpacing: "normal",
      textTransform: "none",
      textIndent: "0px",
      textShadow: "none",
      display: "inline-block",
      textAlign: "center",
      alignItems: "flex-start",
      cursor: "default",
      boxSizing: "border-box",
      font: "400 13.333px Roboto",
      padding: "4px 6px",
    },
    /*MyRep */
    strongs: {
      color: company_color,
    },
    textlocalexpert: {
      color: "#878787",
      fontSize: "20px",
      padding: "5px",
      margin: "30px auto 0",
      width: "300px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    descriptionlocalexpert: {
      color: "#A9A9A9",
      fontSize: "16px",
      width: "300px",
      margin: " 0 auto",
      textAlign: "justify",
    },
    inputcontactinfolabel: {
      color: company_color,
      marginLeft: "0",
      fontSize: "18px",
      display: "block",
      textAlign: "left",
      fontWeight: "500",
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
      "& .input-contact-info .react-tel-input .special-label": {
        color: company_color,
        display: "block",
        top: "0",
        left: "0",
        width: "100%",
        fontSize: "18px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "500",
        textAlign: "left",
      },
    },
    oneOptionContactForm: {
      color: primary_color,
      width: "300px",
      margin: "0 auto",
      "&  p": {
        fontSize: "12px",
        textAlign: "justify",
        margin: "4px 2px",
      },
    },
    termsContactForm: {
      display: "flex",
      width: "300px",
      margin: "0 auto",
    },
    buttonL: {
      width: "120px",
      height: "33px",
      backgroundColor: primary_color,
      border: "1px solid" + primary_color,
      borderRadius: "5px",
      fontSize: "16px",
      color: secondary_color,
      padding: "4px 10px",
      fontWeight: "500",
      marginTop: "12px",
    },
    /*Contact Form*/
    /*Share my experience*/
    myexpBtnSurvey: {
      width: "auto",
      height: "18px",
      backgroundColor: primary_color,
      border: "1px solid" + primary_color,
      borderRadius: "5px",
      fontSize: "16px",
      color: secondary_color,
      padding: "4px 10px",
      fontWeight: "500",
      margin: "8px auto 0",
      textDecoration: "none",
      display: "block",
      maxWidth: "200px",
    },
    Thanku: {
      margin: "0 12px",
      textAlign: "center",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      color: "#878787",
      "& p": {
        fontSize: "16px",
      },
      "& strong": {
        color: company_color,
      },
    },
    /*Share my experience*/
    /*ContactUs */
    contactCardTitle: {
      display: "block",
      fontFamily: ' "Roboto", "Times New Roman", Times, serif',
      fontSize: "14px",
      fontWeight: "bold",
      color: company_color,
      marginBottom: "5px",
    },
    titleSocial: {
      margin: "8px",
      textAlign: "center",
      fontSize: "16px",
      color: company_color,
    },
    titleWeb: {
      margin: "-5px 0 8px 0",
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "800",
      color: company_color,
      fontFamily: "Roboto",
      display: "block",
      textDecoration: "none",
    },
    /*ContactUs */
    /* checkContactInfo*/
    checkContactInfo: {
      color: company_color,
    },
    palette: {
      primary: {
        light: "#757ce8",
        main: company_color,
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: company_color,
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
    /* checkContactInfo*/
    /*ModalBox*/
    containerMoB: {
      position: "fixed",
      zIndex: "1300",
      inset: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdropMoB: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      zIndex: -1,
      position: "fixed",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      //-webkit-tap-highlight-color: transparent;
    },
    paperMoB: {
      display: "flex",
      maxHeight: "calc(100% - 64px)",
      flexDirection: "column",
      color: "rgba(0, 0, 0, 0.87)",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      backgroundColor: secondary_color,
      boxShadow:
        "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
      margin: "32px",
      position: "relative",
      overflowY: "auto",
      borderRadius: "4px",
      maxWidth: "600px",
      padding: "24px 15px",
      width: "90%",
    },
    titleMoB: {
      textAlign: "center",
      color: "#505050",
    },
    buttonMoB: {
      color: secondary_color,
      backgroundColor: primary_color,
      textTransform: "initial",
      textDecoration: "none",
      textAlign: "center",
      minWidth: "64px",
      boxSizing: "border-box",
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1.75",
      borderRadius: "4px",
      padding: "6px 8px",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover, &:focus, &:active": {
        color: secondary_color,
        backgroundColor: primary_color,
      },
    },
    /*ModalBox*/
    /*Loader */
    containerBackLoader: {
      inset: "0px",
      display: "flex",
      zIndex: "1300",
      position: "fixed",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    containerLoader: {
      width: "64px",
      height: "64px",
      backgroundColor: "#fff",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: primary_color,
      "& .MuiCircularProgress-colorPrimary": {
        color: primary_color,
      },
    },
    /*Loader*/
    /*chat*/
    containerchat: {
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      backgroundImage: "url(" + Agency.image_chat.fields.file.url + ")",
    },
    containercall: {
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      backgroundImage: "url(" + Agency.image_call.fields.file.url + ")",
    },
    btnLanguage: {
      fontSize: "15px",
      color: primary_color,
      fontFamily: "Roboto",
      fontWeight: "400",
      position: "absolute",
      top: "0px",
      right: "0px",
      width: "auto",
      height: "auto",
      backgroundColor: "transparent",
      border: "none",
      marginTop: "0",
      padding: "12px",
    },

    /*chat*/

    /*HurricaneDetails*/
    boxesHr: {
      textAlign: "justify",
      lineHeight: "1.5",
      marginLeft: "10px",
      marginRight: "10px",
      fontWeight: "light",
      color: "#7A7A78",
      fontFamily: ' "Roboto", "Times New Roman", Times, serif',
    },
    dateHr: {
      textAlign: "center",
      color: primary_color,
      fontWeight: "bold",
      fontFamily: ' "Roboto", "Times New Roman", Times, serif',
      marginLeft: "5px",
      marginRight: "5px",
      marginTop: "5px",
      marginBottom: "5px",
    },
    titleHr: {
      textAlign: "center",
      fontWeight: "bold",
      color: "#7A7A78",
      fontFamily: ' "Roboto", "Times New Roman", Times, serif',
    },
    /*Notifications*/
    boxesNoti: {
      textAlign: "justify",
      lineHeight: "1.5",
      marginLeft: "10px",
      marginRight: "10px",
      fontWeight: "light",
      color: "#7A7A78",
      fontFamily: ' "Roboto", "Times New Roman", Times, serif',
    },
    titleNoti: {
      textAlign: "center",
      fontWeight: "bold",
      color: primary_color,
      fontFamily: ' "Roboto", "Times New Roman", Times, serif',
      marginBottom: "40px",
      fontSize: "20px",
    },
    iconNoti: {
      color: primary_color,
      height: "20px",
      marginTop: "15px",
    },
    descNoti: {
      textAlign: "center",
      color: "#7A7A78",
      fontFamily: ' "Roboto", "Times New Roman", Times, serif',
      fontSize: "13px",
    },
    cardRoot: {
      marginTop: "-15px",
      marginBottom: "-15px",
    },
    notiIconGrid: {
      flexBasis: "0%",
    },
  };
};

const usingStyles = (styles) => {
  let userStyles = makeStyles(styles);
  let classing = userStyles();
  return classing;
};

export {
  propsStyle,
  useState,
  getContentfulData,
  usingStyles,
  getContentfulAsset,
};
