import React from 'react';
import {Paper} from '@material-ui/core';
import {Link} from "react-router-dom";
import Config from "../../../data/config.json";
import {usingStyles} from '../../../services/themeOptions.js'


const ModalBox = (props) => {

	// console.log(window.location.pathname);
	if(window.location.pathname !== '/my-nexus-wallet')
	{

		if(props !== null)
		{
			const {bookings,styles,links} = props;
			
			const classing=usingStyles(styles)
			if(bookings !== null)
			{
				// var exists = Config.RulesApplyBP.find((item) => { return (item === 'all' ? true : (item === bookings.booking.delegation_id))});
				// if(exists !== undefined && exists !== null)
				// {
					if(!bookings.booking.have_atp)
					{
						return (
							<div className={classing.containerMoB}>
								<div className={classing.backdropMoB} />
								<Paper className={classing.paperMoB}>
									<h3 className={classing.titleMoB}>{links.translations.bp_modal_title}</h3>
									<Link to="/my-nexus-wallet?generate=true" className={classing.buttonMoB}>{links.translations.bp_modal_btn}</Link>
								</Paper>
							</div>
						);
					}
				// }
			}
		}
	}
	return null
}

export default ModalBox;