import 'react-notifications-component/dist/theme.css'
import '../../../scss/Login.scss';
import React, { Component } from 'react';
// import Login from './Login';
import SignIn from './SignIn';
import Register from './Register';
// import ApiXHub from '../../../services/ApiXHub';
// import { Store } from 'react-notifications-component';
import Helpers from "../../libraries/helpers";
import Cookies from 'universal-cookie';
import Encrypt from '../../libraries/SessionEncrypt';
import translations from "../../../data/translation.json";
import Validation from '../../../services/Validation';
import ApiXHub from '../../../services/ApiXHub';
import Config from "../../../data/config.json";
import ReactGA from 'react-ga';
import { getContentfulAsset,propsStyle} from '../../../services/themeOptions'
import Chat from "../common/Chat"


const helpers = new Helpers();
const cookies = new Cookies();

class LoginFlow extends Component {
	constructor (props) {
		super(props)
		this.state= {
			locale: (helpers.existsLangCookie() ? helpers.getLangCookie() : helpers.getLangNav()),
			step:"Register",
			openLang: null,
			loader: false,
			typingTimeout: 0,
			showLastname: false,
			registerDisabled: false,
			agencyInfo: null,
			form:{
				locator: "",
				email: "",
				lastname: "",
				agencyref: "",
				agency:null
			},
			mainAgency: null,
			settings:null
		}
	}

	handleChangeLocale = async (iso) => {
		cookies.set('locale', iso, {path: "/"});
		await this.setState({
			...this.state,
			locale: iso
		})
		this.handleCloseLang();
		this.getMainAgency(this.state.locale);
	}

	handleOpenLang = (e) => {
		this.setState({
			...this.state,
			openLang: e.currentTarget
		})
	}

	handleCloseLang = () => {
		this.setState({
			...this.state,
			openLang: null
		})
	}
	
	handleLogin = () => {
		this.setState({
			...this.state,
			step: 'Login'
		});
	}

	handleRegister = () => {
		this.setState({
			...this.state,
			step: 'Register'
		})
	}

	handleLoader = async (status) => {
		await this.setState({
			...this.state,
			loader : status
		})
	}

	goToBack = ()  => {
		this.setState({
			...this.state,
			step: 'Register'
		})
	}

	signIn = async () => {
		//console.log("Sign In");
		this.handleLoader(true);
		let request = {
			code: this.state.form.locator,
			contact: this.state.form.email,
			company: this.state.settings.simple_name
		}
		//console.log(request);
		request = Validation.login(request);

		if(request)
		{
			let response = await ApiXHub.login(request);
			if(response.data.is_valid)
			{
				await this.login(response);
			}
		}
		this.handleLoader(false);
	}

	register = async () => {
		await this.handleLoader(true);

		// Validar la reserva
		let valid = await this.validateReservation();

		if(valid){
			let request = {
				code: this.state.form.locator,
				company: this.state.settings.simple_name
			}

			request = Validation.loginRegister(request);

			if(request)
			{
				//console.log(request);
				let response = await ApiXHub.login(request);
				if(response.data.is_valid)
				{
					// JJ2HT3
					if(response.data.status.toUpperCase() === 'OK')
					{
						// console.log(response);
						await this.login(response)
					}else {
						Validation.showNotification(this.state.settings.validations.EH1006);
					}
				} else {
					//ERROR_LOGIN_PAX_NULL
					if(response.data.errors.length > 0){
						ReactGA.exception({description: this.state.settings.validations.EH1006});
						response.data.errors.forEach(item => {
							Validation.showNotification(this.state.settings.validations.EH1006);
						});
					} else {
						ReactGA.exception({description: this.state.settings.validations.EH1002});
						Validation.showNotification(this.state.settings.validations.EH1002);
					}
				}
			}
		}

		await this.handleLoader(false);
	}

	login = async (response) => {
		let iso = this.state.locale;
		// Encriptar del login
			let authtoken = response.data.token;
			let user = {
				locator: response.data.locator,
				contact: response.data.contact,
				lines: response.data.lines,
				name: response.data.name,
				lastname: response.data.lastname,
				delegation_id: response.data.delegation_id,
				entry_id: response.data.entry_id,
				language: response.data.language,
				status: response.data.status,
				is_groups: response.data.is_groups,
				is_registered: response.data.is_registered,
				is_booking_registered: response.data.is_booking_registered,
				is_valid: response.data.is_valid
				
			};
			// console.log(user);
			const session = new Encrypt();
			let sessionEncrypt = session.encrypt(user);
			cookies.set('xhub-info', sessionEncrypt, {path: "/"});
			// console.log(sessionEncrypt);
		// Almacenar el token en cookie
			localStorage.setItem('xhub-session', authtoken);
			
		// Setear el Lenguaje de preferencia y verificar que sea un idioma valido
			if(user.language !== null && user.language !== '')
			{
				var exists = Config.validLanguages.find((item) => { return item.toLowerCase().includes(user.language.toLowerCase()) });
				if(exists !== undefined && exists !== null)
				{
					iso = exists; //user.language;
				}
			}
			cookies.set('locale', iso, {path: "/"});
		// Setear el Destination ID de Contentful
			if(user.entry_id !== null && user.entry_id !== '')
			{
				localStorage.setItem('xhub-entry-id', user.entry_id);
			}
		// Activar evento de Login
			ReactGA.event({
				category: 'Login',
				action: 'Sign in',
				label: 'Button Sign in'
			});
		if(user.is_registered)
		{
			if(user.is_groups)
			{
				if(user.is_booking_registered)
				{
					window.location = '/home'
					// console.log("Ir a Home")
				} else {
					window.location = '/groups'
					// console.log("Ir a Grupos")
				}
			} else {
				if(user.is_booking_registered)
				{
					window.location = '/home'
					// console.log("Ir a Home")
				} else {
					// console.log("Añadir el booking al usuario");
					let requestL = {
						token: authtoken,
						locator: user.locator,
						lines: user.lines
					}
					// console.log(requestL);
					requestL = Validation.addLinesLogin(requestL);
					// console.log("Validation");
					// console.log(requestL);
					if(requestL)
					{
						// console.log("Add Lines");
						let responseL = await ApiXHub.addLines(requestL);
						// console.log(responseL);
						if(responseL.data.is_valid)
						{
							// console.log("Ir a Home")
							window.location = '/home'
						}
					}
					
				}
			}
		} else {
			//Validation.showNotification("No existe ese correo o telefono registrado");
			// No existe ese correo o telefono registrado
			// La reserva no es valida
			if(user.is_groups)
			{
				window.location = '/contact-flow'
				// console.log("Ir a Grupos")
			} else {
				window.location = '/contact-form'
				// console.log("Ir a Contacto")
			}
		}
	}

	validateReservation = async () => {
		let is_valid = false;
		let request = {
			code: this.state.form.agencyref,
		}

		if(this.state.showLastname)
		{
			request.lastname = this.state.form.lastname
		}

		request = Validation.validateReservation(request);
		if(request)
		{
			let responseValidate = await ApiXHub.validateReservation(request);
			if(responseValidate.data.is_valid)
			{
				const result = responseValidate.data;

				if(result !== null && result.is_valid)
				{
					is_valid = true;
					if(result.number_bookings > 1){
						if(result.is_agency_partner){
							this.setState({
								...this.state,
								agencyInfo: result,
								showLastname: result.is_agency_partner,
								registerDisabled: true
							})
							is_valid = false;
						} else {
							this.setState({
								...this.state,
								agencyInfo: result,
								registerDisabled: false,
								form:{
									...this.state.form,
									locator: result.bookings[0]
								}
							})
						}
					} else if(result.number_bookings === 1)
					{
						this.setState({
							...this.state,
							agencyInfo: result,
							registerDisabled: false,
							form:{
								...this.state.form,
								locator: result.bookings[0]
							}
						})
					} else if (result.number_bookings === 0){
						this.setState({
							...this.state,
							agencyInfo: result,
							registerDisabled: true,
							form:{
								...this.state.form,
								locator: ""
							}
						})
						Validation.showNotification("Booking and last name do not match", "Error!");	
						is_valid = false;
					}
				} else {
					Validation.showNotification("The booking is invalid", "Format error!");
					is_valid = false;
				}
			}
		}
		return is_valid;
	}

	handleChange = async (e) => {
		await this.setState({
			...this.state,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		})
	}

	handleChangeAgencyRef = async (e) => {
		const input = e.target.name;
		const query = e.target.value;
		await this.handleChange(e)

		if(input === 'lastname'){
			if(query.length >= 2){
				this.setState({
					registerDisabled: false
				})
			} else {
				this.setState({
					registerDisabled: true
				})
			}
		}
	}

	getMainAgency =  async () =>{
		let Locale=this.state.locale;
		Locale=Locale!== null && Locale !== undefined ? Locale :'en-CA'
		let response =   await getContentfulAsset(Locale)
		let value="1"
		    let Agency = {
				simple_name: response.name,
				hostname: response.hostname,
				text: response.companyName,
				link: response.deeplink,
				company_color: response.companyColor,
				primary_color: response.primaryColor,
				secondary_color: response.secondaryColor,
				 company_label: response.logoLeft,
				 xhub_label: response.logoRight,
				 background_image:response.imgBackgroundLogin,
				 image_call:response.imgCall,
				 image_chat:response.imgChat,
				 translations:response.translations,
				 validations:response.validations,
				 valued:value==="1"?'container-chat2':'container-chat' 
				// company_label_navbar: urlXhubLogoNavbar
			}
			 Agency.primary_color="#"+Agency.primary_color
			 Agency.secondary_color="#"+Agency.secondary_color
			 Agency.company_color="#"+Agency.company_color
			let style= await propsStyle(Locale);

			this.setState({...this.state, mainAgency: style,settings: Agency})
			//console.log(Agency)
				
	}
	activateMsgLink= async ()=> {
		(function(w,d,t,u,n,a,m){
				a=d.createElement(t);
				m=d.getElementsByTagName(t)[0];
				a.async=1;
				a.src=u;m.parentNode.insertBefore(a,m)
			})
			(window,document,"script","https://mas-wepartner.inconcertcc.com/public/integration/scripts.js?token=9295d6e620e3e515555646d0a68a611b","ic");

	}
	componentWillMount() {   
		helpers.redirectSession();
		let Locale=this.state.locale;
		if(Locale!==null||Locale!==undefined){
			this.state.locale='en-CA';
		} 
		this.getMainAgency();
		this.activateMsgLink();
	}
	componentDidMount() {
		//this.getMainAgency();
		//useStyles(this.state.mainAgency);
		
		//console.log("dosuno")
	}

	render () {
		
		const {step, locale, openLang, loader,mainAgency,settings,showLastname,registerDisabled} = this.state;
		if(step!==null||step!==undefined)
		{
					return (
						<div>
							<Register 
								register={this.register}
								handleLogin={this.handleLogin}
								handleChange={this.handleChange}
								handleChangeAgencyRef={this.handleChangeAgencyRef}
								handleChangeLocale={this.handleChangeLocale}
								handleOpenLang={this.handleOpenLang}
								handleCloseLang={this.handleCloseLang}
								form={this.state.form}
								trans={translations[locale]}
								loader={loader}
								openLang={openLang}
								locale={locale}
								agency={this.state.mainAgency}
								loginSettings={this.state.settings}
								openReg={step==="Register"}
								showLastname={showLastname}
								registerDisabled={registerDisabled}
							/>
							<SignIn 
								signIn={this.signIn}
								goToBack={this.goToBack}
								handleChange={this.handleChange}
								form={this.state.form}
								trans={translations[locale]}
								loader={loader}
								agency={this.state.mainAgency}
								loginSettings={this.state.settings}
								openReg={step==="Login"}
							/>
							<Chat 
								agency={mainAgency}
								links={settings}
							/>
			 			</div>
					);			
		}
		
	}
}

export default LoginFlow;