import React, { Component } from 'react'
import {Drawer, List, ListItem, ListItemText, ListItemIcon} from '@material-ui/core'
import Config from "../../data/config.json";
import Language from "../../data/languages.json";

export default class DrawerLanguage extends Component {
    render() {
       //console.log(this.props) 
        return (
            <Drawer 
                anchor="bottom"
                variant="temporary"
                open={this.props.open}
                onClose={this.props.onClose ? this.props.onClose : null}
            >
                <List component="nav">
                    {Config.appLanguages.map((item, i) => (
                        <ListItem button onClick={() => this.props.handleChangeLocale(item)} key={i}>
                            {( Language[item].showImage ? 
                                <ListItemIcon style={{minWidth: '32px'}}>
                                    <img src={Language[item].image} alt={Language[item].name} height={'20px'}/>
                                </ListItemIcon>
                                : ''
                            )}
                            <ListItemText primary={Language[item].name} />
                        </ListItem>
                    ))}
                     {/* <ListItem button onClick={() => this.props.handleChangeLocale('pt-BR')}>
                       <ListItemIcon style={{minWidth: '32px'}}>
                            <img src={imgPt} alt="Português" height={'20px'}/>
                        </ListItemIcon> 
                        <ListItemText primary="Português" />
                    </ListItem>*/}
                </List>
            </Drawer>
        )
    }
}