import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {FirebaseAppProvider} from 'reactfire';
import config from './data/config.json'

const firebaseConfig = {
  apiKey: config[config.env].firebase.apiKey,
  authDomain: config[config.env].firebase.authDomain,
  projectId: config[config.env].firebase.projectId,
  storageBucket: config[config.env].firebase.storageBucket,
  messagingSenderId: config[config.env].firebase.messagingSenderId,
  appId: config[config.env].firebase.appId,
  measurementId: config[config.env].firebase.measurementId
};

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <Suspense fallback={<p>Loading...</p>}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Suspense>
  </FirebaseAppProvider>,
  document.getElementById('root')
);