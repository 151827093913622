import React, { useState, useEffect} from 'react';
import {Grid} from '@material-ui/core';
import BookingInfo from './BookingInfo';
import BoardingPass from './BoardingPass';
import VerifyInformation from './VerifyInformation';
import MyVoucher from './MyVoucher';
import Chat from '../common/Chat';
import { ReactNotifications } from 'react-notifications-component';
import ApiXHub from '../../../services/ApiXHub';
import Validation from '../../../services/Validation';
const BOOKING_INFO = 1;
const VERIFY_INFORMATION = 2;
const AIRPORT_TRANSFER_PASS = 3;
const MY_VOUCHER = 4;

const MyNexusWallet = (props) => {
	const {trip, wallet, config, bookings, trans, locale,styles, links,openChatLink} = props;
	const [step, setStep] = useState(1);
	const [lineSelected, setLineSelected] = useState(null);

	useEffect(() => {
		if(bookings !== null)
		{
		  // console.log(locale);
			var query = window.location.search;
			var generate = (query.includes("generate=true") ? true : false);

			if(generate && lineSelected === null)
			{
				let locator = bookings.booking.booking_code;
				let booking = bookings.bookings.find(booking => (booking.locator === locator));
				let line = booking.lines.find(li => (li.is_schedule_grouped && li.direction === 'IN'));

				//console.log("prueba");
				if(line !== undefined) {
					setLineSelected(line);
					if(line.have_atp){
						if(line.have_trf)
						{
							handleChangeStep(AIRPORT_TRANSFER_PASS);
						}
					} else {
						handleChangeStep(VERIFY_INFORMATION);
					}
					// console.log(line);
				}
			}
			// if(lineSelected !== null)
			// {
			// 	let locator = bookings.booking.booking_code;
			// 	let booking = bookings.bookings.find(booking => (booking.locator === locator));
			// 	let line = booking.lines.find(li => (li.is_schedule_grouped && li.direction === 'IN'));

			// 	// line.have_atp = false;
			// 	setLineSelected(line);
			// 	// console.log(line);
			// }
		}
	}, [bookings])

	//useEffect(() => {}, [lineSelected])

	const handleOpenCheckin = () => {
		setLineSelected(null);
		setStep(BOOKING_INFO);
	}

	const handleClickBoarding = (line) => e => {
		//line.code = (booking.agency_ref ? booking.agency_ref : booking.booking_code);
		if(line.service_type === "TRF")
		{
			if(line.direction === 'IN' || line.direction === 'OUT')
			{
				setLineSelected(line);
				if(line.is_schedule_grouped)
				{
					// setOpenCheckin(true);
					if(line.have_atp)
					{
						handleChangeStep(AIRPORT_TRANSFER_PASS);
					} else {
						handleChangeStep(VERIFY_INFORMATION);
					}
				} else {
					// showNotification(trans.validations.RULE1005, trans.nw_notification_title);
					Validation.showNotification(links.validations.RULE1004, links.translations.nw_notification_title);
				}
			}
		} 
		if(line.service_type === "EXC")
		{
			setLineSelected(line);
			// setOpenCheckin(true);
			handleChangeStep(MY_VOUCHER);
		}
		//console.log(line);
	}

	const handleDownload = (line) => async () => {
		//console.log(locale);
		let request = {
			code: line.locator,
			name: bookings.client.name+" "+bookings.client.lastname,
			lang: locale,
			line: line.line_id,
			brand_color:links.company_color,
			
		};

		if(line.service_type === "TRF")
		{
			request.direction = line.direction;
			request.banner_id=trip.fields.bannerBoardingPass.sys.id;
		}

		else if(line.service_type === "EXC")
		{
			request.banner_id=trip.fields.bannerMyVoucher.sys.id;
		}

		request = Validation.getAirportTransferPass(request);
		//console.log(request);
		if(request)
		{
			let response = await ApiXHub.getAirportTransferPass(request);
			if(response.data.is_valid)
			{
				// console.log(response.data.url);
				window.open(response.data.url, '_blank ');
			} else {
				Validation.showNotification(links.validations.RULE1005, links.translations.nw_notification_title);
			}
		}
	}

	const handleChangeStep = async (num) => {
		await setStep(num);
	}

	const handleConfirmInfo = async (line) => {
		// const helpers = new Helpers();
		// const locale = (helpers.existsLangCookie() ? helpers.getLangCookie() : helpers.getLangNav());
		let XhubSession = localStorage.getItem('xhub-session');
		let request = {
			locator: line.locator,
			name: bookings.client.name + " " + bookings.client.lastname,
			language: locale,
			line: line.line_id,
			brand_color:links.company_color,
			banner_id:trip.fields.bannerBoardingPass.sys.id,
		};
		//console.log(request);
		request = Validation.addAirportTransferPass(request);
		// console.log(request);
		if(request)
		{
			let response = await ApiXHub.addAirportTransferPass(request);
			if(response.data.is_valid)
			{
				// console.log(response.data.url);
				window.open(response.data.url, '_blank ');
				handleChangeStep(AIRPORT_TRANSFER_PASS);
			} else {
				Validation.showNotification(links.validations.RULE1005, links.translations.nw_notification_title); // trans.validations.RULE1005
			}
		}

		let rBookings = {
			token: XhubSession,
			code: line.locator,
			lang: locale
		}
		// console.log(rBookings);
		rBookings = Validation.getBookings(rBookings);
		// console.log(rBookings);
		if(rBookings)
		{
			let reBookings = await ApiXHub.getBookings(rBookings);
			if(reBookings.data.is_valid)
			{
				// console.log(response.data);
				await props.handleBookings(reBookings.data);
				// console.log(line);
				let booking = bookings.bookings.find(booking => (booking.locator === line.locator));
				let lineSel = booking.lines.find(li => (li.line_id === line.line_id));
				setLineSelected(lineSel);
			}
		}
	}

	const showDigitalPassport = (link = null) => (e) => {
		if(link !== null)
		{
			window.open(link, '_blank ');
		} else {
			Validation.showNotification(links.validations.RULE1006, links.translations.nw_notification_title,  "danger", "top-left");
		}
	};
	const showDigitalBrochure = (brochure) => (e) => {
		if( config.digital_brochure!==null)
		{
			window.open(brochure, '_blank ');
		}
		else if(config.digital_brochure === null)
		{
			Validation.showNotification(links.validations.RULE1009, links.translations.nw_notification_title,  "danger", "top-left");
		}
		 else {
			Validation.showNotification(links.validations.RULE1009, links.translations.nw_notification_title,  "danger", "top-left");
		}
	};

	if(trip !== null && wallet !== null && config !== null && bookings !== null)
	{
		//console.log(bookings);
		switch(step) {
			case BOOKING_INFO: 
				return (
					<Grid container>
						<ReactNotifications />
						<BookingInfo 
							trip={trip}
							wallet={wallet}
							config={config}
							bookingInfo={bookings}
							trans={trans}
							locale={locale}
							handleClickBoarding={handleClickBoarding}
							showDigitalPassport={showDigitalPassport}
							showDigitalBrochure={showDigitalBrochure}
							styles={styles}
							links={links}

						/>
					</Grid>
				);
			case VERIFY_INFORMATION: 
				return (
					<Grid container>
						<ReactNotifications />
						<VerifyInformation 
							trip={trip}
							trans={trans}
							booking={lineSelected}
							handleConfirmInfo={handleConfirmInfo}
							handleOpenCheckin={handleOpenCheckin}
							styles={styles}
							links={links}
							openChatLink={openChatLink}
						/>
					</Grid>
				);
			case AIRPORT_TRANSFER_PASS: 
				return (
					<Grid container>
						<ReactNotifications />
						<BoardingPass 
							trip={trip}
							bookingInfo={bookings}
							trans={trans}
							booking={lineSelected}
							handleDownload={handleDownload}
							handleOpenCheckin={handleOpenCheckin}
							styles={styles}
							links={links}
						/>
					</Grid>
				);
			case MY_VOUCHER: 
				return (
					<Grid container>
						<ReactNotifications />
						<MyVoucher 
							trip={trip}
							bookingInfo={bookings}
							trans={trans}
							booking={lineSelected}
							handleConfirmInfo={handleConfirmInfo}
							handleOpenCheckin={handleOpenCheckin}
							handleDownload={handleDownload}
							styles={styles}
							links={links}
						/>
					</Grid>
				);
			default:
				return (<div></div>);
		}
	}
	return <div></div>
}

export default MyNexusWallet;