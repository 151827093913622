import React, { Component } from 'react';
import { Checkbox} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Looks1Icon from '@material-ui/icons/LooksOne';
import Looks2Icon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';
import Looks4Icon from '@material-ui/icons/Looks4';
import Looks5Icon from '@material-ui/icons/Looks5';
import Looks6Icon from '@material-ui/icons/Looks6';

 
class ContactItem extends Component{
	constructor (props) {
		super(props)
		this.state= {
			number: 9,
			availavility: props.item.availavility,
		}
	}

	handleChangeCheck = async (e) => {
		e.persist();
		await this.setState({
			...this.state,
			availavility: !this.state.availavility
		})
		this.props.handleChange(e);
	}

	handleChange = async (value, data, event, formattedValue) => {
		if(typeof value === 'object' && value !== null)
		{
			this.props.handleChange(value)
			// console.log(value.target.value);
		} else {
			if(typeof event === 'object' && event !== null) {
				if(event._targetInst !== null) {
					// 	console.log("es nulo");
					// }else{
					this.props.handleChange(event)
					// console.log(event.target.value);
				}
			}
			// console.log(event);
		}
	}
	
	numberDetect = (num) =>{
		this.setState({
			...this.state,
			number : num
		})
	}

	selectIcon = (number) => {
		switch (number) {
			case 1:
				return <Looks1Icon />;
			case 2:
				return <Looks2Icon />;
			case 3:
				return <Looks3Icon />;
			case 4:
				return <Looks4Icon />;
			case 5:
				return <Looks5Icon />;
			case 6:
				return <Looks6Icon />;
			default:
				return <CheckBoxIcon />;
		}
	}
	
	render(){
		return (
			<div className={this.props.userStyles.inputcontactinfolabel}>
				<div className={"check-contact-info"}>
					<Checkbox 
						checked={this.state.availavility} 
						name={"check_" + this.props.item.tag} 
						onChange={this.handleChangeCheck} 
						value={this.props.item.tag} 
						style={{
							color: this.props.links.company_color
						  }}
						required={this.props.item.required}
						disabled={this.props.item.required}
					/>
					<img src={this.props.item.thumb} alt={this.props.item.name[this.props.locale]}/>
				</div>
				<div className="input-contact-info">
					{this.props.item.type !== "number" ? <label  htmlFor={this.props.item.tag} >{ this.props.item.name[this.props.locale] }</label> : "" }
					{
						this.props.item.type === "number" ? 
							(this.props.item.onlyCountries !== undefined ? 
								<PhoneInput  
									placeholder=""
									defaultMask={"(...) ...-....-...."} 
									alwaysDefaultMask={true}
									onlyCountries={this.props.item.onlyCountries}
									country={this.props.item.onlyCountries[0]}
									enableSearch={true}
									disableSearchIcon={true}
									specialLabel={this.props.item.name[this.props.locale]}
									disabled={!this.state.availavility}
									onChange={this.handleChange}
									onClick={this.handleChange}
									containerClass={this.state.availavility ? "" : "disabled"}
									inputProps={{
										name: this.props.item.tag,
										required: this.state.availavility,
									}}
									
								/> :
								<PhoneInput 
									placeholder=""
									defaultMask={"(...) ...-....-...."} 
									alwaysDefaultMask={true}
									preferredCountries={['ca','us','mx','br']}
									enableSearch={true}
									disableSearchIcon={true}
									specialLabel={this.props.item.name[this.props.locale]}
									disabled={!this.state.availavility}
									onChange={this.handleChange}
									onClick={this.handleChange}
									containerClass={this.state.availavility ? "" : "disabled"}
									inputProps={{
										name: this.props.item.tag,
										required: this.state.availavility,
									}}
								/> 
							)
						:
						<input 
							type={this.props.item.type}
							min={this.props.item.min}
							maxLength={this.props.item.max}
							name={this.props.item.tag}
							onChange={this.handleChange}
							disabled={!this.state.availavility}
							className={this.state.availavility? "" : "disabled"}
						/>
					}
					
				</div>
			</div>		
			
		);
	}
}
export default  (ContactItem);