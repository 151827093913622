import '../../../scss/GroupsFlow.scss';
import React, { Component } from 'react';
//import ContactForm from '../contact-form/ContactForm';
import ContactForm from './ContactForm';
import SelectArrival from '../groups-flow/SelectArrival';
import SelectDeparture from '../groups-flow/SelectDeparture';
import Confirmation from '../groups-flow/Confirmation';
import ApiXHub from '../../../services/ApiXHub';
import { Store } from 'react-notifications-component';
import Cookies from 'universal-cookie';
import Encrypt from '../../libraries/SessionEncrypt';
import Validation from '../../../services/Validation';
import Helpers from "../../libraries/helpers";
import trans from "../../../data/translation.json"
import backrgoundImage from"../../../images/01-login-background-1024x1080.jpg";
import {  getContentfulAsset} from '../../../services/themeOptions'
const cookies = new Cookies();
const helpers = new Helpers();

class ContactFlow extends Component {
	constructor (props) {
		super(props)
		this.state= {
			locale: (helpers.existsLangCookie() ? helpers.getLangCookie() : helpers.getLangNav()),
			loader: false,
			step: 1,
			arrival: '',
			departure: '',
			locator: null,
			booking: null,
			mainAgency: null,
			settings:null,
			name: '',
			lastname: ''
		}
	}

	// notification
	notifyDanger = (message) => {
		Store.addNotification({
			title: "Error!",
			message: message,
			type: "danger",
			insert: "top",
			container: "top-center",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 2000,
				onScreen: true
			}




		});
	}

	// Proceed to next step
	nextStep = ()  => {
		const {step, arrival, departure} = this.state;
		let apply = false;

		switch(step) {
			case 1: 
				if(arrival){
					apply = true;
				} else{
					this.notifyDanger("No selecciono nada");
				}
				break;
			case 2: 
				if(departure){
					apply = true;
				} else{
					this.notifyDanger("No selecciono nada");
				}
				break;
			case 3: 
				if(arrival && departure){
					apply = true;
				} else{
					this.notifyDanger("No selecciono nada");
				}
				break;
			default:
				apply = false;
				break;
		}


		if(apply) {
			this.setState({
				...this.state,
				step: step + 1
			});
		}
	}

	// Proceed to prev step
	prevStep = ()  => {
		const { step } = this.state;
		this.setState({
			...this.state,
			step: step - 1
		});
	}

	// Handle fields change
	handleChange = (input, value) => e => {
		// console.log(input, value);
		if(value === 'non')
		{
			this.setState({
				...this.state,
				[input]: value
			});
		} else {
			this.setState({
				...this.state,
				[input]: value.line_id,
				// name: value.paxes[0].name,
				// lastname: value.paxes[0].lastname
			});
		}
	}

	// Redirect to Contact
	goToContact = ()  => {
		let request = {
			token: "TOKENTEST",
			locator: this.state.booking.locator,
			arrival: this.state.arrival,
			departure: this.state.departure
		};
		// console.log(request);
		request = Validation.addLines(request);
		// console.log(request);
		if(request)
		{
			this.setState({
				...this.state,
				step: 4
			});
		}
	}

	// Redirect to Home
	goToHome = ()  => {
		window.location = '/home';
	}

	setLoader = async (status) => {
		await this.setState({
			...this.state,
			loader: status
		});
	}

	setStep = (step) => {
		this.setState({
			...this.state,
			step: step
		});
	}

	setUserInfo = async (locator) => {
		await this.setState({
			...this.state,
			locator: locator
		})
	}
	
	setBooking = async (booking) => {
		await this.setState({
			...this.state,
			booking: booking
		})
	}

	getBooking = async (request) => {
		this.setLoader(true);
		request = Validation.getBooking(request);

		if(request)
		{
			//console.log(request);
			let response = await ApiXHub.getBooking(request);
			//console.log(response);

			if(response.data.is_valid)
			{
				// "is_dir_in": true,
				// "is_dir_out": true,
				// if(response.data.lines_trf_in > 1 && response.data.lines_trf_out > 1) {
				// 	this.setStep(1);
				// } else if(response.data.lines_trf_in > 1 && !(response.data.lines_trf_out > 1)) {
				// 	this.setStep(1);
				// } else if(!(response.data.lines_trf_in > 1) && response.data.lines_trf_out > 1) {
				// 	this.setStep(2);
				// }
				this.setStep(1);
				this.setBooking(response.data);
				//console.log(response.data);
			}
		}
		this.setLoader(false);
	}
	getMainAgency =  async () =>{
		
		let response =   await getContentfulAsset(this.state.locale)
	       //console.log(response)
		   let value="2"
		    let Agency = {
				simple_name: response.name,
				hostname: response.hostname,
				text: response.companyName,
				link: response.deeplink,
				company_color: response.companyColor,
				primary_color: response.primaryColor,
				secondary_color: response.secondaryColor,
				 company_label: response.logoLeft,
				 xhub_label: response.logoRight,
				 background_image:response.imgBackgroundLogin,
				 image_call:response.imgCall,
				 image_chat:response.imgChat,
				 translations:response.translations,
				 validations:response.validations,
				 valued:value==="1"?'container-chat2':'container-chat' 
				// company_label_navbar: urlXhubLogoNavbar
			}
			Agency.primary_color="#"+Agency.primary_color
			Agency.secondary_color="#"+Agency.secondary_color
			Agency.company_color="#"+Agency.company_color
			let style={
				/*Login Form*/
					
				btn: {
					display: 'block',
					width: '300px',
					height: '40px',
					borderRadius: '10px',
					backgroundColor: Agency.primary_color,
					border: 'none',
					fontFamily: 'Roboto',
					margin: '10px 0',
					color: Agency.secondary_color,
					fontSize: '16px',
					fontWeight: '600',
					cursor: 'pointer',
					boxShadow: 'none',
					appearance: 'none',
					textTransform: 'capitalize',
					'&:hover': {
						backgroundColor: Agency.secondary_color,
						color: Agency.primary_color
					}
					},
					imgleft:{
						position: 'absolute',
						top: '0',
						left: 'calc(50% - 130px)',
						width: 'auto',
						height: 'auto',
						marginRight: '20px',
						 
		
				
					},
					imgright:{
						position: 'absolute',
						top: '0',
						left: 'calc(50% - 130px)',
						width: 'auto',
						height: 'auto',
						marginLeft: '165px'
				
					},
				
					containerLogin:{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh',
						backgroundImage: 'url('+backrgoundImage+')',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						marginBottom: '-48px',
					'@media (min-width: 1400px)' :   
						{
						width: '30%',
						borderRadius: '20px',
						position: 'relative',
						flex: 'none',
						display: 'flex',
						flexDirection: 'column',
						flexWrap: 'nowrap',
						alignContent: 'center',
						justifyContent: 'center',
						alignItems: 'center'
						}
				
				
					
					},
					labellogin: {
						//display: 'block',
						fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif",
						color: '#505050',
						fontWeight: 'bold',
						fontSize: '16px',
						textAlign: 'center'
					},
					inputlogin:{
					
							display: 'block',
							width: 'calc(100% - 14px)',
							border: 'none',
							borderRadius: '5px',
							marginTop: '6px',
							marginBottom: '10px',
							height:'30px',
							padding: '2px 6px',
							textAlign: 'center',
							backgroundColor: Agency.secondary_color,
							color:  Agency.primary_color,
							opacity:'.6',
							fontSize: '16px',
							Width: '300px',
							'&:focus-visible': {
								outline: 'none'
							}
					},
					/*Login Form*/
				/*Navbar Form*/
				
				appBar: {
					backgroundColor: Agency.secondary_color,
					color:  Agency.primary_color,
					},
				containerLogo: {
					flexGrow: 1,
					height: '72px',
				},
				containerInfo:{
					flexGrow: 11,
					height: 'auto'
				},
				logo: {
					height: 'auto',
					marginLeft: '-8px',
					marginTop: '6px',
				},
				lang: {
					height: '20px',
				},
				menuButton: {
					marginRight: 'spacing(1)',
				},
				tags: {
					display: 'block',
					fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					marginLeft: '10px',
					color: '#747474'
				},
				username: {
					marginTop: '6px',
					fontSize: '11px',
					fontWeight: 'bold',
					color: Agency.primary_color
				},
				booking: {
					fontSize: '11px',
				},
				zones: {
					fontSize: '11px',
				},
				products: {
					fontSize: '11px',
				},
				date: {
					fontSize: '11px',
					fontWeight: 'bold',
					color: '#000'
				},
				icon1NavBar:
				{position: "relative", 
				fontWeight: 'bold', 
				fontSize: '12px'},
				icon2NavBar:{
					position: "relative",
					top: "8px",
					marginTop: "0px",
					left: "4px",
					width: "0",
					height: "0",
					borderLeft: "3px solid transparent",
					borderRight: "3px solid transparent",
					borderTop: "4px solid"+ Agency.primary_color,
				},
					/*Navbar Form*/
					/*MyExcursion*/ 
					cardItemEx:{
						width: '90%',
						margin: '0 auto',
						padding: '0 10px'
					},
					marginCardEx:{
						marginBottom: '20px'
					},
					titleBoxEx: {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: '8px',
						marginBottom: '8px',
					},
					titleImgEx:{
						display: 'inline-block',
						width: '25px',
					},
					titleItemEx:{
						margin: 0,
						color: Agency.company_color,
						textAlign: 'center',
						fontSize: '20px',
						fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					},
					labelItemEx: {
						fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
						fontSize: '16px',
						fontWeight: 'bold',
						color: '#7A7A78',
						padding: '5px'
					},
					inputItemEx:{
						backgroundColor: '#F4F4F4',
						margin: '5px',
						fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
						fontSize: '12px',
						color: '#575756',
						padding: '7px',
						borderRadius: '4px',
						textAlign: 'center',
						minHeight: '14px',
						// overflowX: 'scroll'
					},
					btnInfoEx: {
						color: '#fff',
						border: '1px solid'+ Agency.primary_color,
						margin: 'auto',
						minWidth: '90%',
						borderRadius: '6px',
						backgroundColor: Agency.primary_color,
						appearance: 'auto',
						textRendering: 'auto',
						letterSpacing: 'normal',
						wordSpacing: 'normal',
						textTransform: 'none',
						textIndent: '0px',
						textShadow: 'none',
						display: 'inline-block',
						textAlign: 'center',
						alignItems: 'flex-start',
						cursor: 'default',
						boxSizing: 'border-box',
						font: '400 13.333px Roboto',
						fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
						padding: '4px 6px',
						textDecoration: 'none',
					},
					/*MyExcursion*/ 
					/*MyTransfer*/ 
					cardItemTr:{
						width: '90%',
						margin: '0 auto',
						padding: '0 10px'
					},
					marginCardTr:{
						marginBottom: '20px'
					},
					titleBoxTr: {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: '8px',
						marginBottom: '8px',
					},
					titleImgTr:{
						display: 'inline-block',
						width: '25px',
					},
					titleItemTr:{
						margin: 0,
						color: Agency.company_color,
						textAlign: 'center',
						fontSize: '20px',
						fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					},
					labelItemTr: {
						fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
						fontSize: '16px',
						fontWeight: 'bold',
						color: '#7A7A78',
						padding: '5px'
					},
					inputItemTr:{
						backgroundColor: '#F4F4F4',
						margin: '5px',
						fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
						fontSize: '12px',
						color: '#575756',
						padding: '7px',
						borderRadius: '4px',
						textAlign: 'center',
						minHeight: '14px',
						// overflowX: 'scroll'
					},
					btnInfoTr: {
						color: Agency.secondary_color,
						border: '1px solid'+ Agency.primary_color,
						margin: 'auto',
						minWidth: '90%',
						borderRadius: '6px',
						backgroundColor:  Agency.primary_color,
						appearance: 'auto',
						textRendering: 'auto',
						letterSpacing: 'normal',
						wordSpacing: 'normal',
						textTransform: 'none',
						textIndent: '0px',
						textShadow: 'none',
						display: 'inline-block',
						textAlign: 'center',
						alignItems: 'flex-start',
						cursor: 'default',
						boxSizing: 'border-box',
						font: '400 13.333px Roboto',
						fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
						padding: '4px 6px',
						textDecoration: 'none',
					},
					/*MyTransfer*/ 
					/*NotFound*/
					cardItemNf:{
						width: '90%',
						margin: '0 auto',
						padding: '12px 10px'
					},
					marginCardNf:{
						marginTop: '-20px',
						marginBottom: '20px',
					},
					titleBoxNf: {
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: '8px',
						marginBottom: '8px',
						textAlign: 'center',
					},
					titleImgNf:{
						display: 'block',
						width: '35px',
						margin: 'auto',
					},
					titleItemNf:{
						margin: '0 auto',
						color: Agency.company_color,
						textAlign: 'center',
						fontSize: '20px',
						fontFamily: 'Roboto',
						width: '200px',
						fontWeight: '500',
					},
					textItemNf:{
						margin: '0 auto',
						color: '#505050',
						textAlign: 'center',
						fontSize: '16px',
						fontFamily: 'Roboto',
						width: 'auto',
						fontWeight: '500',
					},
					btnInfoNf: {
						color: Agency.secondary_color,
						border: '1px solid'+ Agency.primary_color,
						height: '30px',
						margin: 'auto',
						minWidth: '50%',
						borderRadius: '6px',
						backgroundColor: Agency.primary_color,
						appearance: 'auto',
						textRendering: 'auto',
						letterSpacing: 'normal',
						wordSpacing: 'normal',
						textTransform: 'none',
						textIndent: '0px',
						textShadow: 'none',
						display: 'inline-block',
						textAlign: 'center',
						alignItems: 'flex-start',
						cursor: 'default',
						boxSizing: 'border-box',
						font: '400 13.333px Roboto',
						padding: '1px 6px',
					},
					/*NotFound*/
					/*Boarding pass */
					imgQRBp:{
						width: '150px',
						height: '150px',
						margin: 'auto',
						border: '1px solid '+ Agency.primary_color
					},
					bookingCodeBp:{
						color: Agency.company_color,
						fontSize: '14px',
						fontWeight: 'bold',
					},
					tagLabelBp:{
						color: '#575756',
						fontSize: '14px',
					},
					tagInputBp:{
						color: '#505050',
						fontSize: '14px',
						fontWeight: 'bold',
					},
					boxInfoNotCorrectBp:{
						textAlign: 'center'
					},
					btnConfirmInfoBp:{
						color: Agency.secondary_color,
						border: '1px solid'+ Agency.primary_color,
						margin: '0 auto',
						minWidth: '50%',
						borderRadius: '6px',
						backgroundColor: Agency.primary_color,
						appearance: 'auto',
						textRendering: 'auto',
						letterSpacing: 'normal',
						wordSpacing: 'normal',
						textTransform: 'none',
						textIndent: '0px',
						textShadow: 'none',
						display: 'inline-block',
						textAlign: 'center',
						alignItems: 'flex-start',
						cursor: 'default',
						boxSizing: 'border-box',
						font: '400 13.333px Roboto',
						padding: '4px 6px',
						textDecoration: 'none',
						"&:hover, &:focus, &:active": {
							color: Agency.secondary_color,
							backgroundColor:  Agency.primary_color,
						}
					},
					infoNotCorrectBp:{
						padding: '0px 22px',
					color: '#7A7A78',
					fontSize: '16px',
					fontFamily: 'Roboto',
					fontWeight: 400
					},
					marginBottomBp: {
						marginBottom: '20px',
						marginTop: '-52px',
					},
					backLinkBp:{
						position: 'relative',
					top: '74px',
					backgroundColor: 'rgba(255,255,255, 0.5)'
					},
					/*Boarding pass*/
					/*booking Info*/
					headingBi: {
						fontSize: 'typography.pxToRem(15)',
						fontWeight: 'typography.fontWeightBold',
							color: '#565654',
					},
						imgIconBi: {
							width: '20px',
							height: '20px',
							marginRight: '8px',
						},
						btnConfirmInfoBi:{
							color: Agency.secondary_color,
							border: '1px solid '+ Agency.primary_color,
							margin: '0 0 0 auto',
							minWidth: '50%',
							borderRadius: '6px',
							backgroundColor: Agency.primary_color,
							appearance: 'auto',
							textTransform: 'none',
							cursor: 'default',
							boxSizing: 'border-box',
							font: '400 13.333px Roboto',
							padding: '4px 6px',
							"&:hover, &:focus, &:active": {
								color: Agency.secondary_color,
								backgroundColor: Agency.primary_color,
							}
						},
						accordionDetailsBi:{
							padding: 0,
						flexDirection: 'column',
						backgroundColor: '#EAEAEA',
						},
						itemListBi:{
							padding: '10px 16px',
						borderTop: '1px solid #D3D3D3',
						borderBottom: '1px solid #D3D3D3',
							justifyContent: 'start',
						fontWeight: '400',
						textTransform: 'capitalize',
						fontSize: '16px',
						borderRadius: '0px'
						},
						buttonBi: {
							position: 'fixed',
							top: '80px',
							left: '4px',
							zIndex: 100,
							backgroundColor: 'rgba(243,243,243,0.8)',
							boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
						},
						btnIconBi:{
							color: '#575756'
						},
					/*booking Info*/
					/*my voucher*/ 
					imgQRMb:{
						width: '150px',
						height: '150px',
						margin: 'auto',
						border: '1px solid'+ Agency.primary_color
					},
					bookingCodeMb:{
						color:  Agency.primary_color,
						fontSize: '14px',
						fontWeight: 'bold',
					},
					tagLabelMb:{
						color: '#575756',
						fontSize: '14px',
					},
					tagInputMb:{
						color: '#505050',
						fontSize: '14px',
						fontWeight: 'bold',
					},
					tagInputOrangeMb:{
						color: Agency.primary_color,
						fontSize: '14px',
						fontWeight: 'bold',
					},
					boxInfoNotCorrectMb:{
						textAlign: 'center'
					},
					btnConfirmInfoMb:{
						color: '#fff',
						border: '1px solid '+ Agency.primary_color,
						margin: '0 auto',
						minWidth: '50%',
						borderRadius: '6px',
						backgroundColor: Agency.primary_color,
						appearance: 'auto',
						textRendering: 'auto',
						letterSpacing: 'normal',
						wordSpacing: 'normal',
						textTransform: 'none',
						textIndent: '0px',
						textShadow: 'none',
						display: 'inline-block',
						textAlign: 'center',
						alignItems: 'flex-start',
						cursor: 'default',
						boxSizing: 'border-box',
						font: '400 13.333px Roboto',
						padding: '4px 6px',
						textDecoration: 'none',
						"&:hover, &:focus, &:active": {
							color: Agency.secondary_color,
							backgroundColor: Agency.primary_color,
						}
					},
					infoNotCorrectMb:{
						padding: '0px 22px',
					color: '#7A7A78',
					fontSize: '16px',
					fontFamily: 'Roboto',
					fontWeight: 400
					},
					marginBottomMb: {
						marginBottom: '10px',
						marginTop: '-52px',
					},
					backLinkMb:{
						position: 'relative',
					top: '74px',
					backgroundColor: 'rgba(255,255,255, 0.5)'
					},
					/*my voucher */
				/*Verify Info */
				cardItemVi:{
					width: '90%',
					margin: '0 auto',
					padding: '0 10px'
				},
				marginCardVi:{
					marginBottom: '20px'
				},
				titleBoxVi: {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: '8px',
					marginBottom: '8px',
				},
				titleImgVi:{
					display: 'inline-block',
					width: '25px',
				},
				titleItemVi:{
					margin: 0,
					color: Agency.company_color,
					textAlign: 'center',
					fontSize: '20px',
					fontFamily: 'Roboto',
				},
				labelItemVi: {
					fontFamily: 'Roboto',
					fontSize: '16px',
					fontWeight: 'bold',
					color: '#7A7A78',
					padding: '5px'
				},
				inputItemVi:{
					backgroundColor: '#F4F4F4',
					margin: '5px',
					fontFamily: 'Roboto',
					fontSize: '12px',
					color: '#575756',
					padding: '7px',
					borderRadius: '4px',
					textAlign: 'center',
					minHeight: '14px',
					// overflowX: 'scroll'
				},
				btnInfoVi: {
					color: '#fff',
					border: '1px solid'+ Agency.primary_color,
					margin: 'auto',
					minWidth: '50%',
					borderRadius: '6px',
					backgroundColor: Agency.primary_color,
					appearance: 'auto',
					textRendering: 'auto',
					letterSpacing: 'normal',
					wordSpacing: 'normal',
					textTransform: 'none',
					textIndent: '0px',
					textShadow: 'none',
					display: 'inline-block',
					textAlign: 'center',
					alignItems: 'flex-start',
					cursor: 'default',
					boxSizing: 'border-box',
					font: '400 13.333px Roboto',
					padding: '4px 6px',
				},
				boxInfoNotCorrectVi:{
					textAlign: 'center'
				},
				btnConfirmInfoVi:{
					color: Agency.secondary_color,
					border: '1px solid '+ Agency.primary_color,
					margin: '0 auto',
					minWidth: '50%',
					borderRadius: '6px',
					backgroundColor: Agency.primary_color,
					appearance: 'auto',
					textRendering: 'auto',
					letterSpacing: 'normal',
					wordSpacing: 'normal',
					textTransform: 'none',
					textIndent: '0px',
					textShadow: 'none',
					display: 'inline-block',
					textAlign: 'center',
					alignItems: 'flex-start',
					cursor: 'default',
					boxSizing: 'border-box',
					font: '400 13.333px Roboto',
					padding: '4px 6px',
					"&:hover, &:focus, &:active": {
						color: Agency.secondary_color,
						backgroundColor: Agency.primary_color,
					}
				},
				infoNotCorrectVi:{
					padding: '0px 22px',
				color: '#7A7A78',
				fontSize: '16px',
				fontFamily: 'Roboto',
				fontWeight: 400
				},
				marginBottomVi: {
					marginBottom: '10px',
					marginTop: '-52px',
				},
				backLinkVi:{
					position: 'relative',
				top: '74px',
				backgroundColor: 'rgba(255,255,255, 0.5)'
				},
				/*Verify Info*/
				/*Home*/
				imageBannerHm: {
					width: '100%',
					height: 'auto',
				},
				btnInfoHm: {
					width: '100%',
					height: '54px',
					backgroundColor: Agency.primary_color,
					color: '#fff',
					border: '1px solid'+ Agency.primary_color,
					borderRadius: '6px'
				},
				btnInfoDisableHm: {
					width: '100%',
					height: '54px',
					backgroundColor: '#bfbfbf',
					color: '#fff',
					border: '1px solid #827a74',
					borderRadius: '6px'
				},
				/*Home */
				/*Myvoucher*/
				imgQRMv:{
					width: '150px',
					height: '150px',
					margin: 'auto',
					border: '1px solid'+ Agency.primary_color,
				},
				bookingCodeMv:{
					color:  Agency.primary_color,
					fontSize: '14px',
					fontWeight: 'bold',
				},
				tagLabelMv:{
					color: '#575756',
					fontSize: '14px',
				},
				tagInputMv:{
					color: '#505050',
					fontSize: '14px',
					fontWeight: 'bold',
				},
				tagInputOrangeMv:{
					color:  Agency.company_color,
					fontSize: '14px',
					fontWeight: 'bold',
				},
				boxInfoNotCorrectMv:{
					textAlign: 'center'
				},
				btnConfirmInfoMv:{
					color: '#fff',
					border: '1px solid'+ Agency.primary_color,
					margin: '0 auto',
					minWidth: '50%',
					borderRadius: '6px',
					backgroundColor: Agency.primary_color,
					appearance: 'auto',
					textRendering: 'auto',
					letterSpacing: 'normal',
					wordSpacing: 'normal',
					textTransform: 'none',
					textIndent: '0px',
					textShadow: 'none',
					display: 'inline-block',
					textAlign: 'center',
					alignItems: 'flex-start',
					cursor: 'default',
					boxSizing: 'border-box',
					font: '400 13.333px Roboto',
					padding: '4px 6px',
					"&:hover, &:focus, &:active": {
						color: Agency.secondary_color,
						backgroundColor: Agency.primary_color,
					},
					
				},
				infoNotCorrectMv:{
					padding: '0px 22px',
				color: '#7A7A78',
				fontSize: '16px',
				fontFamily: 'Roboto',
				fontWeight: 400
				},
				/*Myvoucher*/
				/*TrfArrival*/
				cardItemTa:{
					width: '90%',
					margin: '0 auto',
					padding: '0 10px'
				},
				marginCardTa:{
					marginBottom: '20px'
				},
				titleBoxTa: {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: '8px',
					marginBottom: '8px',
				},
				titleImgTa:{
					display: 'inline-block',
					width: '25px',
				},
				titleItemTa:{
					margin: 0,
					color: Agency.primary_color,
					textAlign: 'center',
					fontSize: '20px',
					fontFamily: 'Roboto',
				},
				labelItemTa: {
					fontFamily: 'Roboto',
					fontSize: '16px',
					fontWeight: 'bold',
					color: '#7A7A78',
					padding: '5px'
				},
				inputItemTa:{
					backgroundColor: '#F4F4F4',
					margin: '5px',
					fontFamily: 'Roboto',
					fontSize: '12px',
					color: '#575756',
					padding: '7px',
					borderRadius: '4px',
					textAlign: 'center',
					minHeight: '14px',
					// overflowX: 'scroll'
				},
				btnInfoTa: {
					color: Agency.secondary_color,
					border: '1px solid'+ Agency.primary_color,
					margin: 'auto',
					minWidth: '50%',
					borderRadius: '6px',
					backgroundColor: Agency.primary_color,
					appearance: 'auto',
					textRendering: 'auto',
					letterSpacing: 'normal',
					wordSpacing: 'normal',
					textTransform: 'none',
					textIndent: '0px',
					textShadow: 'none',
					display: 'inline-block',
					textAlign: 'center',
					alignItems: 'flex-start',
					cursor: 'default',
					boxSizing: 'border-box',
					font: '400 13.333px Roboto',
					padding: '4px 6px',
				},
				boxInfoNotCorrectViT:{
					textAlign: 'center'
				},
				btnConfirmInfoViT:{
					color: '#fff',
					border: '1px solid '+ Agency.primary_color,
					margin: '0 auto',
					minWidth: '50%',
					borderRadius: '6px',
					backgroundColor:Agency.primary_color,
					appearance: 'auto',
					textRendering: 'auto',
					letterSpacing: 'normal',
					wordSpacing: 'normal',
					textTransform: 'none',
					textIndent: '0px',
					textShadow: 'none',
					display: 'inline-block',
					textAlign: 'center',
					alignItems: 'flex-start',
					cursor: 'default',
					boxSizing: 'border-box',
					font: '400 13.333px Roboto',
					padding: '4px 6px',
					"&:hover, &:focus, &:active": {
						color:Agency.secondary_color,
						backgroundColor: Agency.primary_color,
					}
				},
				infoNotCorrectViT:{
					padding: '0px 22px',
				color: '#7A7A78',
				fontSize: '16px',
				fontFamily: 'Roboto',
				fontWeight: 400
				},
				/*TrfArrival */
				/*MyRep*/
				cardItemMr:{
					width: '90%',
					margin: '0 auto',
					padding: '0 10px'
				},
				marginCardMr:{
					marginBottom: '20px'
				},
				titleBoxMr: {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: '6px',
					marginBottom: '6px',
				},
				titleImgMr:{
					display: 'inline-block',
					width: '50px',
				},
				titleItemMr:{
					margin: 0,
					color: Agency.company_color,
					textAlign: 'center',
					fontSize: '20px',
					fontFamily: 'Roboto',
				},
				labelItemMr: {
					fontFamily: 'Roboto',
					fontSize: '16px',
					fontWeight: 'bold',
					color: '#7A7A78',
					padding: '5px'
				},
				labelHeaderMr: {
					fontFamily: 'Roboto',
					fontSize: '16px',
					fontWeight: 'bold',
					color: Agency.company_color,
					padding: '5px'
				},
				inputItemMr:{
					backgroundColor: '#F4F4F4',
					margin: '5px',
					fontFamily: 'Roboto',
					fontSize: '12px',
					color: '#575756',
					padding: '7px',
					borderRadius: '4px',
					textAlign: 'center',
					minHeight: '14px',
					// overflowX: 'scroll'
				},
				btnInfoMr: {
					color: '#fff',
					border: '1px solid'+ Agency.primary_color,
					margin: 'auto',
					minWidth: '50%',
					borderRadius: '6px',
					backgroundColor: Agency.primary_color,
					appearance: 'auto',
					textRendering: 'auto',
					letterSpacing: 'normal',
					wordSpacing: 'normal',
					textTransform: 'none',
					textIndent: '0px',
					textShadow: 'none',
					display: 'inline-block',
					textAlign: 'center',
					alignItems: 'flex-start',
					cursor: 'default',
					boxSizing: 'border-box',
					font: '400 13.333px Roboto',
					padding: '4px 6px',
				},
				/*MyRep */
				strongs:{
					color: Agency.company_color
				},
				textlocalexpert: {
					color: '#878787',
					fontSize: '20px',
					padding: '5px',
					margin: '30px auto 0',
					width: '300px',
					fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',
				},
				descriptionlocalexpert: {
					color: '#A9A9A9',
					fontSize: '16px',
					width: '300px',
					margin:' 0 auto',
					textAlign: 'justify'
				},
				inputcontactinfolabel: {
					color: Agency.company_color,
					marginLeft: '0',
					fontSize: '18px',
					display: 'block',
					textAlign: 'left',
					fontWeight: '500',
					width: '100%',
					alignItems: 'center',
					marginTop: '10px',
					'& .input-contact-info .react-tel-input .special-label':
					{
						color:Agency.company_color,
						display: 'block',
						top: '0',
						left: '0',
						width: '100%',
						fontSize: '18px',
						fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
						fontWeight: '500',
						textAlign: 'left'
				
					},
					
					
				},
				oneOptionContactForm:
				{
				
					color:Agency.primary_color,
					width: '300px',
					margin: '0 auto',
				
					'&  p':{
						fontSize: '12px',
						textAlign: 'justify',
						margin: '4px 2px'
					},
					
					
				},
				termsContactForm: {
					display: 'flex',
					width: '300px',
					margin: '0 auto'
				},
				buttonL :{
					width: '120px',
					height: '33px',
					backgroundColor: Agency.primary_color,
					border: '1px solid'+ Agency.primary_color,
					borderRadius: '5px',
					fontSize: '16px',
					color: Agency.primary_color,
					padding: '4px 10px',
					fontWeight: '500',
					marginTop: '12px'
				},
				/*Contact Form*/
				
				/*Share my experience*/
					myexpBtnSurvey :{
					width: 'auto',
					height: '18px',
					backgroundColor: Agency.primary_color,
					border: '1px solid'+ Agency.primary_color ,
					borderRadius: '5px',
					fontSize: '16px',
					color: Agency.secondary_color,
					padding: '4px 10px',
					fontWeight: '500',
					margin: '8px auto 0',
					textDecoration: 'none',
					display: 'block',
					maxWidth: '110px'
				},
					Thanku: {
					margin: '0 12px',
					textAlign: 'center',
					fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					color: '#878787',
						'& p':{
						fontSize: '16px',
						},
						'& strong': {
						color: Agency.company_color,
					}
				},
				
		
				
				/*Share my experience*/
				
				/*ContactUs */
				
				contactCardTitle:{
					display: 'block',
					fontFamily:' "Roboto", "Times New Roman", Times, serif',
					fontSize: '14px',
					fontWeight: 'bold',
					color:Agency.company_color,
					marginBottom: '5px',
				},
				
				titleSocial:{
					margin: '8px',
					textAlign: 'center',
					fontSize: '16px',
					color: Agency.company_color,
				},
				titleWeb:{
					margin: '-5px 0 8px 0',
					textAlign: 'center',
					fontSize: '20px',
					fontWeight: '300',
					color:Agency.company_color,
					fontFamily: 'Roboto',
					display: 'block',
				},
				/*Loader */
		     containerBackLoader:{
			inset: '0px',
			display: 'flex',
			zIndex: '1300',
			position: 'fixed',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: 'rgba(0, 0, 0, 0.5)'
		},
				containerLoader:{
					width: "64px",
					height: '64px',
					backgroundColor: Agency.secondary_color,
					borderRadius: '50%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					"& .MuiCircularProgress-colorPrimary":{
						color: Agency.primary_color
					}
				},

		btnLanguage:{
			fontSize: '15px',
			color: Agency.primary_color,
			fontFamily: 'Roboto',
			fontWeight: '400',
			position: 'absolute',
			top: '0px',
			right: '0px',
			width: 'auto',
			height: 'auto',
			backgroundColor: 'transparent',
			border: 'none',
			marginTop: '0',
			padding: '12px'
			
		},
		titleDepature:
		{
			marginTop: '30px',
			fontSize: '36px',
			color: Agency.primary_color,
			marginBlock: '0',
			marginLeft: '25px'
		},
		containerBookingLines:
		{

			padding: '0 20px',
			'& .MuiPaper-root.MuiCard-root.card-main.selected': {
				border: '2px solid'+Agency.primary_color
			},
			'& .MuiPaper-root.MuiCard-root.card-main .card-content .content-info .from::before':
			{

				border: '2px solid'+Agency.primary_color
			},
			'& .MuiPaper-root.MuiCard-root.card-main .card-content .content-info::before':
			{
				background:Agency.primary_color

			},
			'& .MuiPaper-root.MuiCard-root.card-main .card-content .content-info .to::before':
			{
				border: '2px solid'+Agency.primary_color
			}
		}, 	}

			this.setState({...this.state, mainAgency: style,settings: Agency})
			 //console.log(Agency)
			

			
				
	}

	componentWillMount() {   
		this.getMainAgency();
		  }

	componentDidMount() {
		const session = new Encrypt();
		let UserInfo = session.decryptUser(cookies);
		this.setUserInfo(UserInfo.locator);
		let lang = (helpers.existsLangCookie() ? helpers.getLangCookie() : helpers.getLangNav());
		lang = (Validation.isEmpty(UserInfo.language) ? (lang ? lang : 'en') : UserInfo.language);
		if(!UserInfo.is_groups)
		{
			window.location =  '/login?error=not-groups';
		}
		let request = {
			code: UserInfo.locator,
			lang: lang
		}
		
		this.getBooking(request);

		helpers.checkSession(false);
  }

	render () {
		const {step, arrival, departure, booking, loader, locale, name, lastname,mainAgency,settings} = this.state;
		const values = {arrival, departure};
		 //console.log(mainAgency)
		switch(step) {
			case 1: 
				return (
					<SelectArrival
						trans={trans[locale]}
						status={loader}
						nextStep={this.nextStep}
						handleChange={this.handleChange}
						values={values}
						booking={booking}
						agency={mainAgency}
						settings={settings}
					/>
				);
			case 2: 
				return (
					<SelectDeparture
						trans={trans[locale]}
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						handleChange={this.handleChange}
						values={values}
						booking={booking}
						agency={mainAgency}
						settings={settings}
					/>
				);
				case 3:
					return (
						<Confirmation
							trans={trans[locale]}
							nextStep={this.goToContact}
							prevStep={this.prevStep}
							handleChange={this.handleChange}
							values={values}
							booking={booking}
							agency={mainAgency}
							settings={settings}
						/>
					);
				case 4:
						return (
							<ContactForm
								trans={trans[locale]}
								nextStep={this.goToHome}
								prevStep={this.prevStep}
								handleChange={this.handleChange}
								values={values}
								booking={booking}
								agency={mainAgency}
								settings={settings}
								name={name}
								lastname={lastname}
							/>
						);
				default:
					return (
						<SelectArrival
							trans={trans[locale]}
							nextStep={this.nextStep}
							handleChange={this.handleChange}
							values={values}
							booking={booking}
							agency={mainAgency}
							settings={settings}
						/>
					);
		}
	}
}
export default (ContactFlow);