import React from 'react'
import {withRouter} from "react-router-dom";
import {Box, Grid} from '@material-ui/core'
import Chat from '../common/Chat';
import Transfer from './MyTransfer';
import Excursion from './MyExcursion';
import ReactGA from 'react-ga';
import componentDidMount from 'react'
// import Smooch from 'smooch'

const Smooch = require('smooch');

const MyTrip = (props) => {
	//console.log(props)
	const {images, trip, user, userInfo,styles,links,openChatLink} = props;

	if(images !== null && trip !== null && user !== null && userInfo !== null&&styles!==null&&links!==null)
	{
		document.title = trip.fields.title;
		//console.log(trip);
		return (
			<Grid container>
				<Grid item xs={12}>
					<Box mb={2}>
						<img src={trip.fields.banner.fields.file.url} alt={trip.fields.banner.fields.title} style={{width: '100%', height: 'auto'}}/>
					</Box>
				</Grid>

				{userInfo.bookings.map((booking) => (
					booking.lines !== null && booking.lines.length > 0 ? 
						booking.lines.map((line, i) => ( line !== null && line.service_type === "TRF" ?
							<Transfer key={i} transfer={line}  openChatLink={openChatLink} styles={styles} links={links} trip={trip}  />
						: (line !== null && line.service_type === "EXC" ? <Excursion key={i} excursion={line} openChatLink={openChatLink} trip={trip} user={userInfo} styles={styles} links={links} /> : '')))
					: '')
				)}
			</Grid>
		)
	}
	return <div></div>
}

export default withRouter(MyTrip);