const initPac = 'iad';
const maxSplit = 4000;
class SessionEncrypt {
	encrypt (value)
	{
		let enc = initPac + btoa(unescape(encodeURIComponent(JSON.stringify(value))));
		// llamar funcion divisora W85KG3
		// console.log(enc.length);
		return enc;
	}

	decrypt (cookies)
	{
		let i = 0;
		let user = '';
		let items = cookies.get('user_items');
		do {
			user = user + cookies.get('user_' + i);
			i = i + 1;
		  } while (i < items);
		let parc = JSON.parse(atob(user.substr(initPac.length, user.length)));
		return parc;
	}

	decryptUser (cookies)
	{
		let user = cookies.get('xhub-info');
		 let parc = JSON.parse(atob(user.substr(initPac.length, user.length)));
		// let parc = JSON.parse(Buffer.from(user.substr(initPac.length, user.length), 'base64'));
		return parc;
	}

	splitEncrypt(value)
	{
		let i = 0;
		let encArr = [];
		let caracteres = value.length;
		do {
			caracteres = caracteres - maxSplit;
			encArr.push(value.substr(i, maxSplit));
			i = i + maxSplit;
		  } while (i < value.length);
		return encArr;
	}
}

export default SessionEncrypt;