import React  from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {Switch, Route} from "react-router-dom";
import Analytics from 'react-router-ga';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './components/partials/themeConfig.js';
import Container from './components/partials/Container';
import LoginFlow from './components/sections/login-flow/LoginFlow';
import ContactForm from './components/sections/contact-flow/ContactForm';
import ContactFlow from './components/sections/contact-flow/ContactFlow';
import GroupsFlow from './components/sections/groups-flow/GroupsFlow';
import ConfirmTRFBooking from './components/sections/ConfirmTRFBooking/ConfirmTRFBooking';
import Config from "../src/data/config.json";
class App extends React.Component {



	render () {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Analytics id={Config[Config.env].keyGA}>
            <Switch>
              <Route path='/login' component={LoginFlow}/>
              <Route path='/ConfirmTRFBooking' component={ConfirmTRFBooking}/>
              <Route path='/contact-form' component={ContactForm}/>
              <Route path='/groups' component={GroupsFlow}/>
              <Route path='/contact-flow' component={ContactFlow}/>
              <Route path='/' component={Container}/>
            </Switch>
          </Analytics>
        </Router>
      </ThemeProvider>
    );
  }
}

export default  (App);
