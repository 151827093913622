import React, {useState} from 'react';
import { ReactNotifications } from 'react-notifications-component';
import userImg from '../../../images/01-login-icon-user-70x70.png';
import {withStyles,makeStyles, Tooltip, ClickAwayListener, CircularProgress, Button} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Chat from '../common/Chat';
const LightTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: 'rgba(255, 255, 255, 0.8)',
	  color: 'rgba(0, 0, 0, 0.87)',
	  fontSize: 11,
	},
}))(Tooltip);

export default function SignIn(props) {
	const { trans, agency,loginSettings,loader,form,openReg} = props;
	const useStyles=makeStyles(agency)
	const classes=useStyles(props)
	const [open, setOpen] = useState(false);
	//console.log(openReg)
	if(agency !== null&&openReg===true)
	{
	return (
		<div className={classes.containerLogin}>
			<ReactNotifications />
			<div className={classes.imgleft}>
				<img src={loginSettings.company_label.fields.file.url}  ></img>

				</div>
				<div className={classes.imgright}>
					<img src={loginSettings.xhub_label.fields.file.url} ></img>

				</div>
			
			<div>
				<div>
				<img  src={userImg} alt="User" className={classes.imgLogin} />
				</div>
				<label htmlFor="locator" className={classes.labellogin}>
					{loginSettings.translations.form_login_booking} 
					<ClickAwayListener onClickAway={() => setOpen(false)}>
						<LightTooltip 
							PopperProps={{
								disablePortal: true,
							}}
							onClose={() => setOpen(false)}
							open={open}
							disableFocusListener
							disableHoverListener
							disableTouchListener
							placement="bottom"
							title={loginSettings.translations.form_login_booking_tooltip}>
							<InfoIcon style={{ fontSize: '22px', color: '#fff', marginLeft: '5px', marginBottom: '-5px'}} onClick={() => setOpen(true)}/>
						</LightTooltip>
					</ClickAwayListener>
				</label>
				<input className={classes.inputlogin}  type="text" name="locator"  onChange={props.handleChange} value={form.locator}/>
			     
				<label  htmlFor="email" className={classes.labellogin}>{loginSettings.translations.form_login_contact }</label>
				<input className={classes.inputlogin} type="text" name="email" onChange={props.handleChange} value={form.email} />

			

				{loader === true ? <CircularProgress 
						style={{
							color: loginSettings.primary_color,
							textAlign:'center',
							marginLeft: '140px'
						  }}
				/>: 
					<Button 
					  className={classes.btn} 
						onClick={() => props.signIn()}>{loginSettings.translations.form_login_btn_sign_in}</Button>
				}
				{loader === false && <Button 
					className={classes.btnTrnsp}
					onClick={() => props.goToBack("Register")}>{loginSettings.translations.form_login_btn_back }</Button>
				}
			</div>

		</div>	
	
	);

	}
	else{

		return (<div></div>);
	}
	}