import ApiXHub from "./ApiXHub";
import Validation from "./Validation";
import ContactItems from "../data/contact-form.json";
import Cookies from "universal-cookie";
//import Helpers from "../components/libraries/helpers";
//import { ReactNotifications } from 'react-notifications-component';
import Encrypt from "../components/libraries/SessionEncrypt";

const cookies = new Cookies();
//const helpers = new Helpers();
const session = new Encrypt();

const Requests = {
  login: (request) => {
    return request;
  },
  register: async (request, linesSelected = null) => {
    let iso = request.locale;
    let lines = [];
    let channels = request.form.channels;
    let rsp_ip = await ApiXHub.getIP();
    request.ip_client = rsp_ip.data.ip;
    let User = session.decryptUser(cookies);

    if (iso.indexOf("-") > 0) {
      iso = iso.substring(0, iso.indexOf("-"));
    }

    ContactItems.map((item) => {
      let indexChl = channels.findIndex((c) => c.channel === item.tag);
      if (request.form["check_" + item.tag]) {
        let valueContact =
          item.type === "number"
            ? Validation.formatPhone(request.form[item.tag])
            : request.form[item.tag];
        let contact = {
          Rank: item.rank,
          Channel: item.tag,
          value: valueContact,
        };
        if (indexChl === -1) {
          channels.push(contact);
        }
        if (indexChl >= 0) {
          channels.splice(indexChl, 1);
          channels.push(contact);
        }
        // console.log(contact);
      } else {
        if (indexChl >= 0) {
          channels.splice(indexChl, 1);
        }
      }
      return false;
    });
    // console.log(request);
    // console.log(linesSelected);
    if (linesSelected !== null) {
      if (linesSelected.arrival !== "") {
        lines.push(linesSelected.arrival);
      }
      if (linesSelected.departure !== "") {
        lines.push(linesSelected.departure);
      }
    }

    let rqs_channel = {
      locator: User.locator,
      name: request.form.firstname,
      lastname: request.form.lastname,
      delegation_id: User.delegation_id,
      terms: request.form.terms,
      ip: request.ip_client,
      lines: lines,
      channels: channels,
      language: iso,
      app: "XHUB",
    };

    //if(rqs_channel?.delegation_id?.trim().length <= 0)
    if (lines.length <= 0) {
      delete rqs_channel.lines;
    }
    //console.log(rqs_channel, lines.length);

    return rqs_channel;
  },
  changeLang: (request) => {
    return request;
  },
  getBooking: (request) => {
    if (!Validation.isEmpty(request.lang)) {
      if (request.lang.indexOf("-") > 0) {
        request.lang = request.lang.substring(0, request.lang.indexOf("-"));
      }
    } else {
      request.lang = "en";
    }

    return request;
  },
  getBookings: (request) => {
    if (!Validation.isEmpty(request.lang)) {
      if (request.lang.indexOf("-") > 0) {
        request.lang = request.lang.substring(0, request.lang.indexOf("-"));
      }
    } else {
      request.lang = "en";
    }

    return request;
  },
  getConfig: (request) => {
    if (!Validation.isEmpty(request.lang)) {
      if (request.lang.indexOf("-") > 0) {
        request.lang = request.lang.substring(0, request.lang.indexOf("-"));
      }
    } else {
      request.lang = "en";
    }

    return request;
  },
  getAirportTransferPass: (request) => {
    // if(!Validation.isEmpty(request.agency))
    // {
    request.agency = "GBL";
    // }

    return request;
  },
  addAirportTransferPass: (request) => {
    return request;
  },
  addLinesLogin: (request) => {
    let newRequest = {
      token: request.token,
      locator: request.locator,
      lines: request.lines,
    };

    return newRequest;
  },
  addLines: (request) => {
    let newRequest = {
      token: request.token,
      locator: request.locator,
      lines: [],
    };

    if (Validation.isInteger(request.arrival)) {
      newRequest.lines.push(request.arrival);
    }
    if (Validation.isInteger(request.departure)) {
      newRequest.lines.push(request.departure);
    }

    return newRequest;
  },
  validateReservation: (request) => {
    return request;
  },
};
export default Requests;
